import SignupPage from './signup.component';
import { connect } from 'react-redux';
import { userRegister } from './../../api/user/userAction';

const mapDispatchToProps = {
    userRegister
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    user: state.userPage.loggedUser
});

export const SignupPageContainer = connect(mapStateToProps, mapDispatchToProps)(SignupPage); 