import * as actionTypes from "./commonActionType";
import axios from "axios";
import { baseUrl } from "./../../config";
 
export const clearCommon = () => {
  return (dispatch) => {
    dispatch(clearCommonInit());
  };
};

export const clearCommonInit = () => ({
  type: actionTypes.CLEAR_DATA
});

export const pdfStart = (data) => {
  return (dispatch) => {
    dispatch(pdfStartInit());
    axios
      .get(`${baseUrl}/start/${data.tool}`, data)
      .then((response) => {
        dispatch(pdfStartSuccess(response.data.task));
      })
      .catch(function (error) {
        dispatch(pdfStartFailure());
      });
  };
};

export const pdfStartInit = () => ({
  type: actionTypes.PDF_START,
});
export const pdfStartFailure = (data) => ({
  type: actionTypes.PDF_START_FAILURE,
});
export const pdfStartSuccess = (data) => ({
  type: actionTypes.PDF_START_SUCCESS,
  payload: { data },
});

export const pdfUpload = (data) => {
  const params = new FormData();
  params.append("task", data.task);
  params.append("tool", data.tool);
  if (Array.isArray(data.file)) {
    Object.keys(data.file[0]).forEach(function (key, index) {
      params.append(`file[${index}]`, data.file[0][key]);
    });
  } else {
    params.append("file[0]", data.file);
  }
  return (dispatch) => {
    dispatch(pdfUploadInit());
    axios
      .post(`${baseUrl}/upload`, params)
      .then((response) => {
        dispatch(pdfUploadSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfUploadFailure());
      });
  };
};

export const pdfUploadInit = () => ({
  type: actionTypes.PDF_UPLOAD,
});
export const pdfUploadFailure = (data) => ({
  type: actionTypes.PDF_UPLOAD_FAILURE,
});
export const pdfUploadSuccess = (data) => ({
  type: actionTypes.PDF_UPLOAD_SUCCESS,
  payload: { data },
});

export const pdfProcess = (data) => {
  return (dispatch) => {
    dispatch(pdfProcessInit());
    axios
      .post(`${baseUrl}/process`, data)
      .then((response) => {
        dispatch(pdfProcessSuccess(response.data.result));
      })
      .catch(function (error) {
        dispatch(pdfProcessFailure());
      });
  };
};

export const pdfProcessInit = () => ({
  type: actionTypes.PDF_PROCESS,
});
export const pdfProcessFailure = (data) => ({
  type: actionTypes.PDF_PROCESS_FAILURE,
});
export const pdfProcessSuccess = (data) => ({
  type: actionTypes.PDF_PROCESS_SUCCESS,
  payload: { data },
});

export const pdfDownload = (dataa) => {
  return (dispatch) => {
    dispatch(pdfDownloadInit());
    const head = { headers: {  }, responseType: 'blob' }
    axios.get(`${baseUrl}/download/${dataa.task}/${dataa.downloadName}`, { responseType: 'blob' })
      .then((response) => {
        dispatch(pdfDownloadSuccess(response.data));
        const { data, headers } = response;
        const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${dataa.downloadName}`;
        document.body.appendChild(link);
        link.click();
        
      })
      .catch(function (error) {
        dispatch(pdfDownloadFailure());
      });
  };
};

export const pdfDownloadInit = () => ({
  type: actionTypes.PDF_DOWNLOAD,
});
export const pdfDownloadFailure = (data) => ({
  type: actionTypes.PDF_DOWNLOAD_FAILURE,
});
export const pdfDownloadSuccess = (data) => ({
  type: actionTypes.PDF_DOWNLOAD_SUCCESS,
  payload: { data },
});

