import Navs from './index';
import { connect } from 'react-redux';
import { setConvertType } from './../../api/user/userAction';
import { clearCommon } from './../../api/common/commonAction';
import { clearPdf } from '../../api/pdf/pdfAction';

const mapDispatchToProps = {
    setConvertType,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    convertType: state.userPage.convertType,
});

export const NavsPageContainer = connect(mapStateToProps, mapDispatchToProps)(Navs);