import * as actionTypes from "./commonActionType";

const initialState = {
    isLoading: false,
    isError: false,
    pdfResponse: null,
    uploaded: null,
    processed: null,
    download: null,
    isLoading: false,
    isProcessed: false
}

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_DATA:
            return {
                ...state,
                isLoading: false,
                isError: false,
                pdfResponse: null,
                uploaded: null,
                processed: null,
                download: null,
                isLoading: false,
                isProcessed: false
            }
        case actionTypes.PDF_START:
            return {
                ...state,
                isError: false,
                pdfResponse: null,
                isLoading: true
            };
        case actionTypes.PDF_START_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionTypes.PDF_START_SUCCESS:
            return {
                ...state,
                isError: false,
                pdfResponse: action.payload.data,
                isLoading: false
            };
        case actionTypes.PDF_UPLOAD:
            return {
                ...state,
                isError: false,
                uploaded: null,
                isLoading: true
            };
        case actionTypes.PDF_UPLOAD_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionTypes.PDF_UPLOAD_SUCCESS:
            return {
                ...state,
                isError: false,
                uploaded: action.payload ? action.payload.data : null,
                isLoading: false
            };
        case actionTypes.PDF_PROCESS:
            return {
                ...state,
                isError: false,
                processed: null,
                isProcessed: true,
                isLoading: true
            };
        case actionTypes.PDF_PROCESS_FAILURE:
            return {
                ...state,
                isError: true,
                isProcessed: false,
                isLoading: false
            };
        case actionTypes.PDF_PROCESS_SUCCESS:
            return {
                ...state,
                isError: false,
                processed: action.payload ? action.payload.data : null,
                isProcessed: false,
                isLoading: false
            };
        case actionTypes.PDF_DOWNLOAD:
            return {
                ...state,
                isError: false,
                download: null,
            };
        case actionTypes.PDF_DOWNLOAD_FAILURE:
            return {
                ...state,
                isError: true,
            };
        case actionTypes.PDF_DOWNLOAD_SUCCESS:
            return {
                ...state,
                isError: false,
                download: action.payload ? action.payload.data : null,
            };
        default: 
            return state
    }
}