import NumeroterPage from './numeroter.component';
import { connect } from 'react-redux';
import { pdfStart, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import { pdfAddNumber, clearPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfAddNumber,
    pdfProcess,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    isError: state.commonPage.isError,
    pdfResponse: state.commonPage.pdfResponse,
    addedNumber: state.pdfPage.addedNumber,
    processed: state.commonPage.processed,
    download: state.commonPage.download,
    convertType: state.commonPage.convertType
});

export const NumeroterPageContainer = connect(mapStateToProps, mapDispatchToProps)(NumeroterPage); 