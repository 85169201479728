import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
// import ppp from "./../../img/ppp.pdf";
import paper from "./../../img/paper.jpg";
import closeIcon from "./../../img/icons/closeIcon.png";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./../../index.css";
import Top from "./../../component/top";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import desktop from "./../../img/icons/desktop.png";

import { BsSearch, BsDownload } from "react-icons/bs";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";

import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";
import Spinner from "react-bootstrap/Spinner";
import { Document, Page, pdfjs } from "react-pdf";

const APP_KEY = "ylhdren74lw3zw5";
export default function DiviserPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    pdfStart,
    pdfSplit,
    pdfSplitFixRange,
    pdfUpload,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    splitedFix,
    splited,
    download,
    clearCommon,
    clearPdf,
    uploaded,
    isLoading
  } = props;

  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pagePerFile, setPagePerFile] = useState(null);
  const [fichier, setFichier] = useState([{ start: "", end: "" }]);
  const [data, setData] = useState({ start: null, end: null });
  const [fileName, setFileName] = useState("");
  const [ose, setOse] = useState(false);
  const [ose1, setOse1] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();

  // console.warn("file:---", file);
  // console.warn("authResponse:---", authResponse);

  const reset = () => {
    setFile(null);
    setNumPages(null);
    setPagePerFile(null);
    setFichier([{ start: "", end: "" }]);
    setData({ start: null, end: null });
    setFileName("");
    setOse(false);
    setOse1(false);

  }
  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const addFichier = () => {
    setFichier(fichier.concat([{ start: "", end: "" }]));
  }

  const updateFichier = (i, v, t) => {
    setFichier(fichier.map((item, index) => i == index ? { ...item, [t]: v } : item))
  }

  const deleteFichier = (index) => {
    setFichier((fichier) =>
      fichier.filter((x, i) => i !== index)
    );
  }

  const onSelecteFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (pdfResponse != null) {
      if (pagePerFile) {
        pdfUpload({
          file: file,
          new_file: fileName,
          task: pdfResponse,
          tool: "split",
        });
      } else {
        var newData = fichier.map(x => `${x.start}-${x.end}`).join(",");
        pdfSplit({
          file: file,
          task: pdfResponse,
          tool: "split",
          range: newData,
          rename_file: fileName
        });
      }
    }
  }, [pdfResponse]);

  useEffect(() => {
    if (uploaded != null) {
      if (uploaded) {
        pdfProcess({
          tool: uploaded.tool,
          task: pdfResponse,
          rename_file: fileName,
          files: [
            {
              server_filename: uploaded.server_filename,
              original_file: uploaded.origninal_file,
            },
          ],
        });
      }
    }
  }, [uploaded])

  useEffect(() => {
    if (uploaded !== null) {
      if (pagePerFile) {
        pdfSplitFixRange({
          file: file,
          task: pdfResponse,
          tool: "split",
          range: pagePerFile,
          rename_file: fileName
        });
      }
    }
  }, [uploaded]);



  // useEffect(() => {
  //   if (splited !== null) {
  //     let arr = [];
  //     arr.push(splited.original_file)
  //     pdfProcess({
  //       tool: "split",
  //       task: pdfResponse,
  //       merge_file: fileName,
  //       files: [
  //         {
  //           server_filename: splited.server_filename,
  //           original_file: arr,
  //         },
  //       ]
  //     });
  //   }
  // }, [splited]);

  // useEffect(() => {
  //   if (splitedFix !== null) {
  //     let arr = [];
  //     arr.push(splitedFix.original_file)
  //     pdfProcess({
  //       tool: "split",
  //       task: pdfResponse,
  //       merge_file: fileName,
  //       files: [
  //         {
  //           server_filename: splitedFix.server_filename,
  //           original_file: arr,
  //         },
  //       ]
  //     });
  //   }
  // }, [splitedFix]);

  const downloadFile = () => {
    if (splited !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: splited.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
    if (splitedFix !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: splitedFix.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
  };



  const DownloadFileSname = () => {
    if (splited !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: splited.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
    if (splitedFix !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: splitedFix.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
  };

  useEffect(() => {
    if (download !== null) {
      setFile(null);
      clearPdf();
      clearCommon();
      reset();
    }
  }, [download]);

  const handleOpenPicker = (e) => {
    openPicker({
      // clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_KEY}`,
      clientId: "655072993760-l0sooerjlue27oik3s2uobqmpm4jm5ul.apps.googleusercontent.com",
      developerKey: "AIzaSyAw2NhYxgyu5WCZ7Te5p5G0ji7ZzTCsGWs",
      // developerKey: `${process.env.REACT_APP_GOOGLE_DEVELOPER_KEY}`,
      viewId: "DOCS",
      // token: `${process.env.REACT_APP_GOOGLE_TOKEN}`,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: async (data) => {
        const A = new File(
          [
            await (
              await fetch(
                "https://cors-anywhere.herokuapp.com/" + data.docs[0].url
              )
            ).blob(),
          ],
          data.docs[0].name
        );
        setFile(data.docs[0]);
      },
    });
  };

  const showNotice = () => {
    alert("merci de remplir les cases ('De la page' and 'à la page' or 'Fichiers de pages chacun')");
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file ? (
                <Container fluid>
                  <Row className="leftDiv">
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b
                        // onClick={() => setOpen(false)}
                        className="textHover"
                        style={{ color: "#db9731", fontSize: "20px" }}
                      >
                        Diviser le fichier
                      </b>
                      <>
                        <Row>
                          <Col sm={12} lg={7} style={{ marginTop: "10px" }}>
                            <Form style={{ display: "flex" }}>
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "28px",
                                  marginTop: "6px",
                                }}
                              />
                              <Form.Control
                                type="text"
                                className="input"
                                value={file.name}
                                readonly
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "14px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => {
                                  setFile(null);
                                  clearPdf();
                                  reset();
                                  clearCommon();
                                }}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                          </Col>
                          <Col
                            sm={12}
                            lg={5}
                            style={{
                              marginTop: "10px",
                              fontSize: "16px",
                              textAlign: "right",
                            }}
                          >
                            {/* <b>Cliquez les pages que vous souhaitez extraire</b> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "20px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  En xx fichiers <b>DE MÊME NOMBRE DE PAGES</b>
                                </p>
                              </div>
                              <div
                                className="delapage"
                                style={{
                                  border: "1px solid #eee2d9",
                                  marginTop: "-16px",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="diviser"
                                  style={{
                                    padding: "0 10px",
                                    marginBottom: "5px",
                                    width: "100%",
                                  }}
                                >
                                  <div>
                                    <b
                                      style={{
                                        fontSize: "14px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Fichiers de
                                    </b>{" "}
                                    <input
                                      min="1"
                                      type="number"
                                      value={pagePerFile}
                                      onChange={e => setPagePerFile(e.target.value)}
                                      style={{
                                        width: "45px",
                                        height: "28px",
                                        marginTop: "5px",
                                      }}
                                    />
                                    &nbsp;
                                    <b
                                      style={{
                                        fontSize: "14px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      pages chacun
                                    </b>
                                  </div>
                                  <div className="diviserLAPageDiv">
                                    <b
                                      className="diviserLAPage aPartir"
                                      style={{
                                        fontSize: "14px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      À partir de la page
                                    </b>{" "}
                                    <input
                                      min="1"
                                      type="number"
                                      style={{
                                        width: "45px",
                                        height: "28px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  borderBottom: "1px solid #db9731",
                                  marginTop: "14px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    width: "35px",
                                    margin: "0 auto",
                                    background: "#fff",
                                    textAlign: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#db9731",
                                      fontSize: "18px",
                                      fontWeight: "700",
                                      marginBottom: "-14px",
                                    }}
                                  >
                                    OU
                                  </p>
                                </div>
                              </div>
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "22px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  En xx fichiers{" "}
                                  <b>DE NOMBRE DE PAGES DIFFÉRENT</b>
                                </p>
                              </div>
                              <div
                                className="delapage111"
                                style={{
                                  border: "1px solid #eee2d9",
                                  marginTop: "-16px",
                                  paddingBottom: "5px",
                                  // display: "flex",
                                }}
                              >
                                {fichier.map((x, i) => {
                                  return (
                                    <div className="diviser1">
                                      <p
                                        style={{
                                          color: "#db9731",
                                          fontSize: "14px",
                                          fontWeight: "700",
                                          marginTop: "10px",
                                        }}
                                      >
                                        FICHIER {i + 1}
                                      </p>
                                      <div className="fichierDeAla">
                                        <div className="diviserDepage">
                                          <b
                                            style={{
                                              fontSize: "14px",
                                              color: "#5d5446",
                                            }}
                                          >
                                            De la page
                                          </b>{" "}
                                          <input
                                            min="1"
                                            value={fichier[i].start}
                                            onChange={e => { updateFichier(i, e.target.value, "start"); setData({ start: e.target.value }) }}
                                            type="number"
                                            style={{
                                              width: "45px",
                                              height: "28px",
                                              marginTop: "5px",
                                            }}
                                          />
                                        </div>
                                        <div className="diviserLAPageDiv11">
                                          <b
                                            className="diviserLAPage"
                                            style={{
                                              fontSize: "14px",
                                              color: "#5d5446",
                                            }}
                                          >
                                            à la page
                                          </b>{" "}
                                          <input
                                            min="1"
                                            value={fichier[i].end}
                                            onChange={e => { updateFichier(i, e.target.value, "end"); setData({ end: e.target.value }) }}
                                            type="number"
                                            style={{
                                              width: "45px",
                                              height: "28px",
                                              marginTop: "5px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                              <div
                                style={{ textAlign: "end", marginTop: "6px" }}
                              >
                                <p
                                  onClick={() => addFichier()}
                                  style={{
                                    color: "#5d5446",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  <i>Ajouter une partie</i>
                                </p>
                              </div>
                              <div className="txtDiv" onClick={() => {
                                if (splited || splitedFix !== null) {
                                  downloadFile();
                                } else {
                                  if (pagePerFile >= 1 || data.start >= 1 || data.end >= 1) {
                                    pdfStart({ file, tool: "split" });
                                    setOse(true);
                                  }
                                  else {
                                    showNotice();
                                  }
                                }
                              }}>
                                {isLoading && ose == true ? (
                                  <Spinner
                                    style={{ marginTop: "10px" }}
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : ose == true ? (
                                  <p
                                    style={{
                                      padding: "6px 5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                      cursor: "pointer"
                                    }}
                                  >
                                    Download
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      padding: "6px 5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                      cursor: "pointer"
                                    }}

                                  >
                                    Diviser et enregister le fichier{" "}
                                    <b>sous le même nom (+ n)</b>
                                  </p>
                                )}
                              </div>
                              <div className="txtDiv">
                                <p
                                  style={{
                                    padding: "6px 5px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Diviser et enregister le fichier{" "}
                                  <b>sous le nom de suivant (+ n)</b>
                                </p>
                              </div>
                              <div className="txtDiv1">
                                <input
                                  type="text"
                                  value={fileName}
                                  onChange={e => (setFileName(e.target.value))}
                                  className="inputBoxProteger"
                                ></input>
                                <span className="txtDiv1Sapn" onClick={() => {
                                  if (splited || splitedFix !== null) {
                                    DownloadFileSname();
                                  } else {
                                    if (pagePerFile >= 1 || data.start >= 1 || data.end >= 1 && fileName != "") {
                                      pdfStart({ file, tool: "split" });
                                      setOse1(true);
                                    }
                                    else {
                                      showNotice();
                                    }
                                  }
                                }}>
                                  {isLoading && ose1 == true ? (
                                    <Spinner
                                      style={{ marginTop: "10px" }}
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : ose1 == true ? (<BsDownload />)
                                    : (<b style={{ color: "#5d5446" }}>OK</b>)
                                  }
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                            <div className="leftBorderMainDiv">
                              {!pagePerFile && fichier.map((x, i) => {
                                console.warn('start:- ', x.start);
                                return (
                                  <Row style={{ marginTop: "15px" }}>
                                    <p
                                      style={{
                                        color: "#db9731",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      FICHIER {i + 1}
                                    </p>
                                    <div className="pdfsMainDiv">
                                      {file && Array.apply(null, Array((!isNaN(x.end - x.start) && (parseInt(x.end) - parseInt(x.start)) > 0 ? parseInt(parseInt(x.end) - parseInt(x.start)) : 0) + 1)).map((y, i) => {
                                        return (
                                          <div className="dpfsDiv">
                                            <div
                                              className="avi"
                                              style={{ display: "grid" }}
                                            >
                                              <div>
                                                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                                  <Page pageNumber={(x.start == "" ? null : parseInt(x.start)) + i} />
                                                </Document>
                                              </div>
                                              <div>
                                                <BsSearch
                                                  className="searchIcon"
                                                // onClick={() => handleShow(v)}
                                                />
                                              </div>
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                              {
                                                x.start == "" ? <p className="xxx" style={{ display: "none" }}></p> : <p className="pdfBotText">{(parseInt(x.start)) + i}</p>
                                              }

                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </Row>
                                )
                              })}
                              {pagePerFile && Array.apply(null, Array(Math.ceil(numPages / pagePerFile))).map((z, i) => {
                                return (
                                  <Row style={{ marginTop: "15px" }}>
                                    <p
                                      style={{
                                        color: "#db9731",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      FICHIER {i + 1}
                                    </p>
                                    {file && Array.apply(null, Array(parseInt(pagePerFile))).map((y, j) => {
                                      return (pagePerFile * i) + j + 1 <= numPages && (
                                        <div className="pdfsMainDiv">
                                          <div className="dpfsDiv">
                                            <div
                                              className="avi"
                                              style={{ display: "grid" }}
                                            >
                                              <div>
                                                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                                  <Page pageNumber={(pagePerFile * i) + j + 1} />
                                                </Document>
                                              </div>
                                              <div>
                                                <BsSearch
                                                  className="searchIcon"
                                                // onClick={() => handleShow(v)}
                                                />
                                              </div>
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                              <p className="pdfBotText">{(pagePerFile * i) + j + 1}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </Row>
                                )
                              })}
                            </div>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      // show={show}
                      fullscreen
                    // onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body>
          </Modal.Body> */}
                      <embed
                        className="full"
                        // src="https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf"
                        src={file && file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          // onClick={swapDiv}
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le fichier que vous souhaitez diviser
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge le fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        accept="application/pdf"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        appKey={APP_KEY}
                                        // success={handleSuccess}
                                        // cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      onClick={(e) => handleOpenPicker(e)}
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                  <>
                    <Modal
                      // show={show}
                      fullscreen
                    // onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>

                      <embed
                        className="full"
                        src={file && file}
                      />
                    </Modal>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
