// import Button from "react-bootstrap/Button";
import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./../../index.css";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import logoMain from "./../../img/logoMain.png";
import logoMainWith from "./../../img/logoMainWith.png";
import {
  BsFillEnvelopeFill,
  BsFillLockFill,
  BsChevronDown,
  BsFacebook,
  BsPersonCircle,
  BsPeopleFill,
  BsFillPencilFill,
  BsClock,
  BsFillKeyFill,
  BsList,
} from "react-icons/bs";

export default function TopPage(props) {
  const { isLoggedIn, user } = props;

  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.removeItem("access_token");
    window.location.reload();
  };
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const routesWithoutTopPageContainer = [
    "",
  ];

  useEffect(() => {
    const loc = location.pathname.split("/");
    loc.length <= 1
      ? setOpen(true)
      : routesWithoutTopPageContainer.indexOf(loc[1]) > 0
        ? setOpen(false)
        : setOpen(true);
  }, [location]);

  return (
    <Navbar
      expand="lg"
      className="topHTop"
      style={{ backgroundColor: "#5D5446", minHeight: "119px" }}
    >
      <Container
        fluid
        className="headerTopMain"
        style={{ backgroundColor: "#5D5446" }}
      >
        {open ? (
          <img src={logoMain} alt="" className="oVlogoMain" />
        ) : (
          <img src={logoMainWith} alt="" className="oVlogoMain" />
        )}

        <Link to="/" style={{ color: "#5d5446" }}>
          <Navbar.Brand>Navbar scroll</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link className="topHeaderMainText" to="/" >
              <Link to="/" style={{ color: "#000", textDecoration: "none" }}>
                Home
              </Link>
            </Nav.Link>
            <Nav.Link className="topHeaderMainText">
              <Link to="/" style={{ color: "#000", textDecoration: "none" }}>
                Link
              </Link>
            </Nav.Link>
            <Nav.Link className="topHeaderMainText" >
              <Link to="/" style={{ color: "#000", textDecoration: "none" }}>
                Link
              </Link>
            </Nav.Link>
            <Nav.Link className="topHeaderMainText" >
              <Link to="/" style={{ color: "#000", textDecoration: "none" }}>
                Link
              </Link>
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            {user === null ? (
              <>
                <Link to="/login" style={{ color: "#fff" }}>
                  Login
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  to="/signup"
                  style={{ color: "#fff", marginRight: "10px" }}
                >
                  Signup
                </Link>
              </>
            ) : (
              <>
                <Nav>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={user && user.name}
                    menuVariant="dark"
                  >
                    <NavDropdown.Item><BsPersonCircle /> Account</NavDropdown.Item>
                    <NavDropdown.Item >
                      <BsPeopleFill /> Team
                    </NavDropdown.Item>
                    <NavDropdown.Item ><BsFillPencilFill /> Signature</NavDropdown.Item>
                    <NavDropdown.Item ><BsFillKeyFill /> Upgrade to premium</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Nav>
                  <NavDropdown
                    className="dropBtnMenuIcon"
                    id="nav-dropdown-dark-example"
                    title={<BsList
                      style={{
                        color: "#000",
                        background: "#fff",
                        height: "20px",
                        width: "25px",
                        marginRight: "10px",
                      }}
                    />}
                    menuVariant="dark"
                  >
                    <NavDropdown.Item><BsPersonCircle /> Home</NavDropdown.Item>
                    <NavDropdown.Item >
                      <BsPeopleFill /> Product
                    </NavDropdown.Item>
                    <NavDropdown.Item ><BsFillPencilFill /> Solutions</NavDropdown.Item>
                    <NavDropdown.Item ><BsFillKeyFill /> Pricing</NavDropdown.Item>
                    <NavDropdown.Item ><BsFillKeyFill /> Language</NavDropdown.Item>
                    <NavDropdown.Item ><BsFillKeyFill /> Help</NavDropdown.Item>
                    <NavDropdown.Item >Ovidya</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </>
            )}
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
