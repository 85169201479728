import PivoterPage from './pivoter.component';
import { connect } from 'react-redux';
import { pdfStart, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import { pdfRotate, clearPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfRotate,
    pdfProcess,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    isLoading: state.commonPage.isLoading || state.pdfPage.isLoading,
    isError: state.commonPage.isError,
    pdfResponse: state.commonPage.pdfResponse,
    rotated: state.pdfPage.rotated,
    processed: state.commonPage.processed,
    download: state.commonPage.download
});


export const PivoterPageContainer = connect(mapStateToProps, mapDispatchToProps)(PivoterPage);