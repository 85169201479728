import * as actionTypes from "./userActionType";
import axios from "axios";
import { baseUrl } from "./../../config";
import { success, error } from "./../api-result";
 
export const setConvertType = (data) => {
  return (dispatch) => {
    dispatch(setConvertTypeInit(data));
  };
};

export const setConvertTypeInit = (data) => ({
  type: actionTypes.SET_CONVERT_TYPE_INIT,
  payload: { data }
});

export const userLogin = (data) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    params: data,
  };

  return (dispatch) => {
    dispatch(userLoginInit());
    axios
      .post(`${baseUrl}/auth`, data, config)
      .then((response) => {
        if (response.data.token && response.data.token != undefined) {
          localStorage.setItem("access_token", response.data.token);
          axios({
            method: "get",
            url: `${baseUrl}/user`,
            headers: {
              Authorization: `Bearer ` + response.data.token,
            },
          }).then(function (res) {
            if (res.data.status) {
              dispatch(userLoginSuccess(res.data.data));
              setLoginSession(res.data.data);
              success(res.data.msg);
            } else {
              error(res.data.error);
            }
          });
        } else {
        }
      })
      .catch(function (error) {
        dispatch(userLoginFailure());
      });
  };
};

const setLoginSession = (data) => {
  localStorage.setItem("loggedUser", JSON.stringify(data));
  localStorage.setItem("token", data.token);
};

export const userLoginInit = () => ({
  type: actionTypes.USER_LOGIN,
});
export const userLoginFailure = () => ({
  type: actionTypes.USER_LOGIN_FAILURE,
});
export const userLoginSuccess = (data) => ({
  type: actionTypes.USER_LOGIN_SUCCESS,
  payload: { data },
});

export const userRegister = (data) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    params: data,
  };

  return (dispatch) => {
    dispatch(userRegisterInit());
    axios
      .post(`${baseUrl}/user-create`, data, config)
      .then((response) => {
        if (!response.data.status) {
        } else {
          dispatch(userRegisterSuccess(response.data.data));
          localStorage.setItem("access_token", response.data.token);
          setLoginSession(response.data.data);
          success(response.data.msg);
        }
      })
      .catch(function (error) {
        dispatch(userRegisterFailure());
      });
  };
};

export const userRegisterInit = () => ({
  type: actionTypes.USER_REGISTER,
});
export const userRegisterFailure = () => ({
  type: actionTypes.USER_REGISTER_FAILURE,
});
export const userRegisterSuccess = (data) => ({
  type: actionTypes.USER_REGISTER_SUCCESS,
  payload: { data },
});
