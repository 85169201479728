import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsSearch, BsDownload } from "react-icons/bs";
import gDrive from "./../../img/gDrive.png";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import backword from "./../../img/backword.png";
import forword from "./../../img/forword.png";
import desktop from "./../../img/icons/desktop.png";
import "./../../index.css";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import DropboxChooser from "react-dropbox-chooser";
import { Document, Page, pdfjs } from "react-pdf";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function FusionnerPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    pdfStart,
    pdfMerge,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    merged,
    download,
    processed,
    clearCommon,
    clearPdf
  } = props;


  const [conFile, setConFile] = useState(false);
  const [doc, setDoc] = useState(false);
  const [loder, setloder] = useState(false);
  const [newFile, setNewFile] = useState("");



  const reset = () => {
    setFile([]);
    setNumPages(null);
    setFileName("");
    setConFile(false);
    setDoc(false);
    setloder(false);
    setNewFile("");
  }
  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const newFileName = (e) => {
    setNewFile(e.target.value)
  }

  const mergeFile = () => {
    pdfStart({ file, tool: "merge" });
    setDoc(true)
  }

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfMerge({ file: file, task: pdfResponse, tool: "merge", new_file: newFile, rotate: 0 });
    }
  }, [pdfResponse]);

  useEffect(() => {
    if (merged) {
      pdfProcess({
        tool: "merge",
        task: pdfResponse,
        merge_file: merged.mergeFile,
        rotate: 0,
        files: [
          {
            server_filename: merged.server_filename,
            original_file: merged.origninal_file,
          },
        ],
      });
    }
  }, [merged]);

  useEffect(() => {
    if (processed) {
      setConFile(true);
    }
  }, [processed])

  const DownloadFile = () => {
    setloder(true)
    if (processed) {
      pdfDownload({
        task: pdfResponse,
        downloadName: merged.mergeFile,
      });
      clearCommon();
      clearPdf();
      reset();
    }
  };
  useEffect(() => {
    if (download !== null) {
      setloder(false);
      clearCommon();
      clearPdf();
      reset();
      setNewFile("")
    }
  }, [download])

  const [file, setFile] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [fileName, setFileName] = useState("");
  const [moveObj, setMoveObj] = useState([]);

  const onSelecteFile = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(file.concat([{ file: e.target.files[0], rotation: 0, id: e.target.files[0].name, label: e.target.files[0].name }]));
    }
  };



  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function removeFile(i) {
    setFile((file) => file.filter((x, index) => i !== index))
  }

  function defineRotation(type, i) {
    setFile(file.map((x, index) => i == index ? { ...x, rotation: (type == 2 && x.rotation == 360 ? 0 : type == 1 && x.rotation == 0 ? 360 : x.rotation) + (type === 2 ? 90 : -90) } : x))
  }

  const onDragComplete = (result) => {
    if (!result.destination) return;

    const arr = [...file];

    //Changing the position of Array element
    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);

    //Updating the list
    setFile(arr);
    console.warn("arr:--", arr)
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              <Container fluid>
                <>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b
                        className="fusionnerh5"
                        style={{
                          color: "#db9731",
                          fontSize: "20px",
                          letterSpacing: "0px",
                        }}
                      >
                        Télécharger les fichiers que vous souhaitez fusionner
                      </b>
                      <Row>
                        <Col
                          sm={12}
                          lg={4}
                          className="leftMainWrapper boxSizeMain"
                        >
                          <div className="maxWDiv">
                            <div
                              className="fusiMainDiv"
                              style={{
                                marginTop: "17px",
                              }}
                            >
                              <p className="fusiMainDivP">
                                <b>Je télécharge mes fichiers depuis</b>
                              </p>
                            </div>
                            <>
                              <div className="fusionnerJE">
                                <div>
                                  <label
                                    className="gdhover"
                                    style={{
                                      fontSize: "16px",
                                      letterSpacing: "0px",
                                      lineHeight: "40px",
                                      color: "#5d5446",
                                      fontWeight: "600",
                                      fontFamily: "Open Sans",
                                      marginBottom: "16px",
                                    }}
                                  >
                                    <input
                                      style={{ display: "none" }}
                                      type="file"
                                      name="pdf"
                                      onChange={onSelecteFile}
                                      multiple
                                      accept="application/pdf, .docx, .pptx, .xlsx, .xls"
                                    />
                                    <img
                                      src={desktop}
                                      alt=""
                                      style={{
                                        width: "31px",
                                        height: "27px",
                                        marginTop: "6px",
                                      }}
                                    />{" "}
                                    Mon ordinateur
                                  </label>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      letterSpacing: "0px",
                                      lineHeight: "40px",
                                      color: "#5d5446",
                                      fontWeight: "600",
                                      fontFamily: "Open Sans",
                                    }}
                                  >
                                    <img
                                      src={ovidyaSymbol}
                                      alt=""
                                      style={{
                                        width: "32px",
                                        height: "34px",
                                      }}
                                    />{" "}
                                    Mon espace Ovidya
                                  </p>
                                  <lable
                                    className="gdhover"
                                    style={{
                                      fontSize: "16px",
                                      letterSpacing: "0px",
                                      lineHeight: "40px",
                                      color: "#5d5446",
                                      fontWeight: "600",
                                      fontFamily: "Open Sans",
                                    }}
                                  >
                                    <DropboxChooser
                                      // appKey={APP_KEY}
                                      // success={handleSuccess}
                                      cancel={() => console.log("closed")}
                                      multiselect={true}
                                    >
                                      <img
                                        src={dropbox}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "27px",
                                        }}
                                      />{" "}
                                      Ma Dropbox
                                    </DropboxChooser>
                                  </lable>
                                </div>
                                <div className="fusionnerJEDiv">
                                  <label
                                    // onClick={(e) => handleOpenPicker(e)}
                                    className="gdhover"
                                    style={{
                                      fontSize: "16px",
                                      letterSpacing: "0px",
                                      lineHeight: "40px",
                                      color: "#5d5446",
                                      fontWeight: "600",
                                      marginBottom: "16px",
                                      fontFamily: "Open Sans",
                                    }}
                                  >
                                    <img
                                      src={gDrive}
                                      alt=""
                                      style={{
                                        width: "31px",
                                        height: "28px",
                                      }}
                                    />{" "}
                                    Google Drive
                                  </label>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      letterSpacing: "0px",
                                      lineHeight: "40px",
                                      color: "#5d5446",
                                      fontWeight: "600",
                                      fontFamily: "Open Sans",
                                    }}
                                  >
                                    <img
                                      src={Icloud}
                                      alt=""
                                      style={{ width: "31px", height: "31px" }}
                                    />{" "}
                                    Icloud Drive
                                  </p>
                                </div>
                              </div>
                            </>
                          </div>
                        </Col>
                        <Col sm={12} lg={8} className="boxSizeMain">
                          <div
                            className="leftBorderMainDiv"
                            style={{
                              marginTop: "19px",
                            }}
                          >
                            <>
                              <Row>
                                <Col sm={6} style={{ paddingTop: "4px" }}>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      border: "1px solid #d9ceca",
                                      height: "40px",
                                      maxWidth: "487px",
                                      padding: "6px 5px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        letterSpacing: "0px",
                                        fontFamily: "Open Sans",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Fichiers à fusionner
                                    </p>
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div
                                    style={{
                                      textAlign: "end",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        letterSpacing: "0px",
                                        fontFamily: "Open Sans",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Pour modifier l'ordre de vos fichiers PDF,
                                      <br />
                                      glissez-déposez les fichiers comme bon
                                      vous semble.
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </>
                            <>
                              <Row
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <div className="pdfsMainDiv">
                                  {/* {file.length > 0 && file.map((x, i) => {
                                    return ( */}
                                  <DragDropContext onDragEnd={onDragComplete}>

                                    <Droppable droppableId="drag-drop-list" direction="horizontal">
                                      {(provided, snapshot) => (
                                        <div className="fusionnerView" {...provided.droppableProps}
                                          ref={provided.innerRef}>
                                          {/* {listItems.map((item, index) => ( */}
                                          {file.length > 0 && file.map((x, i) => {
                                            return (
                                              <Draggable
                                                key={x.id}
                                                draggableId={x.label}
                                                index={i}
                                              >
                                                {(provided) => (
                                                  <div
                                                    className="dpfsDiv"
                                                    id="rotateDivs"
                                                    style={{ marginBottom: "44px" }}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <div
                                                      className="avi"
                                                      style={{ display: "grid" }}
                                                    >
                                                      <div style={{ transform: `rotate(${x.rotation}deg)` }}>
                                                        <Document
                                                          file={x.file}
                                                          onLoadSuccess={onDocumentLoadSuccess}
                                                        >
                                                          <Page pageNumber={1} />
                                                        </Document>
                                                      </div>
                                                      <div>
                                                        <BsSearch
                                                          className="searchIcon"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        marginTop: "14px",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      <img
                                                        src={backword}
                                                        alt=""
                                                        style={{
                                                          width: "23px",
                                                          height: "18px",
                                                        }}
                                                        onClick={() => defineRotation(1, i)}
                                                      />
                                                      <img
                                                        src={forword}
                                                        alt=""
                                                        style={{
                                                          width: "23px",
                                                          height: "18px",
                                                          marginLeft: "4px",
                                                        }}
                                                        onClick={() => defineRotation(2, i)}
                                                      />
                                                      <img
                                                        src={closeIcon}
                                                        alt=""
                                                        style={{
                                                          height: "19px",
                                                          width: "19px",
                                                          marginLeft: "9px",
                                                        }}
                                                        onClick={() => (removeFile(i), clearCommon())}
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          })}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                  {/* //   )
                                  // })} */}
                                </div>
                              </Row>
                            </>
                            <>
                              <Row className="fusionnerLastDiv">
                                <Col sm={12} md={12} lg={9}>
                                  <div className="maxWDiv">
                                    <div
                                      className="txtDiv"
                                      style={{
                                        marginTop: "14px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          padding: "6px 10px",
                                          fontSize: "16px",
                                          color: "#5d5446",
                                        }}
                                      >
                                        Fusionner et enregistrer le fichier{" "}
                                        <b>sous le nom suivant</b>
                                      </p>
                                    </div>
                                    <div className="txtDiv1">
                                      <input
                                        type="text"
                                        value={newFile}
                                        onChange={newFileName}
                                        className="inputBoxProteger"
                                      >
                                      </input>
                                      {conFile ?
                                        <span className="txtDiv1Sapn" >
                                          <div onClick={DownloadFile}>
                                            {loder ?
                                              <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                              :
                                              <BsDownload />}
                                          </div>
                                        </span> :
                                        <span className="txtDiv1Sapn" onClick={mergeFile}>
                                          {doc ? <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          /> : <b style={{ color: "#5d5446" }}>OK</b>}
                                        </span>
                                      }
                                    </div>
                                  </div>
                                </Col>
                                <Col sm={12} md={12} lg={3}></Col>
                              </Row>
                            </>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
                <>
                  <Modal
                    // show={show}
                    fullscreen
                  // onHide={() => setShow(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>PDF name here</Modal.Title>
                    </Modal.Header>
                    <embed
                      className="full"
                      src={file}
                    />
                  </Modal>
                </>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
