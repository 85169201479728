import ModifierPage from './modifier.component';
import { connect } from 'react-redux';
import { pdfStart, pdfUpload, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';

const mapDispatchToProps = {
    pdfStart,
    pdfUpload,
    pdfProcess,
    pdfDownload,
    clearCommon
}

const mapStateToProps = state => ({
    pdfResponse: state.commonPage.pdfResponse,
    uploaded: state.commonPage.uploaded,
    processed: state.commonPage.processed,
    download: state.commonPage.download,
});

export const ModifierPageContainer = connect(mapStateToProps, mapDispatchToProps)(ModifierPage); 