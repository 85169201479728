import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
import Dropdown from "react-bootstrap/Dropdown";
import "./../../index.css";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
import fontIcon1 from "./../../img/icons/fontIcon1.png";
import boldIcon from "./../../img/icons/boldIcon.png";
import italicIcon from "./../../img/icons/italicIcon.png";
import underlineIcon from "./../../img/icons/underlineIcon.png";
import aIcon from "./../../img/icons/aIcon.png";
import bgcolorIcon from "./../../img/icons/bgcolorIcon.png";
import alignIcon from "./../../img/icons/alignIcon.png";
import transIcon from "./../../img/icons/transIcon.png";
import thickness from "./../../img/icons/thickness.png";
import lineIcon from "./../../img/icons/lineIcon.png";
import squareIcon from "./../../img/icons/squareIcon.png";
import circleIcon from "./../../img/icons/circleIcon.png";
import emojiIcon from "./../../img/icons/emojiIcon.png";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import desktop from "./../../img/icons/desktop.png";
import { SketchPicker } from "react-color";
import backword from "./../../img/backword.png";
import forword from "./../../img/forword.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import Modal from "react-bootstrap/Modal";
// bootstrap
import { BsSearch, BsFillCaretDownFill } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Document, Page, pdfjs } from "react-pdf";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";

const APP_KEY = "ylhdren74lw3zw5";

export default function ModifierPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    pdfStart,
    pdfUpload,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    uploaded,
    // download,
    processed,
    convertType,
  } = props;

  const [file, setFile] = useState([]);
  const [dot, setDot] = useState(null);
  const [image, setImage] = useState("");
  const [no, setNo] = useState(false);
  const [change, setChange] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  const [gdImg, setGdImg] = useState(false);

  // text editor start
  const [bold, setBold] = useState("");
  const [underline, setUnderline] = useState(false);
  const [textcolor, setTextcolor] = useState("");
  const [opacity, setOpacity] = useState(0);
  const [textalign, setTextalign] = useState("");
  const [textfamily, setTextfamily] = useState("");
  const [textsize, setTextsize] = useState("");
  // text editor end

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfUpload({ file: file, task: pdfResponse, tool: convertType.tool });
    }
  }, [pdfResponse]);

  useEffect(() => {
    if (uploaded) {
      pdfProcess({
        tool: uploaded.tool,
        task: pdfResponse,
        files: [
          {
            server_filename: uploaded.server_filename,
            filename: uploaded.origninal_file,
          },
        ],
      });
    }
  }, [uploaded]);

  const DownloadFile = () => {
    if (processed) {
      pdfDownload({
        task: pdfResponse,
        filename: uploaded.origninal_file,
        downloadName: processed.download_filename,
      });
    }
  };

  const onSelecteFile = (e) => {
    e.preventDefault();
    // if (e.target.files && e.target.files[0]) {
    //   setFile(e.target.files[0]);
    // }
    setDot(e.target.files[0]);
    setFile(e.target.files[0]);
    setChange(true);
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }

    setNo(true);
    setOpen(true);
    setGdImg(true);
  };

  const [showLine, setShowLine] = useState(false);
  const [showSquare, setShowSquare] = useState(false);

  const clickLine = () => {
    setShowLine(!showLine);
  };
  const clickSquare = () => {
    setShowSquare(!showSquare);
  };
  const clickLineClose = () => {
    setShowLine(false);
  };

  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };
  const [emoji, setEmoji] = React.useState(false);

  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  //
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  });

  const [state, setState] = useState(false);
  const { r, g, b, a } = sketchPickerColor;

  const handleClick = () => {
    setState(!state);
  };
  const handleClose = () => {
    setState(false);
  };

  const [blockPickerColor, setBlockPickerColor] = useState("#000");
  const [stateOne, setStateOne] = useState(false);
  const handleClickOne = () => {
    setStateOne(!stateOne);
  };
  const handleCloseOne = () => {
    setStateOne(false);
  };

  const [blockPickerColorOne, setBlockPickerColorOne] = useState("#fff");
  const [stateTwo, setStateTwo] = useState(false);
  const handleClickTwo = () => {
    setStateTwo(!stateTwo);
  };
  const handleCloseTwo = () => {
    setStateTwo(false);
  };

  const [open, setOpen] = useState(false);
  function swapDiv() {
    setOpen(true);
  }
  //

  const boldic = () => {
    setBold("bold")
    document.getElementById("boldd").style.background = "#db9732"
    document.getElementById("boldd").style.padding = "2px"
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {open ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1  contMainDiv">
                      <b style={{ color: "#db9731", fontSize: "20px" }}>
                        Modifier le fichier
                      </b>
                      <>
                        <Row>
                          <Col sm={12} lg={7} style={{ marginTop: "10px" }}>
                            <Form style={{ display: "flex" }}>
                              {gdImg ? (
                                <img
                                  src={desktop}
                                  alt=""
                                  style={{
                                    width: "31px",
                                    height: "28px",
                                    marginTop: "6px",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <Form.Control
                                type="text"
                                placeholder={file.name}
                                className="input"
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "14px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => (setFile(""), setOpen(false))}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                          </Col>
                          <Col sm={12} lg={5}></Col>
                        </Row>
                        <Row className="LMRMainDiv">
                          <Col sm={12} md={12} lg={2} className="lftDiv">
                            <div className="scrollPDF">
                              <>
                                <Row style={{ margin: "0 auto" }}>
                                  <div className="pdfsMainDiv">
                                    {Array.apply(null, Array(numPages)).map((x, i) => {
                                      return (
                                        <div className="dpfsDiv pdfsDivMains">
                                          <div style={{ display: "grid" }}>
                                            <div>
                                              <Document
                                                file={file}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                              >
                                                <Page pageNumber={i + 1} />
                                              </Document>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginTop: "5px",
                                            }}
                                          >
                                            <p className="pdfNumMain">{i + 1}</p>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Row>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} md={12} lg={3} className="midDiv">
                            <div
                              className="modifierMidDivMain"
                              style={{ marginTop: "10px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "5px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Modifier la page&nbsp;
                                </p>{" "}
                                <input
                                  min="0"
                                  type="number"
                                  style={{ width: "50px", height: "30px" }}
                                />
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "#DB9732",
                                  //   marginTop: "15px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 0px",
                                    color: "#fff",
                                    fontSize: "16px",
                                  }}
                                >
                                  <b>AJOUTER DU TEXTE</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #b78b8b",
                                  marginTop: "-16px",
                                  padding: "0 10px",
                                }}
                              >
                                <div className="affichageDiv">
                                  <div
                                    style={{ width: "100%", marginTop: "10px" }}
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="text"
                                        id="dropdown-basic"
                                        style={{
                                          fontSize: "16px",
                                          width: "100%",
                                          color: "#5d5446",
                                          borderRadius: "0px",
                                        }}
                                      >
                                        Arial
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">
                                          Action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Another action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                          Something else
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginTop: "10px",
                                      marginBottom: "36px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        float: "right",
                                      }}
                                    >
                                      <img
                                        src={fontIcon1}
                                        alt=""
                                        style={{
                                          height: "16px",
                                          marginTop: "7px",
                                          marginRight: "7px",
                                        }}
                                      />
                                      &nbsp;
                                      <input
                                        min="0"
                                        type="number"
                                        style={{
                                          width: "50px",
                                          height: "30px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    overflow: "auto",
                                    width: "auto",
                                    marginTop: "-23px",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        marginTop: "12px",
                                        marginBottom: "12px",
                                      }}
                                    >
                                      <img
                                        src={boldIcon}
                                        onClick={boldic}
                                        id="boldd"
                                        alt=""
                                        style={{
                                          marginLeft: "11px",
                                          height: "12px",
                                          width: "12px",
                                        }}
                                      />
                                      <img
                                        src={italicIcon}
                                        onClick={() => setBold("italic")}
                                        alt=""
                                        style={{
                                          marginLeft: "22px",
                                          height: "12px",
                                          width: "9px",
                                        }}
                                      />
                                      <img
                                        src={underlineIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "25px",
                                          width: "14px",
                                          height: "17px",
                                        }}
                                      />
                                      <img
                                        src={aIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "24px",
                                          width: "24px",
                                          height: "18px",
                                        }}
                                      />
                                      <img
                                        src={bgcolorIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "20px",
                                          width: "31px",
                                          height: "18px",
                                        }}
                                      />
                                      <img
                                        src={alignIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "17px",
                                          width: "38px",
                                          height: "18px",
                                        }}
                                      />
                                      <img
                                        src={transIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "17px",
                                          width: "32px",
                                          height: "19px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "#DB9732",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 0px",
                                    color: "#fff",
                                    fontSize: "16px",
                                  }}
                                >
                                  <b>AJOUTER UNE IMAGE</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "#DB9732",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 0px",
                                    color: "#fff",
                                    fontSize: "16px",
                                  }}
                                >
                                  <b>DESSINER</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #b78b8b",
                                  marginTop: "-16px",
                                  padding: "18px 10px 4px 10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{
                                      marginTop: "2px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Couler
                                  </p>
                                  &nbsp;
                                  <div
                                    onClick={handleClick}
                                    style={{
                                      backgroundColor: `rgba(${r},${g},${b},${a})`,
                                      width: "20px",
                                      height: "20px",
                                      border: "0.5px solid #000",
                                      marginTop: "4px",
                                    }}
                                  />
                                  <BsFillCaretDownFill
                                    style={{
                                      marginTop: "7px",
                                      marginLeft: "2px",
                                    }}
                                    onClick={handleClick}
                                  />
                                  {state ? (
                                    <div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0px",
                                          right: "0px",
                                          bottom: "0px",
                                          left: "0px",
                                        }}
                                        onClick={handleClose}
                                      />
                                      <SketchPicker
                                        className="colorPikerPOS"
                                        onChange={(color) => {
                                          setSketchPickerColor(color.rgb);
                                        }}
                                        color={sketchPickerColor}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                                &nbsp;
                                <div style={{ display: "flex", width: "auto" }}>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      marginTop: "5px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <img
                                      src={thickness}
                                      alt=""
                                      style={{
                                        marginRight: "9px",
                                        width: "27px",
                                        height: "18px",
                                        marginTop: "-1px",
                                      }}
                                    />{" "}
                                    Epaisseur&nbsp;
                                  </p>{" "}
                                  <input
                                    min="0"
                                    type="number"
                                    style={{ width: "50px", height: "30px" }}
                                  />
                                </div>
                              </div>
                              <div
                                onClick={clickLineClose}
                                style={{
                                  textAlign: "center",
                                  background: "#DB9732",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 0px",
                                    color: "#fff",
                                    fontSize: "16px",
                                  }}
                                >
                                  <b>AJOUTER UNE FORME</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #b78b8b",
                                  marginTop: "-16px",
                                  padding: "10px 10px",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ margin: "5px 10px" }}>
                                  <img
                                    src={lineIcon}
                                    alt=""
                                    onClick={clickLine}
                                    style={{ height: "18px", width: "16px" }}
                                  />
                                </span>
                                <span style={{ margin: "5px 10px" }}>
                                  <img
                                    src={squareIcon}
                                    alt=""
                                    onClick={clickSquare}
                                    style={{ height: "19px", width: "23px" }}
                                  />
                                </span>
                                <span style={{ margin: "5px 10px" }}>
                                  <img
                                    src={circleIcon}
                                    alt=""
                                    style={{ height: "19px", width: "20px" }}
                                  />
                                </span>
                                <span style={{ margin: "5px 10px" }}>
                                  <img
                                    src={emojiIcon}
                                    alt=""
                                    onClick={() => setEmoji((prev) => !prev)}
                                    style={{ height: "19px", width: "19px" }}
                                  />
                                  {emoji && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        zIndex: "1",
                                      }}
                                    >
                                      <Picker
                                        onEmojiClick={onEmojiClick}
                                        disableAutoFocus={true}
                                        skinTone={SKIN_TONE_MEDIUM_DARK}
                                        groupNames={{
                                          smileys_people: "PEOPLE",
                                        }}
                                        native
                                      />
                                    </div>
                                  )}
                                </span>
                                <div
                                  style={{ marginTop: "10px", display: "flex" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      onClick={handleClickOne}
                                      style={{
                                        backgroundColor: `${blockPickerColor}`,
                                        width: "20px",
                                        height: "20px",
                                        background: "#000",
                                        border: "0.5px solid #000",
                                        marginTop: "11px",
                                      }}
                                    />
                                    <BsFillCaretDownFill
                                      style={{
                                        marginTop: "13px",
                                        marginLeft: "2px",
                                      }}
                                      onClick={handleClickOne}
                                    />
                                    {stateOne ? (
                                      <div>
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0px",
                                            right: "0px",
                                            bottom: "0px",
                                            left: "0px",
                                          }}
                                          onClick={handleCloseOne}
                                        />
                                        <SketchPicker
                                          className="colorPikerPOS"
                                          onChange={(color) => {
                                            setBlockPickerColor(color.hex);
                                          }}
                                          color={sketchPickerColor}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <div
                                      onClick={handleClickTwo}
                                      style={{
                                        backgroundColor: `${blockPickerColorOne}`,
                                        width: "20px",
                                        height: "20px",
                                        border: "0.5px solid #000",
                                        marginLeft: "10px",
                                        marginTop: "11px",
                                      }}
                                    />
                                    <BsFillCaretDownFill
                                      style={{
                                        marginTop: "13px",
                                        marginLeft: "2px",
                                      }}
                                      onClick={handleClickTwo}
                                    />
                                    {stateTwo ? (
                                      <div>
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0px",
                                            right: "0px",
                                            bottom: "0px",
                                            left: "0px",
                                          }}
                                          onClick={handleCloseTwo}
                                        />
                                        <SketchPicker
                                          className="colorPikerPOS"
                                          onChange={(color) => {
                                            setBlockPickerColorOne(color.hex);
                                          }}
                                          color={sketchPickerColor}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                  <img
                                    src={thickness}
                                    alt=""
                                    style={{
                                      width: "27px",
                                      height: "18px",
                                      marginLeft: "8px",
                                      marginTop: "11px",
                                    }}
                                  />
                                  <input
                                    min="0"
                                    type="number"
                                    style={{
                                      width: "67px",
                                      height: "37px",
                                      marginLeft: "10px",
                                    }}
                                  />
                                  <img
                                    src={backword}
                                    alt=""
                                    style={{
                                      width: "23px",
                                      height: "18px",
                                      marginLeft: "8px",
                                      marginTop: "11px",
                                    }}
                                  />
                                  <img
                                    src={forword}
                                    alt=""
                                    style={{
                                      width: "23px",
                                      height: "18px",
                                      marginLeft: "8px",
                                      marginTop: "11px",
                                    }}
                                  />
                                  <img
                                    src={transIcon}
                                    alt=""
                                    style={{
                                      width: "31px",
                                      height: "19px",
                                      marginLeft: "8px",
                                      marginTop: "11px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "#eee2d9",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 10px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Modifier et enregister le fichier
                                  <br /> <b>sous le même nom</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "#eee2d9",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 10px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Modifier et enregister le fichier <br />
                                  <b>sous le nom suivant</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  textAlign: "right",
                                  border: "1px solid #eee2d9",
                                  marginTop: "-16px",
                                  marginBottom: "50px",
                                }}
                              >
                                <span
                                  style={{
                                    border: "1px solid #eee2d9",
                                    padding: "1px 5px",
                                  }}
                                >
                                  <b style={{ color: "#5d5446" }}>OK</b>
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} md={12} lg={7} className="rightDiv">
                            <div
                              style={{
                                borderLeft: "1px solid #5D5446",
                                paddingLeft: "25px",
                              }}
                            >
                              <>
                                <Row style={{ marginTop: "15px" }}>
                                  <div className="pdfsMainDiv">
                                    {Array.apply(null, Array(numPages)).map((x, i) => {
                                      return (
                                        <div className="dpfsDiv pdfsDivMains">
                                          <div
                                            className="avi"
                                            style={{ display: "grid" }}
                                          >
                                            <div>
                                              <Document
                                                file={file}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                              >
                                                <Page pageNumber={i + 1} />
                                              </Document>
                                            </div>
                                            <div>
                                              <BsSearch
                                                className="searchIcon"
                                              />
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginTop: "5px",
                                            }}
                                          >
                                            <p className="pdfNumMain">{i + 1}</p>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Row>
                              </>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={show}
                      fullscreen={fullscreen}
                      onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      <embed
                        className="full"
                        src={file && file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          onClick={swapDiv}
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le fichier que vous souhaitez modifier
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge mon fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        // appKey={APP_KEY}
                                        // success={handleSuccess}
                                        // cancel={() => console.log("closed")}
                                        // multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      // onClick={(e) => handleOpenPicker(e)}
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                  <>
                    <Modal
                      show={show}
                      fullscreen={fullscreen}
                      onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      <embed
                        className="full"
                        src={file && file}
                      />
                    </Modal>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
