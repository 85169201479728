import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
import backword from "./../../img/backword.png";
import forword from "./../../img/forword.png";
import desktop from "./../../img/icons/desktop.png";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import DesktopWindowsTwoToneIcon from "@mui/icons-material/DesktopWindowsTwoTone";
import Modal from "react-bootstrap/Modal";
import Top from "./../../component/top";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";
import "./../../index.css";
import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Document, Page, pdfjs } from "react-pdf";
import { BsSearch, BsXLg, BsDownload } from "react-icons/bs";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import Spinner from "react-bootstrap/Spinner";

// const url =
//   "https://cors-anywhere.herokuapp.com/https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf";

import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";


export default function PivoterPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    pdfStart,
    pdfRotate,
    pdfProcess,
    pdfDownload,
    isLoading,
    pdfResponse,
    rotated,
    processed,
    download,
    clearCommon,
    clearPdf
  } = props;

  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);
  const [rotateType, setRotatetype] = useState(null);
  const [angle, setAngle] = useState(0);
  const [ose, setOse] = useState(false);
  const [fileName, setFileName] = React.useState("");

  const reset = () => {
    setFile(null);
    setNumPages(null);
    setOpen(false);
    setRotatetype(null);
    setAngle(0);
    setOse(false);
    setFileName("");
  }
  useEffect(()=>{
    return () => {
     reset();
    }
   },[])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onSelecteFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  function rotate(type) {
    if (rotateType === null) {
      setRotatetype(type);
      rotatePdf(type);
    } else {
      if (rotateType === type) {
        rotatePdf(type);
      }
    }
  }

  function rotatePdf(type) {
    setAngle((type == 2 && angle == 360 ? 0 : type == 1 && angle == 0 ? 360 : angle) + (type == 2 ? 90 : -90));
  }

  const mergeFile = () => {
    pdfStart({ file, tool: "rotate" });
    setOse(false);
  };
  const fileConvertNew1 = () => {
    pdfStart({ file, tool: "rotate" });
    setOse(true);
  };

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfRotate({ file: file, task: pdfResponse, tool: "rotate", rotate: angle });
    }
  }, [pdfResponse]);

  useEffect(() => {
    if (rotated !== null) {
      let arr = [];
      arr.push(rotated.original_file);
      pdfProcess({
        tool: "rotate",
        task: pdfResponse,
        merge_file: !ose ? `${fileName}.pdf` : rotated.original_file,
        rotate: angle,
        files: [
          {
            server_filename: rotated.server_filename,
            original_file: arr,
          },
        ],
      });
    }
  }, [rotated]);

  const DownloadFile = (name) => {
    if (processed !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: name,
      });
      reset();
      clearCommon();
      clearPdf();
    }
  };

  useEffect(() => {
    if (download !== null) {
      setFile(null);
      clearCommon();
      clearPdf();
      reset();
    }
  }, [download]);



  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file !== null && open ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b
                        style={{
                          color: "#db9731",
                          marginTop: "10px",
                          fontSize: "20px",
                        }}
                      >
                        Faire pivoter le(s) fichier(s)
                      </b>
                      <>
                        <Row
                          style={{
                            paddingTop: "12px",
                          }}
                        >
                          <Col sm={12} lg={2} className="compresser">
                            <div className="dpfsDiv">
                              <div
                                className="avi"
                                id="rotationtool"
                                style={{ display: "grid", marginTop: "10px" }}
                              >
                                <div style={{ transform: `rotate(${angle}deg)` }}>
                                  <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={1} />
                                  </Document>
                                </div>
                                <div>
                                  <BsSearch
                                    className="search"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} lg={10} className="alaxDiv">
                            <Form
                              style={{ display: "flex", marginTop: "10px" }}
                            >
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "27px",
                                  marginTop: "6px",
                                }}
                              />{" "}
                              <Form.Control
                                type="text"
                                className="input"
                                placeholder={file.name}
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "10px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => {
                                  setFile(null);
                                  clearCommon();
                                  clearPdf();
                                  reset();
                                }}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                            <div className="comMainDiv">
                              <div className="comMainDiv1 comMainDiv1MAin">
                                <h4
                                  style={{
                                    color: "#db9731",
                                    marginTop: "10px",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Choisissez le sens de rotation
                                </h4>
                                <div
                                  style={{ display: "flex", marginTop: "10px" }}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    className="check checkBlack"
                                    checked={rotateType == 1}
                                    onClick={() => setRotatetype(1)}
                                  />
                                  <h5
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                      marginTop: "5px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    &nbsp;Gauche &nbsp;&nbsp;
                                    <span>
                                      <img
                                        onClick={() => rotate(1)}
                                        src={backword}
                                        alt=""
                                        style={{
                                          width: "23px",
                                          height: "18px",
                                        }}
                                      />
                                    </span>
                                  </h5>
                                </div>
                                <div
                                  style={{ display: "flex", marginTop: "10px" }}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    className="check checkBlack"
                                    checked={rotateType == 2}
                                    onClick={() => setRotatetype(2)}
                                  />
                                  <h5
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                      marginTop: "5px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    &nbsp;Droite &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>
                                      <img
                                        onClick={() => rotate(2)}
                                        src={forword}
                                        alt=""
                                        style={{
                                          width: "23px",
                                          height: "18px",
                                          marginLeft: "-4px",
                                        }}
                                      />
                                    </span>
                                  </h5>
                                </div>
                              </div>
                              <div className="comMainDiv2">
                                <div
                                  className="txtDiv"
                                  style={{
                                    marginTop: "15px",
                                  }}
                                  onClick={() => {
                                    processed !== null && ose ? DownloadFile(processed.download_filename) : fileConvertNew1();
                                  }}
                                  // onClick={() => {
                                  //   setOse(true);
                                  //   pdfStart({ file, tool: "rotate" });
                                  // }}
                                >
                                  {processed !== null && ose ? (
                                    <div>
                                      <BsDownload
                                        style={{ marginTop: "8px" }}
                                      />
                                    </div>
                                  ) : (
                                    <span>
                                      {isLoading && ose ? (
                                        <Spinner
                                          style={{ marginTop: "10px" }}
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            padding: "6px 5px",
                                            fontSize: "16px",
                                            color: "#5d5446",
                                          }}
                                        >
                                          Pivoter et enregistrer le fichier{" "}
                                          <b>sous le même nom</b>
                                        </p>
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div className="txtDiv">
                                  <p
                                    style={{
                                      padding: "10px 5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Pivoter et enregistrer le fichier{" "}
                                    <b>sous le nom suivant</b>
                                  </p>
                                </div>
                                <div className="txtDiv1">
                                  <input
                                    onChange={e => setFileName(e.target.value)}
                                    value={fileName}
                                    className="inputBoxProteger"
                                  ></input>
                                  {processed !== null && !ose ? (
                                    <span className="txtDiv1Sapn">
                                      <div onClick={() => DownloadFile(`${fileName}.pdf`)}>
                                        <BsDownload />
                                      </div>
                                    </span>
                                  ) : (
                                    <span className="txtDiv1Sapn" onClick={() => mergeFile()}>
                                      {isLoading && !ose ? (
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <b style={{ color: "#5d5446" }}>OK</b>
                                      )}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            borderBottom: "1px solid #c1b0b0",
                            paddingTop: "12px",
                          }}
                        />
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body>
          </Modal.Body> */}
                      <embed
                        className="full"
                        // src="https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf"
                        src={file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          onClick={() => setOpen(true)}
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le(s) fichier(s) que vous souhaitez
                          pivoter
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge le(s) fichier(s) depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        // appKey={APP_KEY}
                                        // success={handleSuccess}
                                        cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col
                            sm={12}
                            lg={8}
                            className="boxSizeMain boxSizeMainss"
                          >
                            <div className="comNewRDiv comNewRDivss">
                              <div>
                                <div
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid #d9ceca",
                                    height: "36px",
                                    padding: "6px 5px",
                                  }}
                                >
                                  <p
                                    style={{
                                      // marginTop: "6px",
                                      fontSize: "16px",
                                      fontWeight: "700",
                                      letterSpacing: "0px",
                                      fontFamily: "Open Sans",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Liste des fichiers à faire pivoter{" "}
                                  </p>
                                </div>
                              </div>
                              {file && (
                                <div
                                  className="comNewFlex"
                                  style={{
                                    marginTop: "17px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "400",
                                        fontStyle: "italic",
                                        fontFamily: "Open Sans",
                                        display: "flex",
                                      }}
                                    >
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      &nbsp;{" "}
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {file && file.name}
                                      </p>
                                    </p>
                                  </div>
                                  <img
                                    src={closeIcon}
                                    onClick={() => {
                                      setFile(null);
                                      setOpen(false);
                                      clearCommon();
                                    }}
                                    alt=""
                                    style={{
                                      width: "19px",
                                      height: "19px",
                                      marginTop: "13px",
                                    }}
                                  />
                                </div>

                              )}
                              <div className="maxWDiv">
                                <div
                                  className="comNewtxtDiv"
                                  style={{
                                    marginTop: "14px",
                                  }}
                                  onClick={() => setOpen(true)}
                                >
                                  <p
                                    style={{
                                      padding: "6px 10px",
                                      fontSize: "16px",
                                      color: "#ffffff",
                                      fontWeight: "700",
                                      fontFamily: "Open Sans",
                                      letterSpacing: "0px",
                                    }}
                                  >
                                    Faire pivoter les fichiers sélectionnés
                                  </p>
                                </div>

                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
