import ExtrairePage from './extraire.component';
import { connect } from 'react-redux';
import { pdfStart, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import {  pdfSplit, clearPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfSplit,
    pdfProcess,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    pdfResponse: state.commonPage.pdfResponse,
    splited: state.pdfPage.splited,
    processed: state.commonPage.processed,
    download: state.commonPage.download,
    isLoading: state.commonPage.isLoading || state.pdfPage.isLoading,
});

export const ExtrairePageContainer = connect(mapStateToProps, mapDispatchToProps)(ExtrairePage); 