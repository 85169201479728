import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RoutePath, Route } from "./../../routes";
import { TopPageContainer } from "./../../component/top/top.container";
import "./../../index.css";

export default function AppRoot() {
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const routesWithoutTopPageContainer = [ "l","login", "signup"];

  useEffect(() => {
    const loc = location.pathname.split("/");
    loc.length <= 1
      ? setOpen(true)
      : routesWithoutTopPageContainer.indexOf(loc[1]) > 0
      ? setOpen(false)
      : setOpen(true);
  }, [location]);
  return (
    <Fragment>
      <div
        className="backgroundRes"
        style={{
          background: "linear-gradient(to right, #5D5446 10%, #ffffff 80%)",
        }}
      >
        {open ? <TopPageContainer /> : null}
        <RoutePath />
      </div>
    </Fragment>
  );
}
