import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import paper from "./../../img/paper.jpg";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./../../index.css";
import Top from "./../../component/top";
import Footer from "./../../component/footer";
import Sidenav from "../../component/sidenav";
import { BsSearch, BsXLg } from "react-icons/bs";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import desktop from "./../../img/icons/desktop.png";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
import { Document, Page, pdfjs } from "react-pdf";
import { NavsPageContainer } from "../../component/nav/index.converter";
import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";

const APP_KEY = "ylhdren74lw3zw5";

export default function SupprimerPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    pdfStart,
    pdfUpload,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    uploaded,
    // download,
    processed,
    setConvertType,
    convertType,
  } = props;

  const [file, setFile] = useState([]);
  const [gdfile, setGdfile] = useState([]);
  const [dpfile, setDpfile] = useState([]);
  const [gdurl, setGdurl] = useState("");
  const [dot, setDot] = useState(null);
  const [image, setImage] = useState("");
  const [no, setNo] = useState(false);
  const [change, setChange] = useState(false);
  const [gd, setGd] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  const [url, setUrl] = useState("");
  const [dpbox, setDpbox] = useState(false);

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfUpload({ file, gdurl, task: pdfResponse, tool: convertType.tool });
    }
  }, [pdfResponse]);

  useEffect(() => {
    if (uploaded) {
      pdfProcess({
        tool: uploaded.tool,
        task: pdfResponse,
        files: [
          {
            server_filename: uploaded.server_filename,
            filename: uploaded.origninal_file,
          },
        ],
      });
    }
  }, [uploaded]);

  const DownloadFile = () => {
    if (processed) {
      pdfDownload({
        task: pdfResponse,
        filename: uploaded.origninal_file,
        downloadName: processed.download_filename,
      });
    }
  };

  async function handleSuccess(files) {
    setUrl(files[0].thumbnailLink);
    const B = new File(
      [
        await (
          await fetch("https://cors-anywhere.herokuapp.com/" + files[0].link)
        ).blob(),
      ],
      files[0].name
    );
    setDpfile(files[0]);
    setDpbox(true);
  }

  const handleOpenPicker = (e) => {
    openPicker({
      clientId: `${process.env.REACT_APP_GOOGLE_CLIENT_KEY}`,
      // clientId: "655072993760-l0sooerjlue27oik3s2uobqmpm4jm5ul.apps.googleusercontent.com",
      // developerKey: "AIzaSyAw2NhYxgyu5WCZ7Te5p5G0ji7ZzTCsGWs",
      developerKey: `${process.env.REACT_APP_GOOGLE_DEVELOPER_KEY}`,
      viewId: "DOCS",
      // token: `${process.env.REACT_APP_GOOGLE_TOKEN}`,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: async (data) => {
        const A = new File(
          [
            await (
              await fetch(
                "https://cors-anywhere.herokuapp.com/" + data.docs[0].url
              )
            ).blob(),
          ],
          data.docs[0].name
        );
        setGdurl(data.docs[0].url);
        setGdfile(data.docs[0]);
        setGd(true);
      },
    });
  };

  const onSelecteFile = (e) => {
    e.preventDefault();
    setDot(e.target.files[0]);
    setFile(e.target.files[0]);
    setChange(true);
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
    setOpen(true);
    setNo(true);
  };

  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const [open, setOpen] = useState(false);
  function swapDiv() {
    setOpen(true);
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {open ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b style={{ color: "#db9731", fontSize: "20px" }}>
                        Modifier mon fichier
                      </b>
                      <>
                        <Row>
                          <Col sm={12} lg={7} style={{ marginTop: "10px" }}>
                            <Form style={{ display: "flex" }}>
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "28px",
                                  marginTop: "6px",
                                }}
                              />{" "}
                              <Form.Control
                                type="text"
                                value={file.name}
                                className="input"
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "14px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => setFile("")}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                          </Col>
                          <Col
                            sm={12}
                            lg={5}
                            className="textRightPadding"
                            style={{
                              marginTop: "10px",
                              fontSize: "20px",
                              textAlign: "right",
                              color: "#5d5446",
                            }}
                          >
                            <b>
                              Cliquez les pages que vous souhaitez supprimer
                            </b>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="txtDiv"
                                style={{
                                  marginTop: "15px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 5px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Valider et enregistrer le fichier{" "}
                                  <b>sous le même nom</b>
                                </p>
                              </div>
                              <div className="txtDiv">
                                <p
                                  style={{
                                    padding: "6px 5px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Valider et enregistrer le fichier{" "}
                                  <b>sous le nom suivant</b>
                                </p>
                              </div>
                              <div className="txtDiv1">
                                <span className="txtDiv1Sapn">
                                  <b style={{ color: "#5d5446" }}>OK</b>
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                            <div className="leftBorderMainDiv">
                              <>
                                <Row
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "50px",
                                  }}
                                >
                                  <div className="pdfsMainDiv">
                                    {Array.apply(null, Array(numPages))
                                      .map((x, i) => i + 1)
                                      .map((page) => (
                                        <div className="dpfsDiv pdfsDivMains">
                                          <div
                                            className="avi"
                                            style={{ display: "grid" }}
                                          >
                                            <div>
                                              <Document
                                                file={image}
                                                onLoadSuccess={({ numPages }) =>
                                                  setNumPages(numPages)
                                                }
                                              >
                                                <Page pageNumber={page} />
                                              </Document>
                                            </div>
                                            <div>
                                              {values.map((v, idx) => (
                                                <BsSearch
                                                  className="searchIcon"
                                                  onClick={() => handleShow(v)}
                                                />
                                              ))}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginTop: "5px",
                                            }}
                                          >
                                            <p className="pdfNumMain">{page}</p>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </Row>
                              </>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={show}
                      fullscreen={fullscreen}
                      onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{file.name}</Modal.Title>
                      </Modal.Header>
                      <embed
                        className="full"
                        src={numPages}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          onClick={swapDiv}
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le fichier dont vous souhaitez supprimer
                          des pages
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge mon fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf, image/png, image/jpg, image/jpeg, .docx, .pptx, .xlsx"
                                        // accept="image/png, image/jpg, image/jpeg"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        appKey={APP_KEY}
                                        success={handleSuccess}
                                        cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      onClick={(e) => handleOpenPicker(e)}
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain"></Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
