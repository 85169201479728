import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import desktop from "./../../img/icons/desktop.png";
import docx from "./../../img/docs/docx.png";
import pptx from "./../../img/docs/pptx.png";
import xlsx from "./../../img/docs/xlsx.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import SquareIcon from "@mui/icons-material/Square";
import Spinner from "react-bootstrap/Spinner";
import DropboxChooser from "react-dropbox-chooser";
import { useNavigate } from "react-router-dom";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";
import "./../../index.css";
import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { BsSearch, BsDownload } from "react-icons/bs";
import useDrivePicker from "react-google-drive-picker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

export default function ConvertirPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const navigate = useNavigate();
  const {
    setConvertType,
    convertType,
    pdfResponse,
    pdfStart,
    pdfUpload,
    pdfProcess,
    pdfDownload,
    uploaded,
    processed,
    isLoading,
    clearCommon,
  } = props;
  

  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);
  const [ose, setOse] = useState(false);
  const [fileName, setFileName] = React.useState("");
  const [url, setUrl] = useState("");
  const [xl, setXl] = useState(false);
  const [pp, setPp] = useState(false);
  const [dc, setDc] = useState(false);

  const reset = () => {
    setFile(null);
    setNumPages(null);
    setOpen(false);
    setOse(false);
    setFileName("");
    setXl(false);
    setPp(false);
    setDc(false);
  }
  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const onSelecteFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // API start

  const convertFile = () => {
    pdfStart({ file, tool: convertType.tool });
    setOse(true);
  };
  const fileConvertNew = () => {
    pdfStart({ file, tool: convertType.tool });
    setOse(false);
  };

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfUpload({
        file: file,
        new_file: fileName,
        task: pdfResponse,
        tool: convertType.tool,
      });
    }
  }, [pdfResponse]);

  useEffect(() => {
    if (uploaded) {
      pdfProcess({
        tool: uploaded.tool,
        task: pdfResponse,
        merge_file: fileName,
        files: [
          {
            server_filename: uploaded.server_filename,
            original_file: uploaded.origninal_file,
          },
        ],
      });
    }
  }, [uploaded]);

  const DownloadFile = (name) => {
    if (processed !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: name,
      });
      reset();
      clearCommon();
    }
  };

  // API end

  return (
    <>
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file !== null && open ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 content">
                      <b
                        className="textHover"
                        style={{
                          color: "#db9731",
                          marginTop: "10px",
                          fontSize: "20px",
                        }}
                      >
                        Convertir le fichier / les fichiers de{" "}
                        {convertType.name}
                      </b>
                      <>
                        <Row
                          style={{
                            paddingTop: "12px",
                          }}
                        >
                          <Col sm={12} lg={2} className="compresser">
                            <div className="dpfsDiv">
                              {convertType.setDoc ? <img src={docx} style={{ height: "207px", width: "146px", border: "1px solid #5d5446" }} /> : convertType.setPpt ? <img src={pptx} style={{ height: "207px", width: "146px", border: "1px solid #5d5446" }} /> : convertType.setXcl ? <img src={xlsx} style={{ height: "207px", width: "146px", border: "1px solid #5d5446" }} /> :
                                <div
                                  className="avi"
                                  style={{
                                    display: "grid",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div>
                                    <Document
                                      file={file}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                      <Page pageNumber={1} />
                                    </Document>
                                  </div>
                                  <div>
                                    <BsSearch
                                      className="search"
                                    // onClick={() => handleShow(v)}
                                    />
                                  </div>
                                </div>
                              }
                            </div>
                          </Col>
                          <Col sm={12} lg={10} className="alaxDiv">
                            <Form
                              style={{ display: "flex", marginTop: "10px" }}
                            >
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "27px",
                                  marginTop: "6px",
                                }}
                              />{" "}
                              <Form.Control
                                type="text"
                                className="input"
                                value={file && file.name}
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "10px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => (setFile(null), clearCommon(), reset())}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                            <div className="comMainDiv">
                              <div>
                                <div
                                  className="txtDiv"
                                  style={{
                                    marginTop: "15px",
                                  }}
                                  onClick={() => {
                                    processed !== null && ose ? DownloadFile(processed.download_filename) : convertFile();
                                  }}
                                >
                                  {processed !== null && ose ? (
                                    <div>
                                      <BsDownload
                                        style={{ marginTop: "8px" }}
                                      />
                                    </div>
                                  ) : (
                                    <span>
                                      {isLoading && ose ? (
                                        <Spinner
                                          style={{ marginTop: "10px" }}
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            padding: "6px 5px",
                                            fontSize: "16px",
                                            color: "#5d5446",
                                          }}
                                        >
                                          Convertir et enregistrer le
                                          fichier <b>sous le même nom</b>
                                        </p>
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div className="txtDiv">
                                  <p
                                    style={{
                                      padding: "6px 5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Convertir et enregistrer le fichier{" "}
                                    <b>sous le nom suivant</b>
                                  </p>
                                </div>
                                <div className="txtDiv1">
                                  <input
                                    type="text"
                                    onChange={e => setFileName(e.target.value)}
                                    value={fileName}
                                    className="inputBoxProteger"
                                  ></input>
                                  {processed !== null && !ose ? (
                                    <span className="txtDiv1Sapn">
                                      <div onClick={() => DownloadFile(fileName)}>
                                        <BsDownload />
                                      </div>
                                    </span>
                                  ) : (
                                    <span
                                      className="txtDiv1Sapn"
                                      onClick={fileConvertNew}
                                    >
                                      {isLoading && !ose ? (
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <b style={{ color: "#5d5446" }}>OK</b>
                                      )}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            borderBottom: "1px solid #5D5446",
                            paddingTop: "12px",
                          }}
                        />
                      </>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    {convertType.open ? (
                      <Row>
                        <Col sm={1} className="leftDiv1"></Col>
                        <Col sm={11} className="leftDiv1 contMainDiv">
                          <b
                            className="fusionnerh5 textHover"
                            style={{
                              color: "#db9731",
                              fontSize: "20px",
                              letterSpacing: "0px",
                            }}
                          >
                            Télécharger le(s) fichier(s) que vous souhaitez
                            convertir de {convertType.name}
                          </b>
                          <Row>
                            <Col
                              sm={12}
                              lg={4}
                              className="leftMainWrapper boxSizeMain"
                            >
                              <div className="maxWDiv">
                                <div
                                  className="fusiMainDiv"
                                  style={{
                                    marginTop: "17px",
                                  }}
                                >
                                  <p className="fusiMainDivP">
                                    <b>Je télécharge mon fichier depuis</b>
                                  </p>
                                </div>
                                <>
                                  <div className="fusionnerJE">
                                    <div>
                                      <label
                                        className="gdhover"
                                        style={{
                                          fontSize: "16px",
                                          letterSpacing: "0px",
                                          lineHeight: "40px",
                                          color: "#5d5446",
                                          fontWeight: "600",
                                          fontFamily: "Open Sans",
                                          marginBottom: "16px",
                                        }}
                                      >
                                        <input
                                          style={{ display: "none" }}
                                          type="file"
                                          name="pdf"
                                          onChange={onSelecteFile}
                                          accept={convertType.type}
                                        // accept="application/pdf, image/png, image/jpg, image/jpeg, .docx, .pptx, .xlsx"
                                        />
                                        <img
                                          src={desktop}
                                          alt=""
                                          style={{
                                            width: "31px",
                                            height: "27px",
                                            marginTop: "6px",
                                          }}
                                        />{" "}
                                        Mon ordinateur
                                      </label>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          letterSpacing: "0px",
                                          lineHeight: "40px",
                                          color: "#5d5446",
                                          fontWeight: "600",
                                          fontFamily: "Open Sans",
                                        }}
                                      >
                                        <img
                                          src={ovidyaSymbol}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "34px",
                                          }}
                                        />{" "}
                                        Mon espace Ovidya
                                      </p>
                                      <lable
                                        className="gdhover"
                                        style={{
                                          fontSize: "16px",
                                          letterSpacing: "0px",
                                          lineHeight: "40px",
                                          color: "#5d5446",
                                          fontWeight: "600",
                                          fontFamily: "Open Sans",
                                        }}
                                      >
                                        <DropboxChooser
                                          // appKey={APP_KEY}
                                          // success={handleSuccess}
                                          // cancel={() => console.log("closed")}
                                          multiselect={true}
                                        >
                                          <img
                                            src={dropbox}
                                            alt=""
                                            style={{
                                              width: "32px",
                                              height: "27px",
                                            }}
                                          />{" "}
                                          Ma Dropbox
                                        </DropboxChooser>
                                      </lable>
                                    </div>
                                    <div className="fusionnerJEDiv">
                                      <label
                                        // onClick={() => {
                                        //   handleOpenPicker();
                                        // }}
                                        className="gdhover"
                                        style={{
                                          fontSize: "16px",
                                          letterSpacing: "0px",
                                          lineHeight: "40px",
                                          color: "#5d5446",
                                          fontWeight: "600",
                                          marginBottom: "16px",
                                          fontFamily: "Open Sans",
                                        }}
                                      >
                                        <img
                                          src={gDrive}
                                          alt=""
                                          style={{
                                            width: "31px",
                                            height: "28px",
                                          }}
                                        />{" "}
                                        Google Drive
                                      </label>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          letterSpacing: "0px",
                                          lineHeight: "40px",
                                          color: "#5d5446",
                                          fontWeight: "600",
                                          fontFamily: "Open Sans",
                                        }}
                                      >
                                        <img
                                          src={Icloud}
                                          alt=""
                                          style={{
                                            width: "31px",
                                            height: "31px",
                                          }}
                                        />{" "}
                                        Icloud Drive
                                      </p>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </Col>
                            <Col
                              sm={12}
                              lg={8}
                              className="boxSizeMain boxSizeMainss"
                            >
                              <div className="comNewRDiv comNewRDivss">
                                <div>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      border: "1px solid #d9ceca",
                                      height: "36px",
                                      padding: "6px 5px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        // marginTop: "6px",
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        letterSpacing: "0px",
                                        fontFamily: "Open Sans",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Liste des fichiers à convertir
                                    </p>
                                  </div>
                                </div>

                                {file && (
                                  <div
                                    className="comNewFlex"
                                    style={{
                                      marginTop: "17px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          letterSpacing: "0px",
                                          lineHeight: "40px",
                                          color: "#5d5446",
                                          fontWeight: "400",
                                          fontStyle: "italic",
                                          fontFamily: "Open Sans",
                                          display: "flex",
                                        }}
                                      >
                                        <img
                                          src={desktop}
                                          alt=""
                                          style={{
                                            width: "31px",
                                            height: "27px",
                                            marginTop: "6px",
                                          }}
                                        />{" "}
                                        &nbsp;{" "}
                                        <p
                                          style={{
                                            marginBottom: "0px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          {file && file.name}
                                        </p>
                                      </p>
                                    </div>
                                    <img
                                      src={closeIcon}
                                      onClick={() => {
                                        setFile(null);
                                        clearCommon();
                                      }}
                                      alt=""
                                      style={{
                                        width: "19px",
                                        height: "19px",
                                        marginTop: "13px",
                                      }}
                                    />
                                  </div>
                                )}
                                <div className="maxWDiv">
                                  <div
                                    className="comNewtxtDiv"
                                    style={{
                                      marginTop: "14px",
                                    }}
                                    onClick={() => setOpen(true)}
                                  >
                                    <p
                                      style={{
                                        padding: "6px 10px",
                                        fontSize: "16px",
                                        color: "#ffffff",
                                        fontWeight: "700",
                                        fontFamily: "Open Sans",
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      Convertir les fichiers sélectionnés{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col sm={1} className="leftDiv1"></Col>
                        <Col sm={11} className="leftDiv1 contMainDiv">
                          <b
                            className="fusionnerh5 textHover"
                            style={{
                              color: "#db9731",
                              fontSize: "20px",
                              letterSpacing: "0px",
                            }}
                          >
                            Choisissez le type de conversion que vous souhaitez
                            effectuer
                          </b>
                          <div className="unlWrapper">
                            <div className="row tableDiv">
                              <div className="unl">
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      name: "PDF à Excel",
                                      tool: "pdfoffice",
                                      type: "application/pdf",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  PDF à Excel
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      name: "PDF à jpg",
                                      tool: "pdfjpg",
                                      type: "application/pdf",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  PDF à jpg
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      name: "PDF à PDF/A",
                                      tool: "pdfa",
                                      type: "application/pdf",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  PDF à PDF/A
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      name: "PDF à PowerPoint",
                                      tool: "pdfoffice",
                                      type: "application/pdf",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  PDF à PowerPoint
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      name: "PDF à Word",
                                      tool: "pdfoffice",
                                      type: "application/pdf",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  PDF à Word
                                </li>
                              </div>
                              <div className="unl">
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      setXcl: true,
                                      name: "Excel à PDF",
                                      tool: "officepdf",
                                      type: ".xlsx, .xls",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} />{" "}
                                  D'Excel à PDF
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    navigate("/url_to_pdf");
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} />{" "}
                                  D'HTML à PDF
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      name: "JPG à PDF",
                                      tool: "imagepdf",
                                      type: " image/png, image/jpg, image/jpeg,",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  JPG à PDF
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      setPpt: true,
                                      name: "PowerPoint à PDF",
                                      tool: "officepdf",
                                      type: ".pptx",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  PowerPoint à PDF
                                </li>
                                <li
                                  className="convertirDropLi"
                                  onClick={() => {
                                    setConvertType({
                                      open: true,
                                      setDoc: true,
                                      name: "Word à PDF",
                                      tool: "officepdf",
                                      type: ".docx",
                                    });
                                  }}
                                >
                                  <SquareIcon style={{ color: "#DB9732" }} /> De
                                  Word à PDF
                                </li>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </>
                  <ToastContainer />
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
