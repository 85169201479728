import TopPage from './index';
import { connect } from 'react-redux';
// import { userLogin } from './../../api/user/userAction';

// const mapDispatchToProps = {
//     userLogin
// }

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    isLoggedIn: state.userPage.isLoggedIn,
    user: state.userPage.loggedUser
});

export const TopPageContainer = connect(mapStateToProps, {})(TopPage); 