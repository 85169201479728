import * as actionTypes from "./pdfActionType";

const initialState = {
    isLoading: false,
    isError: false,
    splitedFix: null,
    splited: null,
    watermarked: null,
    merged: null,
    addedNumber: null,
    compressed: null,
    protectted: null,
    isLoading: false,
    rotated: null,
    urlpdfdone: null,
}

export const pdfReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_PDF:
            return {
                ...state,
                isLoading: false,
                isError: false,
                splitedFix: null,
                splited: null,
                watermarked: null,
                merged: null,
                addedNumber: null,
                compressed: null,
                protectted: null,
                isLoading: false,
                rotated: null,
                urlpdfdone: null
            }
        case actionTypes.PDF_SPLIT_FIXRANGE:
            return {
                ...state,
                isError: false,
                splitedFix: null,
                isLoading: true
            };
        case actionTypes.PDF_SPLIT_FIXRANGE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionTypes.PDF_SPLIT_FIXRANGE_SUCCESS:
            return {
                ...state,
                isError: false,
                splitedFix: action.payload ? action.payload.data : null,
                isLoading: false
            };
        case actionTypes.PDF_SPLIT:
            return {
                ...state,
                isError: false,
                splited: null,
                isLoading: true
            };
        case actionTypes.PDF_SPLIT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionTypes.PDF_SPLIT_SUCCESS:
            return {
                ...state,
                isError: false,
                splited: action.payload ? action.payload.data : null,
                isLoading: false
            };
        case actionTypes.PDF_WATERMARK:
            return {
                ...state,
                isError: false,
                watermarked: null
            };
        case actionTypes.PDF_WATERMARK_FAILURE:
            return {
                ...state,
                isError: true,
            };
        case actionTypes.PDF_WATERMARK_SUCCESS:
            return {
                ...state,
                isError: false,
                watermarked: action.payload ? action.payload.data : null,
            };
        case actionTypes.PDF_MERGE:
            return {
                ...state,
                isError: false,
                merged: null
            };
        case actionTypes.PDF_MERGE_FAILURE:
            return {
                ...state,
                isError: true
            };
        case actionTypes.PDF_MERGE_SUCCESS:
            return {
                ...state,
                isError: false,
                merged: action.payload ? action.payload.data : null,
            };
        case actionTypes.PDF_ADD_NUMBER:
            return {
                ...state,
                isError: false,
                addedNumber: null
            };
        case actionTypes.PDF_ADD_NUMBER_FAILURE:
            return {
                ...state,
                isError: true,
            };
        case actionTypes.PDF_ADD_NUMBER_SUCCESS:
            return {
                ...state,
                isError: false,
                addedNumber: action.payload ? action.payload.data : null,
            };
        case actionTypes.PDF_COMPRESS:
            return {
                ...state,
                isError: false,
                compressed: null,
                isLoading: true
            };
        case actionTypes.PDF_COMPRESS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionTypes.PDF_COMPRESS_SUCCESS:
            return {
                ...state,
                isError: false,
                compressed: action.payload ? action.payload.data : null,
                isLoading: false
            };
            case actionTypes.PDF_PROTECT:
            return {
                ...state,
                isError: false,
                protectted: null,
                isLoading: true
            };
        case actionTypes.PDF_PROTECT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionTypes.PDF_PROTECT_SUCCESS:
            return {
                ...state,
                isError: false,
                protectted: action.payload ? action.payload.data : null,
                isLoading: false
            };
        case actionTypes.PDF_ROTATE:
            return {
                ...state,
                isError: false,
                rotated: null,
                isLoading: true
            };
        case actionTypes.PDF_ROTATE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionTypes.PDF_ROTATE_SUCCESS:
            return {
                ...state,
                isError: false,
                rotated: action.payload ? action.payload.data : null,
                isLoading: false
            };
            case actionTypes.URL_PDF:
            return {
                ...state,
                isError: false,
                urlpdfdone: null
            };
        case actionTypes.URL_PDF_FAILURE:
            return {
                ...state,
                isError: true,
            };
        case actionTypes.URL_PDF_SUCCESS:
            return {
                ...state,
                isError: false,
                urlpdfdone: action.payload ? action.payload.data : null,
            };
        default: 
            return state
    }
}