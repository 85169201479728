import ProtegerPage from './proteger.component';
import { connect } from 'react-redux';
import { pdfStart, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import { pdfProtect, clearPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfProtect,
    pdfProcess,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    pdfResponse: state.commonPage.pdfResponse,
    isLoading: state.commonPage.isLoading || state.pdfPage.isLoading,
    protectted: state.pdfPage.protectted,
    processed: state.commonPage.processed,
    download: state.commonPage.download,
    isProcessed: state.commonPage.isProcessed
});


export const ProtegerPageContainer = connect(mapStateToProps, mapDispatchToProps)(ProtegerPage);