import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
import fontIcon from "./../../img/icons/fontIcon.png";
import boldIcon from "./../../img/icons/boldIcon.png";
import italicIcon from "./../../img/icons/italicIcon.png";
import underlineIcon from "./../../img/icons/underlineIcon.png";
import aIcon from "./../../img/icons/aIcon.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import yellow from "./../../img/yellow.png";
import Modal from "react-bootstrap/Modal";
import "./../../index.css";
import Top from "./../../component/top";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";

import { BsSearch, BsDownload } from "react-icons/bs";
import { DefaultEditor } from "react-simple-wysiwyg";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";

import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { RichTextEditor } from "@mantine/rte";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import desktop from "./../../img/icons/desktop.png";

import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";
import Spinner from "react-bootstrap/Spinner";
import { Textsms } from "@mui/icons-material";
import { Document, Page, pdfjs } from "react-pdf";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const APP_KEY = "ylhdren74lw3zw5";


export default function FiligranerPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const {
    pdfStart,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    download,
    pdfWatermark,
    watermarked,
    clearCommon,
    clearPdf
  } = props;

  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [position, setPosition] = useState({ vertical: "top", horizontal: "left" });
  const [opacity, setOpacity] = useState(1);
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState({ start: "", end: "" });
  const [watermark, setWatermark] = useState(null);
  const [conFile, setConFile] = useState(false);
  const [doc, setDoc] = useState(false);
  const [conFile1, setConFile1] = useState(false);
  const [doc1, setDoc1] = useState(false);
  const [newFile, setNewFile] = useState("");
  const [loder, setloder] = useState(false);
  const [texts, setTexts] = useState("");
  const [ffamily, setFfamily] = useState("");
  const [ose, setOse] = useState(false);
  const [ose1, setOse1] = useState(false);
  const [checks, setChecks] = useState(false);
  const [img, setImg] = useState(false)

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Uploaded image is no bigger than 1 mb and and file type are PNG or JPG. 
    </Tooltip>
  );

  const reset = () => {
    setFile(null);
    setNumPages(null);
    setPosition({ vertical: "top", horizontal: "left" });
    setOpacity(1);
    setChecked(false);
    setSelected([{ start: "", end: "" }]);
    setWatermark(null);
    setConFile(false);
    setDoc(false);
    setConFile1(false);
    setDoc1(false);
    setNewFile("");
    setloder(false);
    setTexts("");
    setFfamily("");
    setOse(false);
    setOse1(false);
    setChecks(false);
    setImg(false);
  }
  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const newFileName = (e) => {
    setNewFile(e.target.value);
  };

  const showNotice = () =>{
    alert("Veuillez remplir les champs de saisie ('De la page' et 'à la page')");
  }
  const showNoticeCustom = () =>{
    alert("Veuillez remplir les champs de saisie ('De la page' et 'à la page') ou remplissez le nom personnalisé");
  }

  const mergeFile = () => {
    if(selected.start >=1 && selected.end >=1 && newFile != ""){
      pdfStart({ file, tool: "watermark" });
      setDoc(true);
      setOse(true);
    }
    else{
      showNoticeCustom();
    }
  };

  const mergeFile1 = () => {
    if(selected.start >=1 && selected.end >=1){
    pdfStart({ file, tool: "watermark" });
    setConFile1(true);
    setOse1(true)
    } else{
      showNotice();
    }
  };

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfWatermark({
        new_file: newFile,
        task: pdfResponse,
        file: file,
        tool: "watermark",
        mode: img ? 'image' : 'text',
        text: texts,
        transparency: opacity,
        horizontal_position: position.horizontal,
        vertical_position: position.vertical,
        mosaic: checks,
        font_family: "Arial",
        font_size: "24",
        font_color: "#000000",
        rename_file: newFile,
      });
    }
  }, [pdfResponse]);

  // useEffect(() => {
  //   if (watermarked !== null) {
  //     let arr = [];
  //     arr.push(watermarked.result.download_filename)
  //     pdfProcess({
  //       tool: "split",
  //       task: pdfResponse,
  //       merge_file: newFile,
  //       files: [
  //         {
  //           server_filename: watermarked.server_filename,
  //           original_file: arr,
  //         },
  //       ],
  //     });
  //   }
  // }, [watermarked]);

  useEffect(() => {
    if (watermarked !== null) {
      if (ose1 !== false) {
        setDoc1(true);
      }
      if (ose !== false) {
        setConFile(true);
      }
    }
  }, [watermarked]);


  const DownloadFile = () => {
    if (watermarked !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: watermarked.result.download_filename,
      });
      reset();
      clearCommon();
      clearPdf();
    }
  };
  const DownloadFileSname = () => {
    if (watermarked !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: watermarked.result.download_filename,
      });
      reset();
      clearCommon();
      clearPdf();
    }
  };

  useEffect(() => {
    if (download !== null) {
      setloder(false);
      setFile(null);
      clearPdf();
      clearCommon();
      reset();
    }
  }, [download]);

  const onSelecteWatermark = (e) => {
    setWatermark(e.target.files[0])
    setImg(true);
  }

  const checkChecked = () => {
    if (checked) {
      setSelected({ start: "", end: "" });
    } else {
      setSelected({ start: 1, end: numPages });
    }
    setChecked(!checked);
  }

  const onSelecteFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b style={{ color: "#db9731", fontSize: "20px" }}>
                        Filigraner le fichier
                      </b>
                      <>
                        <Row>
                          <Col sm={12} lg={7} style={{ marginTop: "10px" }}>
                            <Form style={{ display: "flex" }}>
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "28px",
                                  marginTop: "6px",
                                }}
                              />
                              <Form.Control
                                className="input"
                                type="text"
                                value={file.name}
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "14px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => (setFile(null), setWatermark(null), clearPdf(), reset(), clearCommon())}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                          </Col>
                          <Col sm={12} lg={5}></Col>
                        </Row>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "23px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  <b>Filigrane</b>
                                </p>
                              </div>
                              <div
                                className="affichage"
                                style={{
                                  border: "1px solid #EEE2D9",
                                  marginTop: "-16px",
                                  // display: "flex",
                                  // justifyContent: "space-between",
                                  padding: "0 5px",
                                }}
                              >
                                <div className="editor">
                                  <div
                                    className="editorDiv1"
                                    style={{ margin: "5px 0" }}
                                  >
                                    <Form.Select className="texteDropdown" style={{
                                      color: "#5d5446",
                                      textAlign: "left",
                                      borderRadius: "0px",
                                      border: "1px solid #e9d9cd",
                                    }}>
                                      <option onSelect={() => setFfamily("Arial")}>Arial</option>
                                      <option onSelect={() => setFfamily("Fantasy")}>Fantasy</option>
                                      <option onSelect={() => setFfamily("Monospace")}>Monospace</option>
                                    </Form.Select>
                                  </div>
                                  <div
                                    className="editorDiv2"
                                    style={{
                                      overflow: "auto",
                                      margin: "5px 0",
                                    }}
                                  >
                                    <div style={{ marginTop: "5px" }}>
                                      <img
                                        src={fontIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "14px",
                                          height: "12px",
                                          marginTop: "3px",
                                        }}
                                      />
                                      <img
                                        src={boldIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "15px",
                                          height: "12px",
                                          marginTop: "3px",
                                        }}
                                      />
                                      <img
                                        src={italicIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "12px",
                                          height: "12px",
                                          marginTop: "3px",
                                        }}
                                      />
                                      <img
                                        src={underlineIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "9px",
                                          marginTop: "6px",
                                          height: "15px",
                                        }}
                                      />
                                      <img
                                        src={aIcon}
                                        alt=""
                                        style={{
                                          marginLeft: "13px",
                                          marginTop: "3px",
                                          height: "15px",
                                        }}
                                      />
                                    </div>

                                  </div>
                                </div>
                                <div
                                  className="texteEnFiligrane"
                                  style={{
                                    display: "flex",
                                    padding: "0 10px",
                                    marginTop: "10px",
                                    // justifyContent: "space-between",
                                  }}
                                >
                                  <b
                                    style={{
                                      width: "58%",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Texte en filigrane
                                  </b>
                                  &nbsp;
                                  <Form.Control
                                    type="text"
                                    className="textfield"
                                    onChange={(e) => setTexts(e.target.value)}
                                    fullwidth
                                    style={{
                                      height: "30px",
                                      border: "1px solid #e9d9cd",
                                      borderRadius: "0px",
                                    }}
                                  />
                                </div>
                                <div
                                  className="filigranerimage"
                                  style={{
                                    justifyContent: "space-between",
                                    // display: "flex",
                                    padding: "0 10px",
                                    margin: "10px 0",
                                  }}
                                >
                                  <div style={{ marginTop: "10px" }}>
                                    <b
                                      style={{
                                        fontSize: "16px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      <span style={{ color: "#db9731" }}>
                                        OU
                                      </span>{" "}
                                      Charger l'image de filigrane
                                    </b>
                                    <div
                                      style={{
                                        background: "#e9d9cd",
                                        width: "83%",
                                        textAlign: "center",
                                        padding: "5px 0",
                                        marginLeft: "37px",
                                        height: "35px",
                                        marginTop: "6px",
                                      }}
                                    >
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <label
                                          style={{
                                            marginBottom: "-3px",
                                            fontSize: "14px",
                                            color: "#5d5446",
                                            letterSpacing: "0px",
                                            fontWeight: "400",
                                            fontFamily: "Open Sans",
                                            cursor: "pointer"
                                          }}
                                        >
                                          <input
                                            style={{ display: "none" }}
                                            type="file"
                                            name="pdf"
                                            onChange={onSelecteWatermark}
                                            multiple
                                            accept="image/png, image/jpg, image/jpeg"
                                          />
                                          {watermark ? `${watermark.name}` : "Telecharger l'image"}
                                        </label>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div
                                    className="filigranerimagediv"
                                    style={{
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <b
                                      style={{
                                        fontSize: "16px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Transparence
                                    </b>
                                    <Form.Select className="texteDropdown" style={{
                                      fontSize: "16px",
                                      height: "35px",
                                      color: "#5d5446",
                                      borderRadius: "0px",
                                      marginTop: "6px",
                                      textAlign: "center",
                                      border: "1px solid #e9d9cd",
                                    }}
                                      onChange={(e) => setOpacity(e.target.value)}
                                    >
                                      <option>{opacity == 1 ? "Aucune" : opacity * 100}</option>
                                      <option value={10}>10</option>
                                      <option value={30}>30</option>
                                      <option value={50}>50</option>
                                    </Form.Select>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  <b>Positionnement sur les pages</b>
                                </p>
                              </div>
                              <div
                                className="positionnementDiv"
                                style={{
                                  border: "1px solid #EEE2D9",
                                  marginTop: "-16px",
                                  padding: "0 10px",
                                }}
                              >
                                <div
                                  className="grilleGrid"
                                  style={{
                                    display: "flex",
                                    // marginTop: "-28px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      padding: "5px 0px",
                                    }}
                                  >
                                    <div className="positionsGrill">
                                      <div style={{ display: "flex" }}>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "top", horizontal: "left" })}
                                        >
                                          {position.vertical == "top" && position.horizontal == "left" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "top", horizontal: "center" })}
                                        >
                                          {position.vertical === "top" && position.horizontal == "center" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "top", horizontal: "right" })}
                                        >
                                          {position.vertical === "top" && position.horizontal == "right" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => (setPosition({ vertical: "middle", horizontal: "left" }))}
                                        >
                                          {position.vertical === "middle" && position.horizontal == "left" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => (setPosition({ vertical: "middle", horizontal: "center" }))}
                                        >
                                          {position.vertical === "middle" && position.horizontal == "center" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => (setPosition({ vertical: "middle", horizontal: "right" }))}
                                        >
                                          {position.vertical === "middle" && position.horizontal == "right" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "bottom", horizontal: "left" })}
                                        >
                                          {position.vertical === "bottom" && position.horizontal == "left" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "bottom", horizontal: "center" })}
                                        >
                                          {position.vertical === "bottom" && position.horizontal == "center" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "bottom", horizontal: "right" })}
                                        >
                                          {position.vertical === "bottom" && position.horizontal == "right" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    style={{
                                      marginLeft: "6px",
                                      marginTop: "44px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>
                                      Position
                                      <br /> sur les pages
                                    </b>
                                  </p>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginRight: "58px",
                                    }}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      onChange={() => setChecks(true)}
                                      className="check"
                                    />
                                    <b
                                      style={{
                                        marginTop: "2px",
                                        marginLeft: "5px",
                                        fontSize: "16px",
                                        marginBottom: "10px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Mozaique
                                    </b>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "20px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  <b>Positionnement sur les pages</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #EEE2D9",
                                  marginTop: "-16px",
                                }}
                              >
                                <div
                                  className="rotation"
                                  style={{
                                    padding: "5px 10px",
                                    display: "flex",
                                  }}
                                >
                                  <b
                                    style={{
                                      marginTop: "5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Rotation
                                  </b>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <Form.Select className="texteDrop" style={{
                                    ccolor: "#5d5446",
                                    maxWidth: "168px",
                                    minHeight: "37px",
                                    textAlign: "left",
                                    borderRadius: "0px",
                                    border: "1px solid #e9d9cd",
                                  }}>
                                    <option>Ne pas pivoter</option>
                                    <option>90 Deg</option>
                                    <option>180 Deg</option>
                                    <option>270 Deg</option>
                                  </Form.Select>
                                </div>
                                <div
                                  className="premier"
                                  style={{
                                    marginTop: "5px",
                                    marginLeft: "10px",
                                    marginBottom: "5px",
                                    display: "flex",
                                  }}
                                >
                                  <b
                                    style={{
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Appliquer&nbsp;&nbsp;
                                  </b>{" "}
                                  <div style={{ display: "flex" }}>
                                    <Form.Check
                                      type="checkbox"
                                      className="check"
                                      style={{ marginTop: "-5px" }}
                                      onClick={() => checkChecked()}
                                    />
                                    &nbsp;
                                    <b
                                      style={{
                                        fontSize: "16px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      A toutes les pages
                                    </b>
                                  </div>
                                </div>
                                <div
                                  className="numerotationsDiv2"
                                  style={{
                                    // justifyContent: "space-around",
                                    // display: "flex",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    marginLeft: "44px",
                                    padding: "0 10px",
                                  }}
                                >
                                  <div style={{ marginTop: "5px" }}>
                                    <b
                                      style={{
                                        fontSize: "14px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      De la page&nbsp;&nbsp;
                                    </b>{" "}
                                    <input
                                      min="0"
                                      type="number"
                                      value={selected.start}
                                      onChange={e => !checked && setSelected({ ...selected, start: e.target.value })}
                                      style={{ width: "58px", height: "37px" }}
                                    />
                                  </div>
                                  <div
                                    className="aLaLaPage"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <b
                                      style={{
                                        fontSize: "14px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      á la la page
                                    </b>{" "}
                                    <input
                                      min="0"
                                      type="number"
                                      value={selected.end}
                                      onChange={e => !checked && setSelected({ ...selected, end: e.target.value })}
                                      style={{ width: "58px", height: "37px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="txtDiv"
                                onClick={mergeFile1}
                                style={{
                                  marginTop: "16px",
                                }}
                              >
                                {doc1 ? (
                                  <div onClick={DownloadFileSname}>
                                    <BsDownload style={{ marginTop: "8px" }} />
                                  </div>
                                ) : (
                                  <span>
                                    {conFile1 ? (
                                      <Spinner
                                        style={{ marginTop: "10px" }}
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <p
                                        style={{
                                          padding: "6px 5px",
                                          fontSize: "16px",
                                          color: "#5d5446",
                                        }}
                                      >
                                        Filigraner et enregister le fichier{" "}
                                        <b>sous le même nom</b>
                                      </p>
                                    )}
                                  </span>
                                )}
                              </div>
                              <div className="txtDiv">
                                <p
                                  style={{
                                    padding: "6px 5px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Filigraner et enregister le fichier{" "}
                                  <b>sous le nom de suivant</b>
                                </p>
                              </div>
                              <div className="txtDiv1">
                                <input
                                  type="text"
                                  onChange={newFileName}
                                  className="inputBoxProteger"
                                ></input>
                                {conFile ? (
                                  <span className="txtDiv1Sapn">
                                    <div onClick={DownloadFile}>
                                      {loder ? (
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <BsDownload />
                                      )}
                                    </div>
                                  </span>
                                ) : (
                                  <span
                                    className="txtDiv1Sapn"
                                    onClick={mergeFile}
                                  >
                                    {doc ? (
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <b style={{ color: "#5d5446" }}>OK</b>
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                            <div className="leftBorderMainDiv">
                              <>
                                <Row
                                  className="numeroter"
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "50px",
                                  }}
                                >
                                  <div className="pdfsMainDiv">
                                    {Array.apply(null, Array(numPages)).map((x, i) => {
                                      return (
                                        <div className="dpfsDiv">
                                          <div
                                            className="avi"
                                            style={{ display: "grid" }}
                                          >
                                            <span>
                                              {selected.start <= i + 1 && selected.end > i ? (
                                                <img
                                                  src={yellow}
                                                  id="filigranerDotLoc"
                                                  alt=""
                                                  className={`${position.vertical}${position.horizontal}`}
                                                  style={{ position: "relative", zIndex: 1, width: "20px", height: "20px", opacity: opacity }}
                                                />
                                              ) : (
                                                <div style={{ height: "24px" }}></div>
                                              )}
                                            </span>
                                            <div>
                                              <Document
                                                file={file}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                              >
                                                <Page pageNumber={i + 1} />
                                              </Document>
                                            </div>
                                            <div>
                                              <div
                                                style={{ textAlign: "center" }}
                                              >
                                                <BsSearch
                                                  className="midSearch"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Row>
                              </>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    // onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body>
          </Modal.Body> */}
                      <embed
                        className="full"
                        // src="https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf"
                        src={file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le fichier que vous voulez filigraner
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge le fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf, image/png, image/jpg, image/jpeg, .docx, .pptx, .xlsx"
                                      // accept="image/png, image/jpg, image/jpeg"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        // appKey={APP_KEY}
                                        // success={handleSuccess}
                                        // cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      // onClick={(e) => handleOpenPicker(e)}
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                  <>
                    <Modal
                      // show={show}
                      fullscreen
                    // onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body>
        </Modal.Body> */}
                      <embed
                        className="full"
                        // src="https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf"
                        src={file}
                      />
                    </Modal>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
