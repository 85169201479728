import React from "react";
import team4 from "./../../img/team4.png";
import "./../../index.css";
// import {Nav} from "react-bootstrap";
function Sidenav() {
  return (
    <>
      <div
        className="Sidenav"
        style={{ background: "#5D5446", minHeight: "120vh", textAlign: "center" }}
      >
        <img
          src={team4}
          alt=""
          className="sideNameMainImg"
        />
      </div>
    </>
  );
}

export default Sidenav;
