import React from "react";
// import } from "./shared/protected.route";
import { Routes, Route } from "react-router-dom";
import { DashboardPageContainer } from "./views/dashboard/dashboard.container";
import { LoginPageContainer } from "./views/login/login.container";
import { SignupPageContainer } from "./views/signup/signup.container";
import { DiviserPageContainer } from "./views/diviser/diviser.container";
import { FiligranerPageContainer } from "./views/filigraner/filigraner.container";
import { FusionnerPageContainer } from "./views/fusionner/fusionner.container";
import { NumeroterPageContainer } from "./views/numeroter/numeroter.container";
import { CompresserPageContainer } from "./views/compresser/compresser.container";
import { ExtrairePageContainer } from "./views/extraire/extraire.container";
import { ProtegerPageContainer } from "./views/proteger/proteger.container";
import { PivoterPageContainer } from "./views/pivoter/pivoter.container";
import { ConvertirPageContainer } from "./views/convertir/convertir.container";
import { UrlpdfPageContainer } from "./views/urlpdf/urlpdf.container";
import { ModifierPageContainer } from "./views/modifier/modifier.container";
import { SupprimerPageContainer } from "./views/supprimer/supprimer.container";
import { InseserPageContainer } from "./views/inseser/inseser.container";

export const RoutePath = () => (
  <Routes>
    <Route exact path="/" element={<DashboardPageContainer />} />
    <Route exact path="/login" element={<LoginPageContainer />} />
    <Route exact path="/signup" element={<SignupPageContainer />} />
    <Route exact path="/diviser" element={<DiviserPageContainer />} />
    <Route exact path="/filigraner" element={<FiligranerPageContainer />} />
    <Route exact path="/fusionner" element={<FusionnerPageContainer />} />
    <Route exact path="/numeroter" element={<NumeroterPageContainer />} />
    <Route exact path="/compresser" element={<CompresserPageContainer />} />
    <Route exact path="/extraire" element={<ExtrairePageContainer />} />
    <Route exact path="/proteger" element={<ProtegerPageContainer />} />
    <Route exact path="/pivoter" element={<PivoterPageContainer />} />
    <Route exact path="/convertir" element={<ConvertirPageContainer />} />
    <Route exact path="/url_to_pdf" element={<UrlpdfPageContainer />} />
    <Route exact path="/modifier" element={<ModifierPageContainer />} />
    <Route exact path="/supprimer" element={<SupprimerPageContainer />} />
    <Route exact path="/inseser" element={<InseserPageContainer />} />
  </Routes>
);
