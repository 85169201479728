import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../../index.css";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import logSign from "./../../img/logSign.png";
import google from "./../../img/google.png";
import {
  BsPersonCircle,
  BsFillEnvelopeFill,
  BsFillLockFill,
  BsChevronDown,
  BsFacebook,
} from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

export default function SignupPage(props) {
  const { isLoggedIn, userRegister } = props;
  const [values, setValues] = React.useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const navigate = useNavigate();
  // if(isLoggedIn) {
  if (isLoggedIn) {
    // navigate("/compresser");
    setTimeout(() => { navigate("/") }, 2000)
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  // validation
  const [errors, setErrors] = React.useState({});
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.firstName && !values.lastName && !values.email && !values.password && !values.retypePassword) {
      isError = true;
      errorss.name = "First name is required";
      errorss.email = "Email is required";
      errorss.password = "Password is required";
      errorss.confirm_password = "Retype password is required";
    } else if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } else if (!values.email) {
      isError = true;
      errorss.email = "Email is required";
    } else if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    } else if (!regex.test(values.email)) {
      isError = true;
      errorss.email = "This is not a valid email format";
    }
    if (values.password !== values.confirm_password) {
      isError = true;
      errorss.confirm_password = "Password and Retype Password does not match.";
    }
    if (!values.confirm_password) {
      isError = true;
      errorss.confirm_password = "Retype password is required";
    }
    if (!isError) {
      userRegister(values);
    }
    return errorss;
  };
  // 
  return (
    <Container fluid className="containerFluMainDiv">
      <Row className="containerFluMainDivRow">
        <Col
          sm={12}
          md={12}
          lg={7}
          style={{
            background: "#FFFFFF",
          }}
        >
          <div className="loginRoot">
            <div className="loginWrapper">
              <div style={{ textAlign: "center" }}>
                <h1>
                  O<span style={{ color: "red" }}>v</span>idya
                </h1>
                <h5>Create new account</h5>
              </div>
              <div className="buttonDiv">
              </div>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setErrors(validate(values));
                }}
              >
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <BsPersonCircle />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Name"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.name && (
                  <p className="validationErrors">{errors.name}</p>
                )}
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <BsFillEnvelopeFill />
                  </InputGroup.Text>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Email"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.email && (
                  <p className="validationErrors">{errors.email}</p>
                )}
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <BsFillLockFill />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Password"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.password && (
                  <p className="validationErrors">{errors.password}</p>
                )}
                <InputGroup className="mb-2">
                  <InputGroup.Text id="basic-addon1">
                    <BsFillLockFill />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Confirm password"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.confirm_password && (
                  <p className="validationErrors">{errors.confirm_password}</p>
                )}
                <div style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="btn btn-dark btn-lg btn-block"
                    style={{
                      margin: "10px auto",
                      background: "red",
                      border: "1px solid red",
                    }}
                  >
                    Sign up
                  </button>
                  <p
                    className="forgot-password text-right"
                    style={{ marginTop: "10px" }}
                  >
                    Already member{" "}
                    <Link to="/login" style={{ color: "red" }}>
                      log in?
                    </Link>
                  </p>
                  <p style={{ marginTop: "10px", fontSize: "13px" }}>
                    By creating an account. you agree to Ovidya{" "}
                    <a href="/" style={{ color: "red" }}>
                      Terms of Service
                    </a>
                    and
                    <p
                      to="/login"
                      component={Link}
                      compon
                      style={{ color: "red" }}
                    >
                      Privacy Policy
                    </p>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Col>
        <Col
          sm={12}
          md={12}
          lg={5}
          style={{
            background: "#F2F3F8",
          }}
        >
          <div style={{ maxWidth: "398px", margin: "23% auto" }}>
            <img src={logSign} alt="" style={{ width: "100%" }} />
            <h3>PDF tools for productive people</h3>
            <p>
              Ovidya helps you convert, edit, e-sign, and protect PDF files
              quickly and easily. Enjoy a full suite of tools to effectively
              manage documents —no matter where you’re working.
            </p>
            <p>
              see all tools <BsChevronDown />
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
