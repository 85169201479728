import { combineReducers } from 'redux';
import { userReducer as userPage } from './../api/user/userReducer';
import { commonReducer as commonPage } from './../api/common/commonReducer';
import { pdfReducer as pdfPage } from './../api/pdf/pdfReducer';

const appReducer = combineReducers({
    userPage,
    commonPage,
    pdfPage
});

export default appReducer;