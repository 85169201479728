import CompresserPage from './compresser.component';
import { connect } from 'react-redux';
import { pdfStart, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import { pdfCompress, clearPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfCompress,
    pdfProcess,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    isError: state.commonPage.isError,
    pdfResponse: state.commonPage.pdfResponse,
    compressed: state.pdfPage.compressed,
    processed: state.commonPage.processed,
    download: state.commonPage.download,
    isLoading: state.commonPage.isLoading || state.pdfPage.isLoading,
});

export const CompresserPageContainer = connect(mapStateToProps, mapDispatchToProps)(CompresserPage); 