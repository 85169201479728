import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
import secure from "./../../img/secure.png";
import closeIcon from "./../../img/icons/closeIcon.png"
import grille from "./../../img/grille.png";
import dot from "./../../img/dot.png";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Dropdown from "react-bootstrap/Dropdown";
import "./../../index.css";
import Top from "./../../component/top";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
import { BsSearch, BsXLg, BsFillEyeFill, BsDownload } from "react-icons/bs";
import { DefaultEditor } from "react-simple-wysiwyg";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Document, Page, pdfjs } from "react-pdf";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import desktop from "./../../img/icons/desktop.png";
import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";
import Spinner from "react-bootstrap/Spinner";


export default function ProtegerPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    pdfStart,
    pdfProtect,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    protectted,
    download,
    processed,
    isLoading,
    clearCommon,
    clearPdf
  } = props;

  const [file, setFile] = useState(null);
  const [values, setValues] = React.useState({
    password: "",
    confirm_password: "",
  });

  const [fileName, setFileName] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = React.useState(false);
  const [ose, setOse] = React.useState(false);
  const [numPages, setNumPages] = useState(null);

  const reset = () => {
    setFile(null);
    setValues({password: "", confirm_password: ""});
    setFileName("");
    setPasswordShown(false);
    setPasswordShownConfirm(false);
    setOse(false);
    setNumPages(null);
  }
  useEffect(()=>{
    return () => {
     reset();
    }
   },[])

  // validation
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePasswordConfirm = () => {
    setPasswordShownConfirm(!passwordShownConfirm);
  };

  const [errors, setErrors] = React.useState({});
  const validate = (values) => {
    let errorss = {};
    let isError = false;

    if (!values.password && !values.retypePassword) {
      isError = true;
      errorss.password = "Password is required";
      errorss.confirm_password = "Retype password is required";
    } else if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    }
    if (values.password !== values.confirm_password) {
      isError = true;
      errorss.confirm_password = "Password and Retype Password does not match.";
    }
    if (!values.confirm_password) {
      isError = true;
      errorss.confirm_password = "Retype password is required";
    }
    return errorss;
  };

  const showNotice = () =>{
    alert("veuillez remplir le mot de passe et confirmer le mot de passe");
  }
  const showNoticeCustom = () =>{
    alert("veuillez remplir le mot de passe et confirmer le mot de passe ou remplissez le nom personnalisé");
  }

  const mergeFile = () => {
    if(values.password != "" && values.confirm_password != "" && fileName != "" && values.password === values.confirm_password){
      pdfStart({ file, tool: "protect" });
      setOse(false);
    } else{
      showNoticeCustom();
    }
  };
  const fileConvertNew1 = () => {
    if(values.password != "" && values.confirm_password != "" && values.password === values.confirm_password){
      pdfStart({ file, tool: "protect" });
      setOse(true);
    } else {
      showNotice();
    }
  };

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfProtect({
        file: file,
        task: pdfResponse,
        tool: "protect",
        password: values.confirm_password,
        new_file: fileName,
      });
    }
  }, [pdfResponse]);

  useEffect(() => {
    if (protectted) {
      let arr = [];
      arr.push(protectted.result.download_filename)
      pdfProcess({
        tool: "protect",
        task: pdfResponse,
        merge_file: !ose ? fileName + ".pdf" : arr[0],
        password: values.confirm_password,
        files: [
          {
            server_filename: protectted.server_filename,
            original_file: arr,
          },
        ],
      });
    }
  }, [protectted]);

  const DownloadFile = (name) => {
    if (processed !== null) {
      console.log("API CALLING");
      pdfDownload({
        task: pdfResponse,
        downloadName: name,
      });
      reset();
      clearCommon();
      clearPdf();
    }
  };

  useEffect(() => {
    if (download !== null) {
      setFile(null);
      clearCommon();
      clearPdf();
      reset();
    }
  }, [download]);

  const onSelecteFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file !== null ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b style={{ color: "#db9731", fontSize: "20px" }}>
                        Protéger le fichier
                      </b>
                      <>
                        <Row>
                          <Col sm={12} lg={7} style={{ marginTop: "10px" }}>
                            <Form style={{ display: "flex" }}>
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "28px",
                                  marginTop: "6px",
                                }}
                              />
                              <Form.Control
                                type="text"
                                className="input"
                                placeholder={file.name}
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "14px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => (setFile(null),clearCommon(), reset(), clearPdf())}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                          </Col>
                          <Col sm={12} lg={5} style={{ marginTop: "10px" }}>
                            {/* <h4>Cliquez les pages que vous souhaitez supprimer</h4> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <form
                              onSubmit={(event) => {
                                event.preventDefault();
                                setErrors(validate(values));
                              }}
                            >
                              <div className="maxWDiv">
                                <div
                                  className="diviMainDiv"
                                  style={{
                                    marginTop: "22px",
                                  }}
                                >
                                  <p className="diviMainDivP">
                                    <b>Mot de passe</b>
                                  </p>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <div className="proteInputMain">
                                    <img
                                      src={secure}
                                      alt=""
                                      style={{
                                        marginTop: "9px",
                                        color: "#6c5858",
                                        width: "14px",
                                        height: "18px",
                                        marginLeft: "10px",
                                      }}
                                    />{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      type={passwordShown ? "text" : "password"}
                                      name="password"
                                      value={values.password}
                                      onChange={e => setValues({
                                        ...values,
                                        password: e.target.value
                                      })}
                                      className="inputBoxProteger"
                                      placeholder="Entrez le mot de passe choisi"
                                    ></input>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      width: "40px",
                                    }}
                                  >
                                    <BsFillEyeFill
                                      className="bseyyefill"
                                      onClick={() => togglePassword()}
                                      style={{
                                        width: "29px",
                                        height: "25px",
                                        color: "#d9ceca",
                                        marginTop: "6px",
                                      }}
                                    />
                                  </div>
                                </div>
                                {errors.password && (
                                  <p className="validationErrorsnew">
                                    {errors.password}
                                  </p>
                                )}
                                <div
                                  style={{ display: "flex", marginTop: "15px" }}
                                >
                                  <div className="proteInputMain">
                                    <img
                                      src={secure}
                                      alt=""
                                      style={{
                                        marginTop: "9px",
                                        color: "#6c5858",
                                        width: "14px",
                                        height: "18px",
                                        marginLeft: "10px",
                                      }}
                                    />{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                      type={
                                        passwordShownConfirm ? "text" : "password"
                                      }
                                      name="confirm_password"
                                      value={values.confirm_password}
                                      onChange={e => setValues({
                                        ...values,
                                        confirm_password: e.target.value
                                      })}
                                      className="inputBoxProteger"
                                      placeholder="Confirmer le mot de passe"
                                    ></input>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      width: "40px",
                                    }}
                                  >
                                    <BsFillEyeFill
                                      className="bseyyefill"
                                      onClick={() => togglePasswordConfirm()}
                                      style={{
                                        width: "29px",
                                        height: "25px",
                                        color: "#d9ceca",
                                        // color: "#b78b8b",
                                        marginTop: "6px",
                                      }}
                                    />
                                  </div>
                                </div>
                                {errors.confirm_password && (
                                  <p className="validationErrorsnew">
                                    {errors.confirm_password}
                                  </p>
                                )}
                                <div
                                  className="txtDiv"
                                  style={{
                                    marginTop: "15px",
                                  }}
                                  onClick={() => {
                                    processed !== null && ose ? DownloadFile(processed.download_filename) : fileConvertNew1();
                                  }}
                                >
                                  {processed !== null && ose ? (
                                    <div>
                                      <BsDownload
                                        style={{ marginTop: "8px" }}
                                      />
                                    </div>
                                  ) : (
                                    <span>
                                      {isLoading && ose ? (
                                        <Spinner
                                          style={{ marginTop: "10px" }}
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            padding: "6px 5px",
                                            fontSize: "16px",
                                            color: "#5d5446",
                                          }}
                                        >
                                          Portéger et enregister le fichier{" "}
                                          <b>sous le même nom</b>
                                        </p>
                                      )}
                                    </span>
                                  )}
                                </div>
                                <div className="txtDiv">
                                  <p
                                    style={{
                                      padding: "6px 5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Portéger et enregister le fichier{" "}
                                    <b>sous le nom suivant</b>
                                  </p>
                                </div>
                                <div className="txtDiv1">
                                  <input
                                    onChange={e => setFileName(e.target.value)}
                                    value={fileName}
                                    className="inputBoxProteger"
                                  ></input>
                                  {processed !== null && !ose ? (
                                    <span className="txtDiv1Sapn">
                                      <div onClick={() => DownloadFile(fileName)}>
                                        <BsDownload />
                                      </div>
                                    </span>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="txtDiv1Sapn okproteger"
                                      onClick={() => mergeFile()}
                                    >
                                      {isLoading && !ose ? (
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <b style={{ color: "#5d5446" }}>OK</b>
                                      )}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                            <div
                              className="leftBorderMainDiv"
                              style={{
                                minHeight: "300px",
                                marginBottom: "50px",
                              }}
                            >
                              <>
                                <Row style={{ marginTop: "15px" }}>
                                  <div className="pdfsMainDiv">
                                    <div className="dpfsDiv">
                                      <div
                                        className="avi"
                                        style={{ display: "grid" }}
                                      >
                                        <div>
                                          <Document
                                            file={file}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                          ><Page pageNumber={1} /></Document>
                                        </div>
                                        <div>
                                          <BsSearch
                                            className="searchIcon"
                                          // onClick={() => handleShow(v)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    // onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{file.name}</Modal.Title>
                      </Modal.Header>
                      <embed
                        className="full"
                        src={file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          // onClick={swapDiv}
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le fichier que vous souhaitez protézer
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge le fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        // appKey={APP_KEY}
                                        // success={handleSuccess}
                                        cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      // onClick={(e) => handleOpenPicker(e)}
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain"></Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
