import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
// import ppp from "./../../img/ppp.pdf";
import grille from "./../../img/grille.png";
import yellow from "./../../img/yellow.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Dropdown from "react-bootstrap/Dropdown";
import "./../../index.css";
import Top from "./../../component/top";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
import fontIcon from "./../../img/icons/fontIcon.png";
import boldIcon from "./../../img/icons/boldIcon.png";
import italicIcon from "./../../img/icons/italicIcon.png";
import underlineIcon from "./../../img/icons/underlineIcon.png";
import aIcon from "./../../img/icons/aIcon.png";

import { BsSearch, BsDownload } from "react-icons/bs";
import { DefaultEditor } from "react-simple-wysiwyg";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import desktop from "./../../img/icons/desktop.png";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";

import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Document, Page, pdfjs } from "react-pdf";
import { RichTextEditor } from "@mantine/rte";
import Spinner from "react-bootstrap/Spinner";

import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";

const APP_KEY = "ylhdren74lw3zw5";

export default function NumeroterPage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const {
    pdfStart,
    pdfAddNumber,
    addedNumber,
    pdfDownload,
    pdfResponse,
    download,
    clearCommon,
    clearPdf
  } = props;

  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [fileName, setFileName] = useState("");
  const [position, setPosition] = useState({ vertical: "top", horizontal: "left" });
  const [selected, setSelected] = useState({ start: "", end: "", startPage: 1 });
  const [pageType, setPageType] = useState(1);
  const [conFile, setConFile] = useState(false);
  const [doc, setDoc] = useState(false);
  const [conFile1, setConFile1] = useState(false);
  const [doc1, setDoc1] = useState(false);
  const [loder, setloder] = useState(false);
  const [texts, setTexts] = useState(null)
  const [ose, setOse] = useState(false);
  const [ose1, setOse1] = useState(false);

  const reset = () => {
    setFile(null);
    setNumPages(null);
    setFileName("");
    setPosition({ vertical: "top", horizontal: "left" });
    setSelected({ start: "", end: "", startPage: 1 });
    setPageType(1);
    setConFile(false);
    setDoc(false);
    setConFile1(false);
    setDoc1(false);
    setloder(false);
    setTexts(false);
    setOse(false);
    setOse1(false);
  }
  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const newFileName = (e) => {
    setFileName(e.target.value);
  };
  const showNotice = () => {
    alert("Veuillez remplir les champs de saisie ('De la page' et 'à la page')");
  }
  const showNoticeCustom = () => {
    alert("Veuillez remplir les champs de saisie ('De la page' et 'à la page') ou remplissez le nom personnalisé");
  }

  const mergeFile = () => {
    if (selected.start >= 1 && selected.end >= 1 && fileName != "") {
      pdfStart({ file, tool: "pagenumber" });
      setDoc(true);
      setOse(true)
    }
    else {
      showNoticeCustom();
    }
  };
  const mergeFile1 = () => {
    if (selected.start >= 1 && selected.end >= 1) {
      pdfStart({ file, tool: "pagenumber" });
      setConFile1(true);
      setOse1(true)
    } else {
      showNotice();
    }
  };
  console.warn("text", texts)
  useEffect(() => {
    if (pdfResponse !== null) {
      pdfAddNumber({
        task: pdfResponse,
        tool: "pagenumber",
        new_file: fileName,
        page_number: selected.start + "-" + selected.end,
        file: file,
        horizontal_position: position.horizontal,
        vertical_position: position.vertical,
        font_family: "Arial",
        font_size: "24",
        font_color: "#000000",
        text: texts,
        rename_file: fileName,
      });
    }
  }, [pdfResponse]);
  // useEffect(() => {
  //   if (addedNumber !== null) {
  //     let arr = [];
  //     arr.push(addedNumber.result.download_filename)
  //     pdfProcess({
  //       tool: "split",
  //       task: pdfResponse,
  //       merge_file: fileName,
  //       files: [
  //         {
  //           server_filename: addedNumber.server_filename,
  //           original_file: arr,
  //         },
  //       ],
  //     });
  //   }
  // }, [addedNumber]);

  useEffect(() => {
    if (addedNumber !== null) {
      if (ose1 !== false) {
        setDoc1(true);
      }
      if (ose !== false) {
        setConFile(true);
      }
    }
  }, [addedNumber]);

  // useEffect(() => {
  //   if (processed) {
  //     setConFile(true);
  //     setDoc1(true);
  //   }
  // }, [processed]);

  const DownloadFile = () => {
    if (addedNumber !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: addedNumber.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
  };
  const DownloadFileSname = () => {
    if (addedNumber !== null) {
      pdfDownload({
        task: pdfResponse,
        downloadName: addedNumber.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
  };

  useEffect(() => {
    if (download !== null) {
      setloder(false);
      clearPdf();
      clearCommon();
      reset();
    }
  }, [download]);

  const onSelecteFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b style={{ color: "#db9731", fontSize: "20px" }}>
                        Numéroter les pages
                      </b>
                      <>
                        <Row>
                          <Col sm={12} lg={7} style={{ marginTop: "10px" }}>
                            <Form style={{ display: "flex" }}>
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "28px",
                                  marginTop: "6px",
                                }}
                              />
                              <Form.Control
                                type="text"
                                className="input"
                                value={file.name}
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "14px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => (setFile(null), clearPdf(), reset(), clearCommon())}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                          </Col>
                          <Col sm={12} lg={5}></Col>
                        </Row>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "15px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  <b>Affichage des pages</b>
                                </p>
                              </div>
                              <div
                                className="affichageDiv"
                                style={{
                                  border: "1px solid #eee2d9",
                                  marginTop: "-16px",
                                  padding: "0 10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "5px",
                                    marginBottom: "-12px",
                                  }}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    className="check"
                                    checked={pageType == 1}
                                    onClick={() => setPageType(1)}
                                  />
                                  <p
                                    style={{
                                      marginTop: "3px",
                                      marginLeft: "5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>Page individuelle</b>
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "5px",
                                    marginBottom: "-12px",
                                  }}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    className="check"
                                    checked={pageType == 2}
                                    onClick={() => setPageType(2)}
                                  />
                                  <p
                                    style={{
                                      marginTop: "3px",
                                      marginLeft: "5px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>Pages composées</b>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "22px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  <b>Positionnement des pages</b>
                                </p>
                              </div>
                              <div
                                className="positionnement"
                                style={{
                                  border: "1px solid #eee2d9",
                                  marginTop: "-16px",
                                  padding: "0 10px",
                                }}
                              >
                                <div
                                  className="grilleGrid"
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      padding: "5px 0px",
                                    }}
                                  >
                                    <div className="positionsGrill">
                                      <div style={{ display: "flex" }}>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "top", horizontal: "left" })}
                                        >
                                          {position.vertical == "top" && position.horizontal == "left" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "top", horizontal: "center" })}
                                        >
                                          {position.vertical === "top" && position.horizontal == "center" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "top", horizontal: "right" })}
                                        >
                                          {position.vertical === "top" && position.horizontal == "right" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div
                                          className="positionsGrillDiv"
                                        >
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                        >
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                        >
                                        </div>
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "bottom", horizontal: "left" })}
                                        >
                                          {position.vertical === "bottom" && position.horizontal == "left" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "bottom", horizontal: "center" })}
                                        >
                                          {position.vertical === "bottom" && position.horizontal == "center" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                        <div
                                          className="positionsGrillDiv"
                                          onClick={() => setPosition({ vertical: "bottom", horizontal: "right" })}
                                        >
                                          {position.vertical === "bottom" && position.horizontal == "right" && (
                                            <img
                                              src={yellow}
                                              alt=""
                                              className="dotGrille1"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    style={{
                                      margin: "auto 6px",
                                      // maxWidth: "135px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>
                                      Position
                                      <br /> sur les pages
                                    </b>
                                  </p>
                                </div>
                                <div style={{ margin: "auto 0" }}>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>Marge</b>
                                  </p>
                                  <Form.Select className="texteDropdown" style={{
                                    fontSize: "14px",
                                    marginTop: "-10px",
                                    marginBottom: "10px",
                                    color: "#5d5446",
                                    width: "170px",
                                    height: "37px",
                                    textAlign: "left",
                                    borderRadius: "0px",
                                  }}>
                                    <option>Small</option>
                                    <option selected>Recommandé</option>
                                    <option>Big</option>
                                  </Form.Select>
                                  {/* <Dropdown>
                                    <Dropdown.Toggle
                                      variant="text"
                                      id="dropdown-basic"
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "-10px",
                                        marginBottom: "10px",
                                        color: "#5d5446",
                                        width: "170px",
                                        height: "37px",
                                        textAlign: "left",
                                        borderRadius: "0px",
                                      }}
                                    >
                                      Recommandé
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#/action-1">
                                        Action
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-2">
                                        Another action
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-3">
                                        Something else
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown> */}
                                </div>
                              </div>
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "15px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  <b>Numérotation</b>
                                </p>
                              </div>
                              <div
                                style={{
                                  border: "1px solid #eee2d9",
                                  marginTop: "-16px",
                                }}
                              >
                                <div
                                  className="numeroterdelaPage"
                                  style={{
                                    marginTop: "5px",
                                    padding: "0 10px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      <b>Numéroter de la page&nbsp;</b>
                                    </p>{" "}
                                    <input
                                      min="0"
                                      type="number"
                                      value={selected.start}
                                      onChange={e => setSelected({ ...selected, start: e.target.value })}
                                      style={{ width: "58px", height: "37px" }}
                                    />
                                  </div>
                                  <div
                                    className="alaPage1"
                                    style={{
                                      display: "flex",
                                      marginRight: "27",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      <b>à la la page &nbsp;</b>
                                    </p>{" "}
                                    <input
                                      min="0"
                                      type="number"
                                      value={selected.end}
                                      onChange={e => setSelected({ ...selected, end: e.target.value })}
                                      style={{ width: "58px", height: "37px" }}
                                    />
                                  </div>
                                </div>
                                <div className="premiuerPage">
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      marginTop: "5px",
                                      marginLeft: "38px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>Premier numéro &nbsp;</b>
                                  </p>{" "}
                                  <input
                                    min="0"
                                    type="number"
                                    value={selected.startPage}
                                    onChange={e => setSelected({ ...selected, startPage: e.target.value })}
                                    style={{ width: "58px", height: "37px" }}
                                  />
                                </div>
                                <div style={{ padding: "5px 10px" }}>
                                  <h5
                                    style={{
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>Texte à insérer</b>
                                  </h5>
                                  <Form.Select className="texteDrop" style={{
                                    fontSize: "14px",
                                    whiteSpace: "normal",
                                    textAlign: "left",
                                    color: "#5d5446",
                                    borderRadius: "0px",
                                  }}
                                    onChange={(e) => setTexts(e.target.value)}>
                                    <option value="" selected>Insérer uniquement le numéro de page
                                      (Recommandé)</option>
                                    <option value={1}>Page {"{n}"}</option>
                                    <option value={2}>Page {"{n}"} of {"{p}"}</option>
                                  </Form.Select>
                                  <h5
                                    style={{
                                      marginTop: "10px",
                                      fontSize: "16px",
                                      color: "#5d5446",
                                    }}
                                  >
                                    <b>Formatage du texte</b>
                                  </h5>
                                  <div className="editor">
                                    <div className="editorDiv1">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="text"
                                          id="dropdown-basic"
                                          className="texteDropdown"
                                          style={{
                                            margin: "5px 0",
                                            fontSize: "14px",
                                            color: "#5d5446",
                                            textAlign: "left",
                                            borderRadius: "0px",
                                          }}
                                        >
                                          Arial
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            Courier
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            Arial
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            Something else
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <div
                                      className="editorDiv2"
                                      style={{ overflow: "auto" }}
                                    >
                                      <div style={{ marginTop: "5px" }}>
                                        <img
                                          src={fontIcon}
                                          alt=""
                                          style={{
                                            marginLeft: "14px",
                                            height: "12px",
                                            marginTop: "3px",
                                          }}
                                        />
                                        <img
                                          src={boldIcon}
                                          alt=""
                                          style={{
                                            marginLeft: "15px",
                                            height: "12px",
                                            marginTop: "3px",
                                          }}
                                        />
                                        <img
                                          src={italicIcon}
                                          alt=""
                                          style={{
                                            marginLeft: "12px",
                                            height: "12px",
                                            marginTop: "3px",
                                          }}
                                        />
                                        <img
                                          src={underlineIcon}
                                          alt=""
                                          style={{
                                            marginLeft: "9px",
                                            marginTop: "6px",
                                            height: "15px",
                                          }}
                                        />
                                        <img
                                          src={aIcon}
                                          alt=""
                                          style={{
                                            marginLeft: "13px",
                                            marginTop: "3px",
                                            height: "15px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="txtDiv"
                                style={{
                                  marginTop: "10px",
                                }}
                                onClick={mergeFile1}
                              >
                                {doc1 ? (
                                  <div onClick={DownloadFileSname}>
                                    <BsDownload style={{ marginTop: "8px" }} />
                                  </div>
                                ) : (
                                  <span>
                                    {conFile1 ? (
                                      <Spinner
                                        style={{ marginTop: "10px" }}
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <p
                                        style={{
                                          padding: "6px 5px",
                                          fontSize: "16px",
                                          color: "#5d5446",
                                        }}
                                      >
                                        Numéroter et enregister le fichier{" "}
                                        <b>sous le même nom</b>
                                      </p>
                                    )}
                                  </span>
                                )}
                              </div>
                              <div className="txtDiv">
                                <p
                                  style={{
                                    padding: "6px 5px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Numéroter et enregister le fichier{" "}
                                  <b>sous le nom de suivant</b>
                                </p>
                              </div>
                              <div className="txtDiv1">
                                <input
                                  type="text"
                                  className="inputBoxProteger"
                                  onChange={newFileName}
                                ></input>
                                {conFile ? (
                                  <span className="txtDiv1Sapn">
                                    <div onClick={DownloadFile}>
                                      {loder ? (
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <BsDownload />
                                      )}
                                    </div>
                                  </span>
                                ) : (
                                  <span
                                    className="txtDiv1Sapn"
                                    onClick={mergeFile}
                                  >
                                    {doc ? (
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <b style={{ color: "#5d5446" }}>OK</b>
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                            <div className="leftBorderMainDiv">
                              <>
                                <Row
                                  className="numeroter"
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "50px",
                                  }}
                                >
                                  <div className="pdfsMainDiv">
                                    {Array.apply(null, Array(numPages)).map((x, i) => {
                                      return (
                                        <div className="dpfsDiv">
                                          <div
                                            className="avi"
                                            style={{ display: "grid" }}
                                          >
                                            <span>
                                              {selected.start <= i + 1 && selected.end > i ? (
                                                <img
                                                  src={yellow}
                                                  id="filigranerDotLoc"
                                                  alt=""
                                                  className={`${position.vertical}${position.horizontal}`}
                                                  style={{ position: "relative", zIndex: 1, width: "20px", height: "20px" }}
                                                />
                                              ) : (
                                                <div style={{ height: "24px" }}></div>
                                              )}
                                            </span>
                                            <div>
                                              <Document
                                                file={file}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                              >
                                                <Page pageNumber={i + 1} />
                                              </Document>
                                            </div>
                                            <div>
                                              <div
                                                style={{ textAlign: "center" }}
                                              >
                                                <BsSearch
                                                  className="midSearch"
                                                />
                                              </div>
                                            <div style={{ textAlign: "center", marginTop: "-25px", color: "#5d5446", fontWeight: "400" }}>
                                              {i + 1}
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Row>
                              </>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      <embed
                        className="full"
                        src={file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le fichier dans lequael vous souhaitez
                          ajouter des numéros de pages
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge le fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf, image/png, image/jpg, image/jpeg, .docx, .pptx, .xlsx"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        appKey={APP_KEY}
                                        // success={handleSuccess}
                                        cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>

                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      <embed
                        className="full"
                        src={file}
                      />
                    </Modal>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
