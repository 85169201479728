import ConvertirPage from './convertir.component';
import { connect } from 'react-redux';
import { pdfStart, pdfUpload, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import { setConvertType } from './../../api/user/userAction';
import { urlPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfUpload,
    urlPdf,
    pdfProcess,
    pdfDownload,
    setConvertType,
    clearCommon
}

const mapStateToProps = state => ({
    pdfResponse: state.commonPage.pdfResponse,
    uploaded: state.commonPage.uploaded,
    processed: state.commonPage.processed,
    download: state.commonPage.download,
    urlpdfdone: state.pdfPage.urlpdfdone,
    convertType: state.userPage.convertType,
    isLoading: state.commonPage.isLoading
});

export const ConvertirPageContainer = connect(mapStateToProps, mapDispatchToProps)(ConvertirPage); 