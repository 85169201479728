import * as actionTypes from "./userActionType";

const initialState = {
    isLoading: false,
    isError: false,
    isLoggedIn: sessionStorage.getItem("access_token") !== null ? true : false,
    loggedUser: sessionStorage.getItem("user") !== null ? sessionStorage.getItem("user") : null,
    convertType: { open: false, name: "", tool: "", setXcl: false, setPpt: false, setDoc: false }
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONVERT_TYPE_INIT:
            return {
                ...state,
               convertType: action.payload.data
            };
        case actionTypes.USER_LOGIN:
            return {
                ...state,
                isLoading: true,
                isError: false,  
                isLoggedIn: false,
                loggedUser: null
            };
        case actionTypes.USER_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true, 
                isLoggedIn: false,
                loggedUser: null
            };
        case actionTypes.USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : null,
            };
        case actionTypes.USER_REGISTER:
            return {
                ...state,
                isLoading: true,
                isError: false,  
                isLoggedIn: false,
                loggedUser: null
            };
        case actionTypes.USER_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true, 
                isLoggedIn: false,
                loggedUser: null
            };
        case actionTypes.USER_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : null, 
            };
        default: 
            return state
    }
}