import FiligranerPage from './filigraner.component';
import { connect } from 'react-redux';
import { pdfWatermark, clearPdf } from './../../api/pdf/pdfAction';
import { pdfStart, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';

const mapDispatchToProps = {
    pdfStart,
    pdfProcess,
    pdfWatermark,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    isError: state.pdfPage.isError,
    pdfResponse: state.commonPage.pdfResponse,
    processed: state.commonPage.processed,
    watermarked: state.pdfPage.watermarked,
    download: state.commonPage.download,
});

export const FiligranerPageContainer = connect(mapStateToProps, mapDispatchToProps)(FiligranerPage); 