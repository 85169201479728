import "./../../index.css";
import Footer from "./../../component/footer";
import Sidenav from "../../component/sidenav";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import React, {useState} from "react";
import compresser from "./../../img/compresser.png";
import convertir from "./../../img/convertir.png";
import divider from "./../../img/diviser.png";
import extraire from "./../../img/extraire.png";
import filigraner from "./../../img/filigraner.png";
import fusioner from "./../../img/fusioner.png";
import insérer from "./../../img/insrer.png";
import modifier from "./../../img/modifier.png";
import numéroter from "./../../img/numroter.png";
import pivoter from "./../../img/pivoter.png";
import protéger from "./../../img/protger.png";
import signer from "./../../img/signer.png";
import supprimer from "./../../img/supprimer.png";
import pictopdf from "./../../img/pictopdf.png";

export default function DashboardPage(props) {
  const {setConvertType, convertType} = props;
  const navigate = useNavigate();


  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow containerFluMainDivRowAct">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <>
                <Row className="pdfNav">
                  <Col
                    sm={12}
                    md={12}
                    lg={1}
                    style={{ paddingLeft: "0px" }}
                    className="pdfNavDiv"
                  >
                    <div className="imgCenterMainDiv">
                      <img src={pictopdf} alt="" className="pdfNavImgMain" />
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={11}
                    style={{
                      marginBottom: "40px",
                      paddingLeft: "17px",
                      paddingTop: "25px",
                    }}
                    className="pdfNavDiv"
                  >
                    <div className="dashboardWrapper">
                      <div
                        onClick={() => {
                          setConvertType({ tool: "compress" });
                          navigate("/compresser");
                        }}
                        className="dashboardSinDiv dashboardCompreDiv"
                      >
                        <img
                          src={compresser}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "111px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">COMPRESSER</p>
                        <p className="dashboardMainP2">
                          Réduire la taille de votre fichier PDF tout en gardant
                          une qualité optimale
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/convertir")}
                        className="dashboardSinDiv dashboardConverDiv"
                      >
                        <img
                          src={convertir}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "113px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">CONVERTIR</p>
                        <p className="dashboardMainP2">
                          Convertir vorte fichier PDF dans le Foemat de vorte
                          choix
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/diviser")}
                        className="dashboardSinDiv dashboardDivisDiv"
                      >
                        <img
                          src={divider}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "113px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">DIVISER</p>
                        <p className="dashboardMainP2">
                          Diviser ou convertie vorte fichier PDF en plusoeurs
                          fichiers indépendants
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/extraire")}
                        className="dashboardSinDiv dashboardExtraDiv"
                      >
                        <img
                          src={extraire}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "108px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">EXTRAIRE</p>
                        <p className="dashboardMainP2">
                          Extraire un partie du vorte fichier PDF pour créer un
                          nouveau document
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/filigraner")}
                        className="dashboardSecDiv dashboardFiliDiv"
                      >
                        <img
                          src={filigraner}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "104px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">FILIGRANER</p>
                        <p className="dashboardMainP2">
                          Insérer in fond ou une image dans vorte fichier PDF
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setConvertType({ tool: "merge" });
                          navigate("/fusionner");
                        }}
                        className="dashboardSinDiv dashboardFusionnDiv"
                      >
                        <img
                          src={fusioner}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "113px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">FUSIONNER</p>
                        <p className="dashboardMainP2">
                          Fusionner plusieurs fichiers PDF en un seul fichier
                          PDF
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/inserer")}
                        className="dashboardSinDiv dashboardInsererDiv"
                      >
                        <img
                          src={insérer}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "110px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">INSÉRER</p>
                        <p className="dashboardMainP2">
                          Insérer de nouvelles pages dans votre fichier PDF
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/modifier")}
                        className="dashboardSinDiv dashboardModiDiv"
                      >
                        <img
                          src={modifier}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "104px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">MODIFIER</p>
                        <p className="dashboardMainP2">
                          Modifier vos textes ou insérer
                          <br /> des éléments dans votre fichier PDF
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/numeroter")}
                        className="dashboardSinDiv dashboardNumeritDiv"
                      >
                        <img
                          src={numéroter}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "104px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">NUMÉROTER</p>
                        <p className="dashboardMainP2">
                          Insérer des numéros de pages dans votre fichier PDF
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/pivoter")}
                        className="dashboardSecDiv dashboardPivotDiv"
                      >
                        <img
                          src={pivoter}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "104px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">PIVOTER</p>
                        <p className="dashboardMainP2">
                          Changer l’orientation des pages ou de votre document
                          PDF
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/proteger")}
                        className="dashboardSinDiv dashboardSinDivmargin"
                      >
                        <img
                          src={protéger}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "104px", height: "68px" }}
                        />
                        <p className="dashboardMainP1">PROTÉGER</p>
                        <p className="dashboardMainP2">
                          Protéger votre fichier PDF avec un mot de passe
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/signer")}
                        className="dashboardSinDiv dashboardSigneDiv"
                      >
                        <img
                          src={signer}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "104px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">SIGNER</p>
                        <p className="dashboardMainP2">
                          Signer votre fichier PDF avec un certificat d’identité
                          numérique
                        </p>
                      </div>
                      <div
                        onClick={() => navigate("/supprimer")}
                        className="dashboardSecDiv dashboardSuppriDiv"
                      >
                        <img
                          src={supprimer}
                          alt=""
                          className="dashboardImg"
                          style={{ width: "104px", height: "67px" }}
                        />
                        <p className="dashboardMainP1">SUPPRIMER</p>
                        <p className="dashboardMainP2">
                          Supprimer des pages ou une partie de votre document
                          PDF
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}