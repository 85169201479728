import Form from "react-bootstrap/Form";
import browser from "./../../img/browser.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
import Spinner from "react-bootstrap/Spinner";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";
import "./../../index.css";
import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';

export default function ConvertirPage(props) {
    const {
        pdfStart,
        urlPdf,
        urlpdfdone,
        download,
        pdfDownload,
        pdfResponse,
        setConvertType,
    } = props;
    const [gd, setGd] = useState(false);
    const [loder, setloder] = useState(false);
    const [loder1, setloder1] = useState(false);
    const [conurl, setConurl] = useState("");
    const [filename, setFilename] = useState("");
    const [show, setShow] = useState(false);
    const [downloads, setDownloads] = useState(false);

    const reset = () => {
        setGd(false);
        setloder(false);
        setloder1(false);
        setConurl("");
        setFilename("");
        setShow(false);
        setDownloads(false);
    }
    useEffect(() => {
        return () => {
            reset();
        }
    }, [])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getUrl = (e) => {
        setConurl(e.target.value)
    }


    const urlpdfconvert = () => {
        pdfStart({ tool: "htmlpdf", })
    }
    useEffect(() => {
        if (pdfResponse !== null) {
            urlPdf({ tool: "htmlpdf", url: conurl });
        }
    }, [pdfResponse]);

    useEffect(() => {
        if (urlpdfdone) {
            setGd(true);
            setloder1(false);
            setDownloads(true);
            setFilename(urlpdfdone.result.download_filename)
        }
    }, [urlpdfdone]);

    const DownloadFile = () => {
        setloder(true)
        if (urlpdfdone) {
            pdfDownload({
                task: pdfResponse,
                downloadName: urlpdfdone.result.download_filename,
            });
            reset();
        }
    };
    useEffect(() => {
        if (download) {
            setloder(false);
            reset();
        }
    }, [download])


    return (
        <>
            <Container fluid className="containerFluMainDiv">
                <Row className="rowGrid containerFluMainDivRow">
                    <Col sm={12} md={1} lg={1} className="rowGridDiv">
                        <Sidenav />
                    </Col>
                    <Col sm={12} md={11} lg={11} className="rowGridDiv">
                        <div className="back">
                            <NavsPageContainer />
                            <Container fluid>
                                <Row>
                                    <Col sm={1} className="leftDiv1"></Col>
                                    <Col sm={11} className="leftDiv1 content">
                                        <b
                                            className="fusionnerh5 textHover"
                                            style={{
                                                color: "#db9731",
                                                fontSize: "20px",
                                                letterSpacing: "0px",
                                            }}
                                        >
                                            Télécharger le(s) fichier(s) que vous souhaitez
                                            convertir de HTML à PDF
                                        </b>
                                        <div>
                                            {gd ? (
                                                <div
                                                    className="comNewFlexnew"
                                                    style={{
                                                        marginTop: "17px",
                                                        marginBottom: "0px",
                                                    }}
                                                >
                                                    <div>
                                                        <p
                                                            style={{
                                                                fontSize: "16px",
                                                                letterSpacing: "0px",
                                                                lineHeight: "40px",
                                                                color: "#5d5446",
                                                                fontWeight: "400",
                                                                fontStyle: "italic",
                                                                fontFamily: "Open Sans",
                                                            }}
                                                        >
                                                            <img
                                                                src={browser}
                                                                alt=""
                                                                style={{
                                                                    width: "31px",
                                                                    height: "28px",
                                                                }}
                                                            />{" "}
                                                            &nbsp; {filename}
                                                        </p>
                                                    </div>
                                                    <img
                                                        src={closeIcon}
                                                        onClick={() => {
                                                            setGd(false);
                                                            downloads(false);
                                                        }}
                                                        alt=""
                                                        style={{
                                                            width: "19px",
                                                            height: "19px",
                                                            marginTop: "13px",
                                                            marginLeft: "15px"
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {downloads ?
                                                <Button className="urlpdfBtn" onClick={DownloadFile}>
                                                    {loder ?
                                                        <p>Please wait&nbsp;
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </p>
                                                        :
                                                        <p>Download</p>
                                                    }
                                                </Button> :
                                                <Button className="urlpdfBtn" onClick={handleShow}>
                                                    {loder1 ?
                                                        <p>Please wait&nbsp;
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </p>
                                                        :
                                                        <p>Add HTML Url</p>
                                                    }
                                                </Button>
                                            }
                                        </div>
                                        <>
                                            <Modal show={show} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>D'HTML à PDF</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Enter or paste your URL-</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                onChange={getUrl}
                                                                placeholder="https://example.com"
                                                                autoFocus
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant="success" onClick={() => {
                                                        urlpdfconvert();
                                                        handleClose();
                                                        setloder1(true);
                                                        setConvertType({
                                                            open: true,
                                                            name: "HTML à PDF",
                                                            tool: "htmlpdf",
                                                        });
                                                    }}>
                                                        Convert
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}
