import * as actionTypes from "./pdfActionType";
import axios from "axios";
import { baseUrl } from "./../../config";
import { success, error } from "./../api-result";
 
export const clearPdf = () => {
  return (dispatch) => {
    dispatch(clearPdfInit());
  };
};

export const clearPdfInit = () => ({
  type: actionTypes.CLEAR_PDF
});

export const pdfSplitFixRange = (data) => {
  // const ext =  data.file.name.split('.').pop();
  // const newfile = data.new_file+"."+ext;
  const params = new FormData();
  params.append("rename_file", data.rename_file);
  params.append("task", data.task);
  params.append("tool", data.tool);
  params.append("range", data.range);
  params.append("file", data.file);
  return (dispatch) => {
    dispatch(pdfSplitFixRangeInit());
    axios
      .post(`${baseUrl}/fixrange-file`, params)
      .then((response) => {
        dispatch(pdfSplitFixRangeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfSplitFixRangeFailure());
      });
  };
};

export const pdfSplitFixRangeInit = () => ({
  type: actionTypes.PDF_SPLIT_FIXRANGE,
});
export const pdfSplitFixRangeFailure = () => ({
  type: actionTypes.PDF_SPLIT_FIXRANGE_FAILURE,
});
export const pdfSplitFixRangeSuccess = (data) => ({
  type: actionTypes.PDF_SPLIT_FIXRANGE_SUCCESS,
  payload: { data },
});

export const pdfSplit = (data) => {
  // const ext =  data.file.name.split('.').pop();
  // const newfile = data.rename_file+"."+ext;
  const params = new FormData();
  params.append("task", data.task);
  params.append("tool", data.tool);
  params.append("rename_file", data.rename_file);
  params.append("range", data.range);
  params.append("file", data.file);
  return (dispatch) => {
    dispatch(pdfSplitInit());
    axios
      .post(`${baseUrl}/split-file`, params)
      .then((response) => {
        dispatch(pdfSplitSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfSplitFailure());
      });
  };
};

export const pdfSplitInit = () => ({
  type: actionTypes.PDF_SPLIT,
});
export const pdfSplitFailure = (data) => ({
  type: actionTypes.PDF_SPLIT_FAILURE,
});
export const pdfSplitSuccess = (data) => ({
  type: actionTypes.PDF_SPLIT_SUCCESS,
  payload: { data },
});

export const pdfWatermark = (data) => {
  // const ext =  data.file && data.file.name.split('.').pop();
  // const newfile = data.new_file+"."+ext;
  const params = new FormData();
  params.append("rename_file", data.rename_file);
  params.append("task", data.task);
  params.append("file", data.file);
  params.append("tool", data.tool);
  params.append("mode", data.mode);
  params.append("text", data.text);
  params.append("transparency", data.transparency);
  params.append("horizontal_position", data.horizontal_position);
  params.append("vertical_position", data.vertical_position);
  params.append("mosaic", data.mosaic);
  params.append("font_family", data.font_family);
  params.append("font_size", data.font_size);
  params.append("font_color", data.font_color);
  return (dispatch) => {
    dispatch(pdfWatermarkInit());
    axios
      .post(`${baseUrl}/watermark`, params)
      .then((response) => {
        dispatch(pdfWatermarkSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfWatermarkFailure());
      });
  };
};

export const pdfWatermarkInit = () => ({
  type: actionTypes.PDF_WATERMARK,
});
export const pdfWatermarkFailure = () => ({
  type: actionTypes.PDF_WATERMARK_FAILURE,
});
export const pdfWatermarkSuccess = (data) => ({
  type: actionTypes.PDF_WATERMARK_SUCCESS,
  payload: { data },
});

export const pdfMerge = (data) => {
  const params = new FormData();
  params.append("task", data.task);
  params.append("tool", data.tool);
  params.append("rotate", data.rotate);
  params.append("new_file", data.new_file+".pdf");
  data.file.forEach(function (key, index) {
    params.append(`file[${index}]`, data.file[index].file);
  });
  
  return (dispatch) => {
    dispatch(pdfMergeInit());
    axios
      .post(`${baseUrl}/merge`, params)
      .then((response) => {
        dispatch(pdfMergeSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfMergeFailure());
      });
  };
};

export const pdfMergeInit = () => ({
  type: actionTypes.PDF_MERGE,
});
export const pdfMergeFailure = (data) => ({
  type: actionTypes.PDF_MERGE_FAILURE,
});
export const pdfMergeSuccess = (data) => ({
  type: actionTypes.PDF_MERGE_SUCCESS,
  payload: { data },
});

export const pdfAddNumber = (data) => {
  // const ext =  data.file.name.split('.').pop();
  // const newfile = data.new_file+"."+ext;
  const params = new FormData();
  params.append("task", data.task);
  params.append("tool", data.tool);
  params.append("page_number", data.page_number);
  params.append("file", data.file);
  params.append("horizontal_position", data.horizontal_position);
  params.append("vertical_position", data.vertical_position);
  params.append("font_family", data.font_family);
  params.append("font_size", data.font_size);
  params.append("font_color", data.font_color);
  params.append("text", data.text);
  params.append("rename_file", data.rename_file);
  
  return (dispatch) => {
    dispatch(pdfAddNumberInit());
    axios
      .post(`${baseUrl}/add-number`, params)
      .then((response) => {
        dispatch(pdfAddNumberSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfAddNumberFailure());
      });
  };
};

export const pdfAddNumberInit = () => ({
  type: actionTypes.PDF_ADD_NUMBER,
});
export const pdfAddNumberFailure = (data) => ({
  type: actionTypes.PDF_ADD_NUMBER_FAILURE,
});
export const pdfAddNumberSuccess = (data) => ({
  type: actionTypes.PDF_ADD_NUMBER_SUCCESS,
  payload: { data },
});

export const pdfCompress = (data) => {
  // const ext =  data.file[0].name.split('.').pop();
  // const newfile = data.new_file+"."+ext;
  const params = new FormData();
  params.append("task", data.task);
  params.append("tool", data.tool);
  params.append("rename_file", data.rename_file);
  params.append("compression_level", data.compression_level);
  if (Array.isArray(data.file)) {
    data.file.forEach(function (key, index) {
      params.append(`file[${index}]`, data.file[index]);
    });
  } else {
    params.append("file", data.file);
  }
  
  return (dispatch) => {
    dispatch(pdfCompressInit());
    axios
      .post(`${baseUrl}/compress`, params)
      .then((response) => {
        dispatch(pdfCompressSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfCompressFailure());
      });
  };
};

export const pdfCompressInit = () => ({
  type: actionTypes.PDF_COMPRESS,
});
export const pdfCompressFailure = (data) => ({
  type: actionTypes.PDF_COMPRESS_FAILURE,
});
export const pdfCompressSuccess = (data) => ({
  type: actionTypes.PDF_COMPRESS_SUCCESS,
  payload: { data },
});

export const pdfProtect = (data) => {
  const ext =  data.file.name.split('.').pop();
  const newfile = data.new_file+"."+ext;
  const params = new FormData();
  params.append("task", data.task);
  params.append("tool", data.tool);
  params.append("password", data.password);
  params.append("new_file", newfile);
  params.append("file", data.file);
  return (dispatch) => {
    dispatch(pdfProtectInit());
    axios
      .post(`${baseUrl}/protect`, params)
      .then((response) => {
        dispatch(pdfProtectSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfProtectFailure());
      });
  };
};

export const pdfProtectInit = () => ({
  type: actionTypes.PDF_PROTECT,
});
export const pdfProtectFailure = (data) => ({
  type: actionTypes.PDF_PROTECT_FAILURE,
});
export const pdfProtectSuccess = (data) => ({
  type: actionTypes.PDF_PROTECT_SUCCESS,
  payload: { data },
});

export const pdfRotate = (data) => {
  const params = new FormData();
  params.append("tool", data.tool);
  params.append("rotate", data.rotate);
  params.append("file", data.file);
  return (dispatch) => {
    dispatch(pdfRotateInit());
    axios
      .post(`${baseUrl}/rotate`, params)
      .then((response) => {
        dispatch(pdfRotateSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(pdfRotateFailure());
      });
  };
};

export const pdfRotateInit = () => ({
  type: actionTypes.PDF_ROTATE,
});
export const pdfRotateFailure = (data) => ({
  type: actionTypes.PDF_ROTATE_FAILURE,
});
export const pdfRotateSuccess = (data) => ({
  type: actionTypes.PDF_ROTATE_SUCCESS,
  payload: { data },
});

export const urlPdf = (data) => {
  return (dispatch) => {
    dispatch(urlPdfInit());
    axios
      .post(`${baseUrl}/convert-html-file`, data)
      .then((response) => {
        dispatch(urlPdfSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(urlPdfFailure());
      });
  };
};

export const urlPdfInit = () => ({
  type: actionTypes.URL_PDF,
});
export const urlPdfFailure = (data) => ({
  type: actionTypes.URL_PDF_FAILURE,
});
export const urlPdfSuccess = (data) => ({
  type: actionTypes.URL_PDF_SUCCESS,
  payload: { data },
});