// import Sidenav from "../../component/sidenav";
// import Navs from "./../../component/nav";

// import React, { useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";

// import Top from "../../component/top/index";

import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
// import ppp from "./../../img/ppp.pdf";
// import paper from "./../../img/paper.jpg";
import closeIcon from "./../../img/icons/closeIcon.png";
import Modal from "react-bootstrap/Modal";
// import CloseIcon from "@mui/icons-material/Close";
import "./../../index.css";
// import Top from "./../../component/top";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";

import { BsSearch, BsDownload } from "react-icons/bs";
// import SamplePdf from "./../../pdf/SamplePdf.pdf";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";

import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Document, Page, pdfjs } from "react-pdf";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import desktop from "./../../img/icons/desktop.png";

import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";
import Spinner from "react-bootstrap/Spinner";

const APP_KEY = "ylhdren74lw3zw5";


export default function ExtrairePage(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const {
    pdfStart,
    pdfSplit,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    splited,
    download,
    clearCommon,
    clearPdf,
    isLoading
  } = props;

  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [numPages, setNumPages] = useState(2);
  const [custom, setCustom] = useState({ start: null, end: null });
  const [ose, setOse] = useState(false);
  const reset = () => {
    setFile(null);
    setName(null);
    setNumPages(null);
    setCustom({ start: null, end: null });
    setOse(false);
  }
  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const onSelecteFile = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function setCheck(type, value) {
    if (type == "checkAll") {
      setCustom({ start: 1, end: numPages });
    } else if (type == "uncheckAll") {
      setCustom({ start: "", end: "" });
    } else {
      setCustom({ ...custom, [type]: value });
    }
  }

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfSplit({
        file: file,
        task: pdfResponse,
        tool: "split",
        range: `${custom.start}-${custom.end}`,
        rename_file: name,
      });
    }
  }, [pdfResponse]);

  // useEffect(() => {
  //   if (splited) {
  //     let arr = [];
  //     arr.push(splited.original_file)
  //     pdfProcess({
  //       tool: "split",
  //       task: pdfResponse,
  //       merge_file: name,
  //       files: [
  //         {
  //           server_filename: splited.server_filename,
  //           original_file: arr,
  //         },
  //       ],
  //     });
  //   }
  // }, [splited]);

  const DownloadFile = () => {
    if (splited) {
      pdfDownload({
        task: pdfResponse,
        downloadName: splited.result.download_filename
      });
      reset();
      clearPdf();
      clearCommon();
    }
  };
  const DownloadFileSame = () => {
    if (splited) {
      pdfDownload({
        task: pdfResponse,
        downloadName: splited.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
  };

  useEffect(() => {
    if (download !== null) {
      setFile(null);
      clearPdf();
      clearCommon();
      reset();
    }
  }, [download]);

  const showNotice = () =>{
    alert("Veuillez remplir les champs de saisie ('De la page' et 'à la page')");
  }

  return (
    <>
      {/* <Top /> */}
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 contMainDiv">
                      <b style={{ color: "#db9731", fontSize: "20px" }}>
                        Extraire des pages
                      </b>
                      <>
                        <Row>
                          <Col sm={12} lg={7} style={{ marginTop: "10px" }}>
                            <Form style={{ display: "flex" }}>
                              <img
                                src={desktop}
                                alt=""
                                style={{
                                  width: "31px",
                                  height: "28px",
                                  marginTop: "6px",
                                }}
                              />
                              <Form.Control
                                type="text"
                                value={file && file.name}
                                className="input"
                                style={{
                                  maxWidth: "860px",
                                  height: "40px",
                                  marginLeft: "14px",
                                  borderRadius: "0px",
                                }}
                              />
                              <img
                                src={closeIcon}
                                onClick={() => {
                                  setFile(null);
                                  clearPdf();
                                  clearCommon();
                                  reset();
                                }}
                                alt=""
                                style={{
                                  height: "19px",
                                  width: "19px",
                                  marginTop: "10px",
                                  marginLeft: "5px",
                                }}
                              />
                            </Form>
                          </Col>
                          <Col
                            sm={12}
                            lg={5}
                            className="textRightPadding"
                            style={{
                              marginTop: "10px",
                              fontSize: "20px",
                              textAlign: "right",
                              color: "#5d5446",
                            }}
                          >
                            <b>Cochez les pages que vous souhaitez extraire</b>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="diviMainDiv de_select"
                                onClick={() => setCheck("checkAll", null)}
                                style={{
                                  marginTop: "20px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  Sélectionnez <b>TOUTES LES PAGES</b>
                                </p>
                              </div>
                              <div
                                className="diviMainDiv"
                                style={{
                                  marginTop: "20px",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "6px 0px",
                                    color: "#fff",
                                    fontSize: "16px",
                                  }}
                                >
                                  Sélectionnez <b>UNE PARTIE DU DOCUMENT</b>
                                </p>
                              </div>
                              <div
                                className="delapage"
                                style={{
                                  border: "1px solid #eee2d9",
                                  marginTop: "-16px",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="extraire"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    padding: "0 10px",
                                    marginBottom: "5px",
                                    width: "94%",
                                  }}
                                >
                                  <div>
                                    <b
                                      style={{
                                        fontSize: "16px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      De la page&nbsp;
                                    </b>{" "}
                                    <input
                                      type="number"
                                      value={custom.start}
                                      onChange={e => setCheck("start", e.target.value)}
                                      min="1"
                                      required
                                      style={{
                                        width: "52px",
                                        height: "27px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </div>
                                  <div className="aLaPage">
                                    <b
                                      style={{
                                        fontSize: "16px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      à la page&nbsp;&nbsp;&nbsp;
                                    </b>{" "}
                                    <input
                                      type="number"
                                      value={custom.end}
                                      onChange={e => setCheck("end", e.target.value)}
                                      min="1"
                                      required
                                      style={{
                                        width: "52px",
                                        height: "27px",
                                        marginTop: "5px",
                                        border: "1px solid #e9d9cd"
                                      }}
                                    />
                                  </div>
                                </div>
                                {/* <span
                                  style={{
                                    border: "1px solid #eee2d9",
                                    padding: "0 10px",
                                    height: "40px",
                                    width: "42px",
                                    marginTop: "auto",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <b
                                    style={{
                                      marginTop: "8px",
                                      position: "absolute",
                                      color: "#5D5446",
                                    }}
                                  >
                                    OK
                                  </b>
                                </span> */}
                              </div>
                              <div
                                className="diviMainDiv de_select"
                                onClick={() => setCheck("uncheckAll", null)}
                                style={{
                                  marginTop: "15px",
                                }}
                              >
                                <p className="diviMainDivP">
                                  <b>DÉSÉLECTIONNEZ TOUTES LES PAGES</b>
                                </p>
                              </div>
                              <div
                                className="txtDiv"
                                onClick={() => {
                                  if (splited !== null) {
                                    DownloadFileSame();
                                  } else {
                                    if( custom.start >= 1 && custom.end >= 1 ){
                                      pdfStart({ file, tool: "split" });
                                      setOse(true);
                                    } else{
                                      showNotice();
                                    }
                                  }
                                }}
                                style={{
                                  marginTop: "34px",
                                }}
                              >
                                {isLoading && ose ? (
                                  <Spinner
                                    style={{ marginTop: "10px" }}
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : splited !== null && ose ? (
                                  <BsDownload />
                                ) : (
                                  <span>
                                    <p
                                      style={{
                                        padding: "6px 5px",
                                        fontSize: "16px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Valider et extraire les pages{" "}
                                      <b>sous le même nom de fichier</b>
                                    </p>
                                  </span>
                                )}
                              </div>
                              <div className="txtDiv">
                                <p
                                  style={{
                                    padding: "6px 5px",
                                    fontSize: "16px",
                                    color: "#5d5446",
                                  }}
                                >
                                  Valider et extraire les pages{" "}
                                  <b>sous le nom de fichier suivant</b>
                                </p>
                              </div>
                              <div className="txtDiv1">
                                <input
                                  type="text"
                                  onChange={e => setName(e.target.value)}
                                  className="inputBoxProteger"
                                ></input>
                                {splited && !ose ? (
                                  <span className="txtDiv1Sapn">
                                    <div
                                      onClick={() => DownloadFile()}
                                    >
                                      {isLoading && !ose ? (
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <BsDownload />
                                      )}
                                    </div>
                                  </span>
                                ) : (
                                  <span
                                    className="txtDiv1Sapn"
                                    onClick={() => {
                                      if(custom.start >= 1 && custom.end >= 1 && name != null){
                                        pdfStart({ file, tool: "split" });
                                        setOse(false);
                                      } else{
                                        showNotice();
                                      }
                                    }}
                                  >
                                    {isLoading && !ose ? (
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <b style={{ color: "#5d5446" }}>OK</b>
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">
                            <div className="leftBorderMainDiv">
                              <>
                                <Row
                                  style={{
                                    marginTop: "15px",
                                    marginBottom: "50px",
                                  }}
                                >
                                  <div className="pdfsMainDiv">
                                    {Array.apply(null, Array(numPages))
                                      .map((x, i) => i + 1)
                                      .map((page) => (
                                        <div className="dpfsDiv pdfsDivMains">
                                          <div
                                            className="avi"
                                            style={{ display: "grid" }}
                                          >
                                            <div>
                                              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                                <Page pageNumber={page} />
                                              </Document>
                                            </div>
                                            <div>
                                              <BsSearch
                                                className="searchIcon"
                                              // onClick={() => handleShow(v)}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            <Form.Check
                                              type="checkbox"
                                              checked={custom.start <= page && custom.end >= page}
                                              className="checkBoxes check1"
                                            />
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </Row>
                              </>
                            </div>
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    // onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body>
          </Modal.Body> */}
                      <embed
                        className="full"
                        // src="https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf"
                        src={file && file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          // onClick={swapDiv}
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le fichier dont vous souhaitez extraire
                          des pages
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge mon fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf, image/png, image/jpg, image/jpeg, .docx, .pptx, .xlsx"
                                      // accept="image/png, image/jpg, image/jpeg"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        // appKey={APP_KEY}
                                        // success={handleSuccess}
                                        cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      // onClick={(e) => handleOpenPicker(e)}
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                    {/* <p
                                style={{ fontSize: "16px", color: "#5d5446" }}
                              >
                                <img
                                  src={amazon}
                                  alt=""
                                  style={{ width: "30px", height: "28px" }}
                                />{" "}
                                Amazon Drive
                              </p> */}
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col sm={12} lg={8} className="boxSizeMain">

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    // onHide={() => setShow(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>

                      <embed
                        className="full"
                        // src="https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf"
                        src={file && file}
                      />
                    </Modal>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
