import FusionnerPage from './fusionner.component';
import { connect } from 'react-redux';
import { pdfStart, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import { pdfMerge, clearPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfMerge,
    pdfProcess,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    isError: state.commonPage.isError,
    pdfResponse: state.commonPage.pdfResponse,
    merged: state.pdfPage.merged,
    processed: state.commonPage.processed,
    download: state.commonPage.download
});

export const FusionnerPageContainer = connect(mapStateToProps, mapDispatchToProps)(FusionnerPage);