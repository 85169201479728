import React from "react";
import SquareIcon from "@mui/icons-material/Square";
import { Container, Row, Col } from "react-bootstrap";
import "./../../index.css";
import { BsFillCaretDownFill } from "react-icons/bs";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pictopdf from "./../../img/pictopdf.png";

export default function Navs(props) {
  const { setConvertType, convertType, clearCommon, clearPdf } = props;


  const location = useLocation();

  const navigate = useNavigate();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  function clearData() {
    clearCommon();
    clearPdf();
  }

  return (
    <Container fluid className="containerFluMainDiv">
      <Row className="pdfNav">
        <Col
          sm={12}
          md={12}
          lg={1}
          style={{ paddingLeft: "0px" }}
          className="pdfNavDiv"
        >
          <div className="imgCenterMainDiv">
            <img src={pictopdf} alt="" className="pdfNavImgMain" />
          </div>
          {/* <BsFillFileEarmarkPdfFill
              style={{
                margin: "19px 0px 0px 20px",
                height: "50px",
                width: "50px",
                color: "#5d5446"
              }}
            />
          </div> */}
        </Col>
        <Col sm={12} md={12} lg={11} className="pdfNavDiv pdfABC">
          <div className="ulMainDivFor">
            <ul
              className="nav"
              style={{
                // borderBottom: "2px solid #000",
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "8px",
                paddingBottom: "15px",
                // padding: "0 10px"
              }}
            >
              <li
                // style={{ width: "116px"  }}
                className={splitLocation[1] === "compresser" ? "active" : ""}
              >
                <Link
                  onClick={() => {
                    clearData();
                    setConvertType({ tool: "compress" });
                  }}
                  className="nav-link navTexts textColor"
                  to="/compresser"
                >
                  Compresser
                </Link>
              </li>
              <li
                // style={{ width: "114px"  }}
                className={splitLocation[1] === "convertir" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/convertir">
                  Convertir{" "}
                </Link>
                  <span
                    className="converterIcons"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    <BsFillCaretDownFill />
                  </span>
                <div style={{ width: "auto" }}>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <div
                      style={{
                        background: "#e9d9cd",
                        textAlign: "center",
                        marginTop: "-8px",
                        borderRadius: "5px",
                      }}
                    >
                      <h5>Type de converstion</h5>
                    </div>
                    <div>
                      <div className="row tableDiv">
                        <div className="col-6 unl">
                          <li
                            className="convertirDropL"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                name: "PDF à Excel",
                                tool: "pdfoffice",
                                type: "application/pdf",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> De PDF à
                            Excel
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                name: "PDF à jpg",
                                tool: "pdfjpg",
                                type: "application/pdf",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> De PDF à
                            jpg
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                name: "PDF à PDF/A",
                                tool: "pdfa",
                                type: "application/pdf",
                              });
                              navigate("/convertir");
                            }}
                          >
                            {/* // className="dropdown-item textColor1" */}
                            <SquareIcon style={{ color: "#DB9732" }} /> De PDF à
                            PDF/A
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                name: "PDF à PowerPoint",
                                tool: "pdfoffice",
                                type: "application/pdf",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> De PDF à
                            PowerPoint
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                name: "PDF à Word",
                                tool: "pdfoffice",
                                type: "application/pdf",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> De PDF à
                            Word
                          </li>
                        </div>
                        <div className="col-6 unl">
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                setXcl: true,
                                name: "Excel à PDF",
                                tool: "officepdf",
                                type: ".xlsx, .xls",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> D'Excel
                            à PDF
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                name: "HTML à PDF",
                                tool: "",
                              });
                              navigate("/url_to_pdf");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> D'HTML à
                            PDF
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                name: "JPG à PDF",
                                tool: "imagepdf",
                                type: " image/png, image/jpg, image/jpeg,",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> De JPG à
                            PDF
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                setPpt: true,
                                name: "PowerPoint à PDF",
                                tool: "officepdf",
                                type: ".pptx",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> De
                            PowerPoint à PDF
                          </li>
                          <li
                            className="convertirDropLi"
                            onClick={() => {
                              clearData();
                              setConvertType({
                                open: true,
                                setDoc: true,
                                name: "Word à PDF",
                                tool: "officepdf",
                                type: ".docx",
                              });
                              navigate("/convertir");
                            }}
                          >
                            <SquareIcon style={{ color: "#DB9732" }} /> De Word
                            à PDF
                          </li>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#e9d9cd",
                        height: "5px",
                        marginBottom: "-8px",
                        borderRadius: "5px",
                      }}
                    />
                  </ul>
                </div>
              </li>
              <li
                // style={{ width: "76px"  }}
                className={splitLocation[1] === "diviser" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/diviser">
                  Diviser
                </Link>
              </li>
              <li
                // style={{ width: "84px"  }}
                className={splitLocation[1] === "extraire" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/extraire">
                  Extraire
                </Link>
              </li>
              <li
                // style={{ width: "96px"  }}
                className={splitLocation[1] === "filigraner" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/filigraner">
                  Filigraner
                </Link>
              </li>
              <li
                // style={{ width: "100px"  }}
                className={splitLocation[1] === "fusionner" ? "active" : ""}
              >
                <Link
                  onClick={() => {
                    clearData();
                    setConvertType({ tool: "merge" });
                  }}
                  className="nav-link navTexts textColor"
                  to="/fusionner"
                >
                  Fusionner
                </Link>
              </li>
              {/* <li style={{ width: "79px"  }} className={splitLocation[1] === "inserer" ? "active" : ""}>
                <Link
                  className="nav-link navTexts textColor"
                  to="/inserer"
                >
                  Insérer
                </Link>
              </li> */}
              <li
                // style={{ width: "88px"  }}
                className={splitLocation[1] === "modifier" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/modifier">
                  Modifier
                </Link>
              </li>
              <li
                // style={{ width: "109px"  }}
                className={splitLocation[1] === "numeroter" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/numeroter">
                  Numéroter
                </Link>
              </li>
              <li
                // style={{ width: "78px"  }}
                className={splitLocation[1] === "pivoter" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/pivoter">
                  Pivoter
                </Link>
              </li>
              <li
                // style={{ width: "90px"  }}
                className={splitLocation[1] === "proteger" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/proteger">
                  Protéger
                </Link>
              </li>
              <li
                // style={{ width: "73px"  }}
                className={splitLocation[1] === "signer" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/signer">
                  Signer
                </Link>
              </li>
              <li
                // style={{ width: "188px"  }}
                className={splitLocation[1] === "supprimer" ? "active" : ""}
              >
                <Link onClick={() => clearData()} className="nav-link navTexts textColor" to="/supprimer">
                  Supprimer des pages
                </Link>
              </li>
            </ul>
          </div>
          <div className="navUndermineDiv" />
        </Col>
      </Row>
    </Container>
  );
}
