export const CLEAR_PDF = "CLEAR_PDF";

export const PDF_SPLIT_FIXRANGE = "PDF_SPLIT_FIXRANGE";
export const PDF_SPLIT_FIXRANGE_SUCCESS = "PDF_SPLIT_FIXRANGE_SUCCESS";
export const PDF_SPLIT_FIXRANGE_FAILURE = "PDF_SPLIT_FIXRANGE_FAILURE";

export const PDF_SPLIT = "PDF_SPLIT";
export const PDF_SPLIT_SUCCESS = "PDF_SPLIT_SUCCESS";
export const PDF_SPLIT_FAILURE = "PDF_SPLIT_FAILURE";

export const PDF_WATERMARK = "PDF_WATERMARK";
export const PDF_WATERMARK_SUCCESS = "PDF_WATERMARK_SUCCESS";
export const PDF_WATERMARK_FAILURE = "PDF_WATERMARK_FAILURE";

export const PDF_MERGE = "PDF_MERGE";
export const PDF_MERGE_SUCCESS = "PDF_MERGE_SUCCESS";
export const PDF_MERGE_FAILURE = "PDF_MERGE_FAILURE";

export const PDF_ADD_NUMBER = "PDF_ADD_NUMBER";
export const PDF_ADD_NUMBER_SUCCESS = "PDF_ADD_NUMBER_SUCCESS";
export const PDF_ADD_NUMBER_FAILURE = "PDF_ADD_NUMBER_FAILURE";

export const PDF_COMPRESS = "PDF_COMPRESS";
export const PDF_COMPRESS_SUCCESS = "PDF_COMPRESS_SUCCESS";
export const PDF_COMPRESS_FAILURE = "PDF_COMPRESS_FAILURE";

export const PDF_PROTECT = "PDF_PROTECT";
export const PDF_PROTECT_SUCCESS = "PDF_PROTECT_SUCCESS";
export const PDF_PROTECT_FAILURE = "PDF_PROTECT_FAILURE";

export const PDF_ROTATE = "PDF_ROTATE";
export const PDF_ROTATE_SUCCESS = "PDF_ROTATE_SUCCESS";
export const PDF_ROTATE_FAILURE = "PDF_ROTATE_FAILURE";

export const URL_PDF = "URL_PDF";
export const URL_PDF_SUCCESS = "URL_PDF_SUCCESS";
export const URL_PDF_FAILURE = "URL_PDF_FAILURE";