import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./../../index.css";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import logSign from "./../../img/logSign.png";
import google from "./../../img/google.png";
import {
  BsFillEnvelopeFill,
  BsFillLockFill,
  BsChevronDown,
  BsFacebook,
} from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function LoginPage(props) {
  const { userLogin, isLoggedIn } = props;

  const [values, setValues] = React.useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  if (isLoggedIn) {
    setTimeout(() => { navigate("/") }, 2000)
  }
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  // validation

  const [errors, setErrors] = React.useState({});
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.email && !values.password) {
      isError = true;
      errorss.email = "Email is required";
      errorss.password = "Password is required";
    } else if (!values.email) {
      isError = true;
      errorss.email = "Email is required";
    } else if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    } else if (!regex.test(values.email)) {
      isError = true;
      errorss.email = "This is not a valid email format";
    }
    if (!isError) {
      userLogin(values);
    }
    return errorss;
  };

  return (
    <Container fluid className="containerFluMainDiv">
      <Row className="containerFluMainDivRow">
        <Col
          sm={12}
          md={12}
          lg={7}
          style={{
            background: "#FFFFFF",
          }}
        >
          <div className="loginRoot">
            <div className="loginWrapper">
              <div style={{ textAlign: "center" }}>
                <h1>
                  O<span style={{ color: "red" }}>v</span>idya
                </h1>
                <h5>Login to your account</h5>
              </div>
              <div className="buttonDiv">
              </div>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setErrors(validate(values));
                }}
              >
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <BsFillEnvelopeFill />
                  </InputGroup.Text>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter your email"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.email && (
                  <p className="validationErrors">{errors.email}</p>
                )}
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <BsFillLockFill />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Password"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.password && (
                  <p className="validationErrors">{errors.password}</p>
                )}
                <div style={{ textAlign: "center" }}>
                  <p style={{ color: "red" }}>Forgot your password?</p>
                  <button
                    type="submit"
                    className="btn btn-dark btn-lg btn-block"
                    style={{
                      margin: "10px auto",
                      background: "red",
                      border: "1px solid red",
                    }}
                  >
                    Log in
                  </button>
                  <p
                    className="forgot-password text-right"
                    style={{ marginTop: "10px" }}
                  >
                    Don't have an account{" "}
                    <Link to="/signup" style={{ color: "red" }}>
                      Create an account
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>

        </Col>
        <Col
          sm={12}
          md={12}
          lg={5}
          style={{
            background: "#F2F3F8",
          }}
        >
          <div style={{ maxWidth: "398px", margin: "23% auto" }}>
            <img src={logSign} alt="" style={{ width: "100%" }} />
            <h3>Log in to your workspace</h3>
            <p>
              Enter your email and password to access your Ovidya account. You
              are one step closer to boosting your document productivity.
            </p>
            <p>
              see all tools <BsChevronDown />
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
