import LoginPage from './login.component';
import { connect } from 'react-redux';
import { userLogin } from './../../api/user/userAction';

const mapDispatchToProps = {
    userLogin
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    isLoggedIn: state.userPage.isLoggedIn,
});

export const LoginPageContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage); 