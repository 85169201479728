import UrlpdfPage from './urlpdf.component';
import { connect } from 'react-redux';
import { pdfStart, pdfUpload, pdfProcess, pdfDownload } from './../../api/common/commonAction';
import { setConvertType } from './../../api/user/userAction';
import { urlPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfUpload,
    urlPdf,
    pdfProcess,
    pdfDownload,
    setConvertType
}

const mapStateToProps = state => ({
    pdfResponse: state.commonPage.pdfResponse,
    uploaded: state.commonPage.uploaded,
    urlpdfdone: state.pdfPage.urlpdfdone,
    processed: state.commonPage.processed,
    download: state.commonPage.download,
    convertType: state.userPage.convertType,
});

export const UrlpdfPageContainer = connect(mapStateToProps, mapDispatchToProps)(UrlpdfPage); 