import DiviserPage from './diviser.component';
import { connect } from 'react-redux';
import { pdfStart, pdfUpload, pdfProcess, pdfDownload, clearCommon } from './../../api/common/commonAction';
import { pdfSplitFixRange, pdfSplit, clearPdf } from './../../api/pdf/pdfAction';

const mapDispatchToProps = {
    pdfStart,
    pdfSplit,
    pdfSplitFixRange,
    pdfUpload,
    pdfProcess,
    pdfDownload,
    clearCommon,
    clearPdf
}

const mapStateToProps = state => ({
    pdfResponse: state.commonPage.pdfResponse,
    isLoading: state.commonPage.isLoading || state.pdfPage.isLoading,
    splited: state.pdfPage.splited,
    splitedFix: state.pdfPage.splitedFix,
    CLEAR_PDF: state.pdfPage.CLEAR_PDF,
    uploaded: state.commonPage.uploaded,
    processed: state.commonPage.processed,
    download: state.commonPage.download
});

export const DiviserPageContainer = connect(mapStateToProps, mapDispatchToProps)(DiviserPage); 