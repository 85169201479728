import Form from "react-bootstrap/Form";
import gDrive from "./../../img/gDrive.png";
import ovidyaSymbol from "./../../img/ovidyaSymbol.png";
import paper from "./../../img/paper.jpg";
import desktop from "./../../img/icons/desktop.png";
import closeIcon from "./../../img/icons/closeIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import DesktopWindowsTwoToneIcon from "@mui/icons-material/DesktopWindowsTwoTone";
import Modal from "react-bootstrap/Modal";
import Top from "./../../component/top";
import Footer from "./../../component/footer";
import { NavsPageContainer } from "./../../component/nav/index.converter";
import Sidenav from "../../component/sidenav";
import dropbox from "./../../img/dropbox.png";
import Icloud from "./../../img/Icloud.png";
import Spinner from "react-bootstrap/Spinner";

// bootstrap
import { Container, Row, Col } from "react-bootstrap";
import "./../../index.css";
import React, { useState, useEffect } from "react";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Document, Page, pdfjs } from "react-pdf";
import { BsSearch, BsDownload } from "react-icons/bs";
import DropboxChooser from "react-dropbox-chooser";

const APP_KEY = "ylhdren74lw3zw5";
export default function CompresserPage(props) {
  const {
    pdfStart,
    pdfCompress,
    pdfProcess,
    pdfDownload,
    pdfResponse,
    compressed,
    processed,
    isLoading,
    download,
    clearCommon,
    clearPdf
  } = props;
  const [file, setFile] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [n, setN] = useState(false);
  const [level, setLevel] = useState("recommended");
  const [ose, setOse] = useState(false);
  const [fileName, setFileName] = React.useState("");

  const reset = () => {
    setFile([]);
    setNumPages(null);
    setN(false);
    setLevel("recommended");
    setOse(false);
    setFileName("");
  }
  React.useEffect(()=>{
    return () => {
     reset();
    }
   },[])

  const onSelecteFile = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(file.concat([e.target.files[0]]));
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function removeFile(i) {
    setFile((file) => file.filter((x, index) => i !== index))
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // API start

  const convertFile = () => {
    pdfStart({ file, tool: "compress" });
    setOse(true);
  };
  const fileConvertNew = () => {
    pdfStart({ file, tool: "compress" });
    setOse(false);
  };

  useEffect(() => {
    if (pdfResponse !== null) {
      pdfCompress({
        file: file,
        task: pdfResponse,
        tool: "compress",
        compression_level: level,
        rename_file: fileName,
      });
    }
  }, [pdfResponse]);

  // useEffect(() => {
  //   if (compressed) {
  //     pdfProcess({
  //       tool: "compress",
  //       task: pdfResponse,
  //       merge_file: fileName,
  //       files: [
  //         {
  //           server_filename: compressed.server_filename,
  //           original_file: compressed.original_file,
  //         },
  //       ],
  //     });
  //   }
  // }, [compressed]);

  const DownloadFile = () => {
    if (compressed) {
      pdfDownload({
        task: pdfResponse,
        downloadName: compressed.result.download_filename,
      });
      reset();
      clearPdf();
      clearCommon();
    }
  };
  useEffect(()=>{
    if(download != null){
      reset();
      clearCommon();
      clearPdf();
    }
  },[download])

  // API end

  return (
    <>
      <Container fluid className="containerFluMainDiv">
        <Row className="rowGrid containerFluMainDivRow">
          <Col sm={12} md={1} lg={1} className="rowGridDiv">
            <Sidenav />
          </Col>
          <Col sm={12} md={11} lg={11} className="rowGridDiv">
            <div className="back">
              <NavsPageContainer />
              {file.length > 0 && n ? (
                <Container fluid>
                  <Row>
                    <Col sm={1} className="leftDiv1"></Col>
                    <Col sm={11} className="leftDiv1 content">
                      <b
                        className="textHover"
                        style={{
                          color: "#db9731",
                          marginTop: "10px",
                          fontSize: "20px",
                        }}
                      >
                        Compresser le fichier / les fichiers
                      </b>
                      <>
                        <>
                          <Row
                            style={{
                              paddingTop: "12px",
                            }}
                          >
                            <Col sm={12} lg={2} className="compresser">
                              {file.map(y => {
                                return (
                                  <div className="dpfsDiv">
                                    <div
                                      className="avi"
                                      style={{
                                        display: "grid",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div>
                                        <Document
                                          file={y}
                                          onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                          <Page pageNumber={1} />
                                        </Document>
                                      </div>
                                      <div>
                                        <BsSearch
                                          className="search"
                                        // onClick={() => handleShow(v)}
                                        />

                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </Col>
                            <Col sm={12} lg={10} className="alaxDiv">
                              <Form
                                style={{ display: "flex", marginTop: "10px" }}
                              >
                                <img
                                  src={desktop}
                                  alt=""
                                  style={{
                                    width: "31px",
                                    height: "27px",
                                    marginTop: "6px",
                                  }}
                                />{" "}
                                <Form.Control
                                  type="text"
                                  className="input"
                                  value={file[0].name}
                                  style={{
                                    maxWidth: "860px",
                                    height: "40px",
                                    marginLeft: "10px",
                                    borderRadius: "0px",
                                  }}
                                />
                                <img
                                  src={closeIcon}
                                  alt=""
                                  style={{
                                    height: "19px",
                                    width: "19px",
                                    marginTop: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => {
                                    setFile([]);
                                    setN(false);
                                    reset();
                                    clearPdf();
                                    clearCommon();
                                  }}
                                />
                              </Form>
                              <div className="comMainDiv">
                                <div className="comMainDiv1">
                                  <h4
                                    style={{
                                      color: "#db9731",
                                      marginTop: "10px",
                                      fontSize: "20px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Choisissez le niveau de compression
                                  </h4>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      className="check checkBlack"
                                      checked={level === "extreme"}
                                      onClick={() => setLevel("extreme")}
                                    />
                                    <h5
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "16px",
                                        color: "#5d5446",
                                        marginTop: "5px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Elevé &nbsp;&nbsp;&nbsp;
                                      <span
                                        style={{
                                          color: "#5d5446",
                                          fontWeight: "300",
                                          fontSize: "16px",
                                        }}
                                      >
                                        (Forte compression - Faible qualité)
                                      </span>
                                    </h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      checked={level === "recommended"}
                                      onClick={() => setLevel("recommended")}
                                      className="check checkBlack"
                                    />
                                    <h5
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "16px",
                                        color: "#5d5446",
                                        marginTop: "5px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      &nbsp;Moyen &nbsp;
                                      <span
                                        style={{
                                          color: "#5d5446",
                                          fontWeight: "300",
                                          fontSize: "16px",
                                        }}
                                      >
                                        (Recommandé)
                                      </span>
                                    </h5>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      className="check checkBlack"
                                      checked={level === "low"}
                                      onClick={() => setLevel("low")}
                                    />
                                    <h5
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "16px",
                                        color: "#5d5446",
                                        marginTop: "5px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Bas&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span
                                        style={{
                                          color: "#5d5446",
                                          fontWeight: "300",
                                          fontSize: "16px",
                                        }}
                                      >
                                        (Forte compression - Qualité
                                        maintenue)
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                                <div className="comMainDiv2">
                                  <div
                                    className="txtDiv"
                                    style={{
                                      marginTop: "15px",
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      compressed !== null && ose ? DownloadFile() : convertFile();
                                    }}
                                  >
                                    {compressed !== null && ose ? (
                                      <div>
                                        <BsDownload
                                          style={{ marginTop: "8px" }}
                                        />
                                      </div>
                                    ) : (
                                      <span>
                                        {isLoading && ose ? (
                                          <Spinner
                                            style={{ marginTop: "10px" }}
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <p
                                            style={{
                                              padding: "6px 5px",
                                              fontSize: "16px",
                                              color: "#5d5446",
                                            }}
                                          >
                                            Compresser et enregistrer le
                                            fichier <b>sous le même nom</b>
                                          </p>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                  <div className="txtDiv">
                                    <p
                                      style={{
                                        padding: "6px 5px",
                                        fontSize: "16px",
                                        color: "#5d5446",
                                      }}
                                    >
                                      Compresser et enregistrer le fichier{" "}
                                      <b>sous le nom suivant</b>
                                    </p>
                                  </div>
                                  <div className="txtDiv1">
                                    <input
                                      type="text"
                                      onChange={e => setFileName(e.target.value)}
                                      value={fileName}
                                      className="inputBoxProteger"
                                    ></input>
                                    {compressed !== null && !ose ? (
                                      <span className="txtDiv1Sapn">
                                        <div onClick={() => DownloadFile()}>
                                          <BsDownload />
                                        </div>
                                      </span>
                                    ) : (
                                      <span
                                        className="txtDiv1Sapn"
                                        onClick={fileConvertNew}
                                      >
                                        {isLoading && !ose ? (
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <b style={{ color: "#5d5446" }}>
                                            OK
                                          </b>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div
                            style={{
                              borderBottom: "1px solid #5D5446",
                              paddingTop: "12px",
                            }}
                          />
                        </>
                      </>
                    </Col>
                  </Row>
                  <>
                    <Modal
                      show={false}
                      fullscreen
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>PDF name here</Modal.Title>
                      </Modal.Header>
                      {/* <Modal.Body>
          </Modal.Body> */}
                      <embed
                        className="full"
                        // src="https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf"
                        src={file}
                      />
                    </Modal>
                  </>
                </Container>
              ) : (
                <Container fluid>
                  <>
                    <Row>
                      <Col sm={1} className="leftDiv1"></Col>
                      <Col sm={11} className="leftDiv1 contMainDiv">
                        <b
                          // onClick={swapDiv}
                          className="fusionnerh5 textHover"
                          style={{
                            color: "#db9731",
                            fontSize: "20px",
                            letterSpacing: "0px",
                          }}
                        >
                          Télécharger le(s) fichier(s) que vous souhaitez
                          compresser
                        </b>
                        <Row>
                          <Col
                            sm={12}
                            lg={4}
                            className="leftMainWrapper boxSizeMain"
                          >
                            <div className="maxWDiv">
                              <div
                                className="fusiMainDiv"
                                style={{
                                  marginTop: "17px",
                                }}
                              >
                                <p className="fusiMainDivP">
                                  <b>Je télécharge mon fichier depuis</b>
                                </p>
                              </div>
                              <>
                                <div className="fusionnerJE">
                                  <div>
                                    <label
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <input
                                        style={{ display: "none" }}
                                        type="file"
                                        name="pdf"
                                        onChange={onSelecteFile}
                                        multiple
                                        accept="application/pdf"
                                      />
                                      <img
                                        src={desktop}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "27px",
                                          marginTop: "6px",
                                        }}
                                      />{" "}
                                      Mon ordinateur
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={ovidyaSymbol}
                                        alt=""
                                        style={{
                                          width: "32px",
                                          height: "34px",
                                        }}
                                      />{" "}
                                      Mon espace Ovidya
                                    </p>
                                    <lable
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <DropboxChooser
                                        // appKey={APP_KEY}
                                        // success={handleSuccess}
                                        cancel={() => console.log("closed")}
                                        multiselect={true}
                                      >
                                        <img
                                          src={dropbox}
                                          alt=""
                                          style={{
                                            width: "32px",
                                            height: "27px",
                                          }}
                                        />{" "}
                                        Ma Dropbox
                                      </DropboxChooser>
                                    </lable>
                                  </div>
                                  <div className="fusionnerJEDiv">
                                    <label
                                      // onClick={(e) => handleOpenPicker(e)}
                                      className="gdhover"
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        marginBottom: "16px",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={gDrive}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "28px",
                                        }}
                                      />{" "}
                                      Google Drive
                                    </label>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        letterSpacing: "0px",
                                        lineHeight: "40px",
                                        color: "#5d5446",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      <img
                                        src={Icloud}
                                        alt=""
                                        style={{
                                          width: "31px",
                                          height: "31px",
                                        }}
                                      />{" "}
                                      Icloud Drive
                                    </p>
                                  </div>
                                </div>
                              </>
                            </div>
                          </Col>
                          <Col
                            sm={12}
                            lg={8}
                            className="boxSizeMain boxSizeMainss"
                          >
                            <div className="comNewRDiv comNewRDivss">
                              <div>
                                <div
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid #d9ceca",
                                    height: "36px",
                                    padding: "6px 5px",
                                  }}
                                >
                                  <p
                                    style={{
                                      // marginTop: "6px",
                                      fontSize: "16px",
                                      fontWeight: "700",
                                      letterSpacing: "0px",
                                      fontFamily: "Open Sans",
                                      color: "#5d5446",
                                    }}
                                  >
                                    Liste des fichiers à compresser
                                  </p>
                                </div>
                              </div>
                              {file.map((x, i) => {
                                return (
                                  <div
                                    className="comNewFlex"
                                    style={{
                                      marginTop: "17px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontSize: "16px",
                                          letterSpacing: "0px",
                                          lineHeight: "40px",
                                          color: "#5d5446",
                                          fontWeight: "400",
                                          fontStyle: "italic",
                                          fontFamily: "Open Sans",
                                          display: "flex",
                                        }}
                                      >
                                        <img
                                          src={desktop}
                                          alt=""
                                          style={{
                                            width: "31px",
                                            height: "27px",
                                            marginTop: "6px",
                                          }}
                                        />{" "}
                                        &nbsp;{" "}
                                        <p
                                          style={{
                                            marginBottom: "0px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          {x.name}
                                        </p>
                                      </p>
                                    </div>
                                    <img
                                      src={closeIcon}
                                      alt=""
                                      style={{
                                        width: "19px",
                                        height: "19px",
                                        marginTop: "13px",
                                      }}
                                      onClick={() => removeFile(i)}
                                    />
                                  </div>
                                )
                              })}

                              <div className="maxWDiv">
                                <div
                                  className="comNewtxtDiv"
                                  onClick={() => setN(true)}
                                  style={{
                                    marginTop: "14px",
                                  }}
                                >
                                  <p
                                    style={{
                                      padding: "6px 10px",
                                      fontSize: "16px",
                                      color: "#ffffff",
                                      fontWeight: "700",
                                      fontFamily: "Open Sans",
                                      letterSpacing: "0px",
                                    }}
                                  >
                                    Compresser les fichiers sélectionnés
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
