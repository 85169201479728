export const CLEAR_DATA = "CLEAR_DATA";

export const PDF_START = "PDF_START";
export const PDF_START_SUCCESS = "PDF_START_SUCCESS";
export const PDF_START_FAILURE = "PDF_START_FAILURE";

export const PDF_UPLOAD = "PDF_UPLOAD";
export const PDF_UPLOAD_SUCCESS = "PDF_UPLOAD_SUCCESS";
export const PDF_UPLOAD_FAILURE = "PDF_UPLOAD_FAILURE";

export const PDF_PROCESS = "PDF_PROCESS";
export const PDF_PROCESS_SUCCESS = "PDF_PROCESS_SUCCESS";
export const PDF_PROCESS_FAILURE = "PDF_PROCESS_FAILURE";

export const PDF_DOWNLOAD = "PDF_DOWNLOAD";
export const PDF_DOWNLOAD_SUCCESS = "PDF_DOWNLOAD_SUCCESS";
export const PDF_DOWNLOAD_FAILURE = "PDF_DOWNLOAD_FAILURE";